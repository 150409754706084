import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchNoResultsRed } from 'assets/search-no-results-red.svg';

import useStyles from './styles';

const NoResultsBlock = ({ title }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <SearchNoResultsRed className={classes.searchImage} />
        <div
          className={classes.title}
          data-test="noResultsBlockTitle"
        >
          {title || t('NO_RESULTS')}
        </div>
      </div>
    </div>
  );
};

NoResultsBlock.defaultProps = {
  title: '',
};

NoResultsBlock.propTypes = {
  title: PropTypes.string,
};

export default NoResultsBlock;
