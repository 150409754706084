import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  filterLabel: {
    position: 'absolute',
    top: 8,
    left: 18,
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
    backgroundColor: '#fff',
    paddingRight: 10,
    color: theme.colors.darkGray,
    transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  filterLabelToTop: {
    paddingLeft: 4,
    paddingRight: 4,
    color: theme.colors.secondary,
    lineHeight: '12px',
    fontSize: '10px',
    transform: 'translate(-4px, -15px)',
  },
  filterLabelToCenter: {
    transform: 'translate(0px, 0px) scale(1)',
  },
  emptyLabel: {
    height: 40,
  },
  activeOptionWrapper: {
    paddingLeft: 6,
    paddingTop: 4,
    paddingRight: 24,
    zIndex: 1,
    '& .MuiButtonBase-root': {
      maxWidth: '100%',
      textDecoration: 'none',
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      height: 32,
      paddingLeft: 4,
      paddingTop: 4,
      paddingRight: 8,
      paddingBottom: 4,
      backgroundColor: '#ecf4fc',
      marginRight: 4,
      marginBottom: 4,
      '&:first-child': {
        maxWidth: '90%',
      },
    },
  },
  closeIcon: {
    marginRight: 4,
  },
  infoIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    borderRadius: '50%',
    backgroundColor: '#fafafa',
    '&:hover': {
      backgroundColor: '#f3f3f3',
    },
  },
  infoIcon: {
    color: '#767676',
  },
  unCollapseFilterGroupButton: {
    textTransform: 'none',
    textDecoration: 'none',
    width: '100%',
    paddingLeft: 4,
    paddingRight: 4,
    '& .MuiButton-label': {
      marginLeft: 16,
      justifyContent: 'space-between',
    },
  },
  unCollapseFilterGroupIcon: {
    marginRight: 24,
    color: '#767676',
    transform: 'rotate(90deg)',
  },
  collapseFilterGroupIcon: {
    marginRight: 24,
    color: '#767676',
    transform: 'rotate(-90deg)',
  },
  searchWrapper: {
    boxSizing: 'border-box',
    minHeight: 40,
    marginRight: -24,
    marginBottom: 30,
    position: 'relative',
    width: '100%',
    border: '1px solid #cccccc',
    borderRadius: '4px 4px 4px 4px',
    zIndex: 0,
  },
  root: {
    '& label': {
      fontSize: 16,
      background: '#fff',
      padding: '0 5px',
      top: 0,
    },
    '& label.MuiInputLabel-shrink': {
      fontSize: 14,
      top: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset legend': {
        maxWidth: 0,
      },
      '&:hover fieldset': {
        borderColor: '#4798e8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4798e8',
        borderWidth: 1,
      },
    },
  },
  focused: {
    borderColor: '#4798e8',
  },
  focusedLabel: {
    color: '#4798e8',
  },
  unfocused: {
    borderColor: '#cccccc',
    '&:hover': {
      borderColor: '#4798e8',
    },
  },
  unfocusedLabel: {
    borderColor: '#cccccc',
  },
  searchMenuWrapper: {
    padding: 0,
  },
  searchMenuItem: {
    paddingLeft: 48,
  },
  optionLabel: {
    textAlign: 'left',
    color: '#212121',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: 0,
    textTransform: 'none',
  },
  menuItem: {
    paddingLeft: 16,
    height: 48,
    display: 'block',
    width: '100%',
    '&.MuiButtonBase-root': {
      '&:hover': {
        backgroundColor: '#fafafa',
      },
    },
  },
  buttonOverlay: {
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
    bottom: 0,
    zIndex: 3,
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    outline: 'inherit',
    cursor: 'pointer',
  },
  filterElementWrapper: {
    position: 'relative',
  },
  filterOptionsCollapseBtn: {
    display: 'block',
    padding: 0,
    width: 24,
    height: 24,
    position: 'absolute',
    top: 8,
    right: 9,
    zIndex: 2,
  },
  activeOptionLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}
));

export default useStyles;
