import {
  CREATE_REQUEST,
  CREATE_REQUEST_FOR_SOMEONE,
} from 'constants/permissions';
import {
  EMPLOYEE,
  ASSISTANT,
} from 'constants/roles';

const rulesConfig = {
  [EMPLOYEE]: {
    static: [
      CREATE_REQUEST,
    ],
  },
  [ASSISTANT]: {
    static: [
      CREATE_REQUEST_FOR_SOMEONE,
    ],
  },
};

export default rulesConfig;
