import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles((theme) => ({
  pendingStatus: {
    color: theme.colors.alert,
  },
  agreedStatus: {
    color: theme.colors.success,
  },
  rejectedStatus: {
    color: theme.colors.error,
  },
  draftStatus: {
    color: theme.colors.darkGray,
  },
  tabletSecondedName: {
    color: theme.colors.darkGray,
  },
}));

const Status = (props) => {
  const { t } = useTranslation();
  const classes = useRowStyles();
  const { status, noColor } = props;
  switch (status) {
    case 'OnApproval': {
      return <div className={noColor ? {} : classes.pendingStatus}>{t('ON_APPROVAL')}</div>;
    }
    case 'Approved': {
      return <div className={noColor ? {} : classes.agreedStatus}>{t('AGREED')}</div>;
    }
    case 'Declined': {
      return <div className={noColor ? {} : classes.rejectedStatus}>{t('DECLINED')}</div>;
    }
    case 'Revoked': {
      return <div className={noColor ? {} : classes.draftStatus}>{t('REVOKED')}</div>;
    }
    case 'Draft': {
      return <div className={noColor ? {} : classes.draftStatus}>{t('DRAFT')}</div>;
    }
    default: {
      return '—';
    }
  }
};

export default Status;
