import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 424,
    background: theme.colors.white,
    boxShadow: '1px 2px 6px 0 rgb(0 0 0 / 50%)',
    padding: '12px 8px',
    alignItems: 'center',
  },
  alertSuccess: {
    borderLeft: '8px solid #44bb44', // _color_
  },
  alertError: {
    borderLeft: '8px solid #dd4747', // _color_
  },
  action: {
    color: '#767676',
    paddingLeft: 'unset',
    marginRight: 'unset',
    alignSelf: 'flex-start',
    '& .MuiSvgIcon-root': {
      fontSize: '24px !important',
    },
  },
  message: {
    padding: 0,
    color: '#212121',
    fontSize: 16,
  },
  icon: {
    alignItems: 'center',
    padding: 0,
    marginRight: 8,
  },
}
));

export default useStyles;
