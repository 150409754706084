import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import BackArrow from './components/BackArrow';

import useStyles from './PageHeaderStyles';

const PageHeader = ({
  children,
  title,
  sticky,
  hasBackArrow,
  className,
}) => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        px={3}
        py={1}
        className={clsx(classes.pageHeader, {
          [classes.sticky]: sticky,
          [className]: className,
        })}
        id="page-header"
      >
        {hasBackArrow && <BackArrow />}
        {children}
      </Box>
    </>
  );
};

PageHeader.defaultProps = {
  title: 'HCM-002',
  sticky: true,
  hasBackArrow: false,
  className: '',
};

PageHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  sticky: PropTypes.bool,
  hasBackArrow: PropTypes.bool,
};

export default PageHeader;
