import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PartFilterHeaderWithClearButton from './components/PartFilterHeaderWithClearButton';
import PartFilterApplyButton from './components/PartFilterApplyButton';

const FilterPanel = ({
  headerLabel,
  headerClearBtnLabel,
  filterOpened,
  children,
  filterApplyBtnLabel,
  hasActiveFilters,
  hasDiff,
  setFilters,
  handleClearFilters,
}) => {
  const useStyles = makeStyles((theme = {}) => ({
    root: {
      overflow: 'hidden',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      right: 0,
      width: filterOpened ? 368 : 0,
      maxHeight: 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 24px + 35px)',
      minHeight: 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 24px + 35px)',
      backgroundColor: '#fff',
      transition: 'width 0.4s',
      boxShadow: '0 2px 4px 0 #ccc',
    },
    slidingContent: {
      // backgroundColor: 'red',
      width: 368,
      height: 100 || 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 24px)', // TODO
      maxHeight: 100 || 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 24px)', // TODO
      minHeight: 100 || 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 24px)', // TODO
      borderLeft: '1px solid #ccc',
      paddingBottom: theme.spacing(2),
      // backgroundColor: 'yellow'
    },
    filtersWrapper: {
      zIndex: 100,
      position: 'relative',
      overflowY: 'visible',
    },
    filtersInputs: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      position: 'relative',
      maxHeight: 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 24px - 32px - 100px)', // TODO
      minHeight: 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 24px - 32px - 100px)', // TODO
      overflowY: 'scroll',
      paddingBottom: 48,
      '&::before': {
        zIndex: 1,
        position: 'sticky',
        content: '""',
        display: 'block',
        top: 0,
        left: 0,
        right: 0,
        height: theme.spacing(1),
        background: 'linear-gradient(to top, rgba(255, 255, 255,0)0%,rgba(255,255,255,0.5)25%,rgba(255, 255, 255, 0.85) 100%)',
      },
    },
    filterApplyButton: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      position: 'sticky',
      bottom: 0,
      paddingBottom: 16,
      zIndex: 2,
      '&::after': {
        position: 'absolute',
        content: '""',
        display: 'block',
        bottom: '100%',
        left: 8,
        right: 8,
        height: theme.spacing(2),
        background: 'linear-gradient(to bottom, rgba(255, 255, 255,0)0%,rgba(255,255,255,0.5)75%,rgba(255, 255, 255, 0.85) 100%)',
      },
      '&::before': {
        position: 'absolute',
        content: '""',
        display: 'block',
        top: 0,
        left: 0,
        right: 0,
        height: '100%',
        backgroundColor: '#fff',
      },
    },
  }));
  const classes = useStyles();

  return (
    <div
      className={classes.root}
    >
      <div className={classes.slidingContent}>
        <PartFilterHeaderWithClearButton
          label={headerLabel}
          clearLabel={headerClearBtnLabel}
          hasActiveFilters={hasActiveFilters}
          handleClearFilters={handleClearFilters}
          hasDiff={hasDiff}
        />
        <div className={classes.filtersWrapper}>
          <div className={classes.filtersInputs}>
            {children}
          </div>
          <div className={classes.filterApplyButton}>
            <PartFilterApplyButton
              label={filterApplyBtnLabel}
              setFilters={setFilters}
              hasDiff={hasDiff}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FilterPanel.defaultProps = {
  filterOpened: false,
  hasActiveFilters: false,
  children: null,
  hasDiff: null,
  setFilters: () => {},
  handleClearFilters: () => {},
};

FilterPanel.propTypes = {
  filterOpened: PropTypes.bool,
  hasActiveFilters: PropTypes.bool,
  hasDiff: PropTypes.bool,
  children: PropTypes.node,
  filterApplyBtnLabel: PropTypes.string.isRequired,
  setFilters: PropTypes.func,
  handleClearFilters: PropTypes.func,
  headerLabel: PropTypes.string.isRequired,
  headerClearBtnLabel: PropTypes.string.isRequired,
};

export default FilterPanel;
