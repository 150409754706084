import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  SvgIcon, IconButton, Tooltip,
} from '@material-ui/core/';
import { FilterList, CheckCircle } from '@material-ui/icons/';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const FilterButton = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClickFilterButton, filterOpened, hasActiveFilters } = props;

  return (
    <Tooltip title={t('FILTERS')}>
      <IconButton
        data-test="filters-button"
        size="medium"
        className={clsx(classes.filterButton, filterOpened && classes.opened)}
        onClick={onClickFilterButton}
      >
        <div className={classes.filterButtonIcon}>
          <SvgIcon component={FilterList} />
          {hasActiveFilters && (
            <SvgIcon
              className={classes.hasActiveFiltersBadge}
              component={CheckCircle}
            />
          )}
        </div>
      </IconButton>
    </Tooltip>

  );
};

FilterButton.propTypes = {
  onClickFilterButton: PropTypes.func,
  filterOpened: PropTypes.bool,
  hasActiveFilters: PropTypes.bool,
};

FilterButton.defaultProps = {
  onClickFilterButton: () => {
  },
  filterOpened: false,
  hasActiveFilters: false,
};
export default FilterButton;
