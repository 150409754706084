import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import TextBlock from 'components/TextBlock';
import useAppStyles from 'containers/App/AppStyles';

import useStyles from '../RequestStyles';

const AgreementTab = ({ data }) => {
  const { t } = useTranslation();

  const appClasses = useAppStyles();
  const classes = useStyles();
  const { approver1 = {}, approver2 = {} } = data?.approvalRoute;
  const initiator = data?.consolidation?.initiator;
  const initiatorActionName = initiator?.user?.value ? `(${initiator?.user?.value})` : '';
  const consApprover1 = data?.consolidation?.approver1;
  const consApprover2 = data?.consolidation?.approver2;
  const status = data?.status;

  const approver1Name = consApprover1?.user?.value || approver1?.value;
  const approver2Name = consApprover2?.user?.value || approver2?.value;

  let showApprover1Block = Boolean(Object.keys(approver1).length);
  let showApprover2Block = Boolean(Object.keys(approver2).length);
  if (status === 'Declined' || status === 'Approved') {
    showApprover1Block = Boolean(consApprover1);
    showApprover2Block = Boolean(consApprover2);
  }

  const actionsTranslationKeys = {
    Save: 'SAVE',
    SendToApproval: 'SEND_TO_APPROVAL',
    Revoke: 'REVOKE',
    Approve: 'APPROVE',
    Decline: 'DECLINE',
  };

  return (
    <Box px={3} pt={1}>
      <Box style={{ padding: '20px 80px 0' }}>
        <Box className={appClasses.flexWraIndented}>
          <Box className={classes.eventsAttrsLeft}>
            <TextBlock title={t('INITIATOR')} data-test="initiator">
              {data.initiatorName}
            </TextBlock>
            <TextBlock title={t('DATE')} data-test="initiator-date">
              {initiator?.date ? format(new Date(initiator?.date), 'dd/MM/yyyy HH:mm') : '—'}
            </TextBlock>
          </Box>
          <Box className={classes.eventsAttrsRight}>
            <TextBlock title={t('TRAVELED_EMPLOYEE')} data-test="initiator-employee">
              {data.employeeName}
            </TextBlock>
            <TextBlock title={t('ACTION')} data-test="initiator-action">
              {initiator?.action && actionsTranslationKeys[initiator?.action] ? `${t(actionsTranslationKeys[initiator?.action])} ${initiatorActionName}` : '—'}
            </TextBlock>
          </Box>
        </Box>
        <Box className={appClasses.flexWraIndented}>
          <TextBlock title={t('COMMENTS')} data-test="initiator-comment">
            {initiator?.comment || '—'}
          </TextBlock>
          <div />
        </Box>
      </Box>

      {showApprover1Block && (
        <>
          <Divider className={classes.blockDivider} />
          <Box style={{ padding: '0 80px' }}>
            <Box className={appClasses.flexWraIndented}>
              <Box className={classes.eventsAttrsLeft}>
                <TextBlock title={`${t('APPROVER')} 1`} data-test="approver1">
                  {approver1Name}
                </TextBlock>
                <TextBlock title={t('DATE')} data-test="approver1-date">
                  {consApprover1?.date ? format(new Date(consApprover1?.date), 'dd/MM/yyyy HH:mm') : '—'}
                </TextBlock>
              </Box>
              <Box className={classes.eventsAttrsRight}>
                <TextBlock title="" />
                <TextBlock title={t('ACTION')} data-test="approver1-action">
                  {consApprover1?.action || '—'}
                </TextBlock>
              </Box>
            </Box>
            <Box className={appClasses.flexWraIndented}>
              <TextBlock title={t('COMMENTS')} data-test="approver1-comment">
                {consApprover1?.comment || '—'}
              </TextBlock>
              <div />
            </Box>
          </Box>
        </>
      )}
      {showApprover2Block && (
        <>
          <Divider className={classes.blockDivider} />
          <Box style={{ padding: '0 80px' }}>
            <Box className={appClasses.flexWraIndented}>
              <Box className={classes.eventsAttrsLeft}>
                <TextBlock title={`${t('APPROVER')} 2`} data-test="approver2">
                  {approver2Name}
                </TextBlock>
                <TextBlock title={t('DATE')} data-test="approver2-date">
                  {consApprover2?.date ? format(new Date(consApprover2?.date), 'dd/MM/yyyy HH:mm') : '—'}
                </TextBlock>
              </Box>
              <Box className={classes.eventsAttrsRight}>
                <TextBlock title="" />
                <TextBlock title={t('ACTION')} data-test="approver2-action">
                  {consApprover2?.action || '—'}
                </TextBlock>
              </Box>
            </Box>
            <Box className={appClasses.flexWraIndented}>
              <TextBlock title={t('COMMENTS')} data-test="approver2-comment">
                {consApprover2?.comment || '—'}
              </TextBlock>
              <div />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AgreementTab;
