const TRANSLATIONS_EN = {
  REQUEST: 'Request',
  LIMITS: 'Limits',
  APPROVAL: 'Approval',
  REQUESTS_LIST: 'Requests list',
  REQUEST_VIEW: 'Request view',
  DATE_OF_REGISTRATION: 'Execution date',
  EMPLOYEE: 'Employee',
  START_DATE: 'Start date',
  COMPLETION: 'completion',
  COMPLETION_DATE: 'Completion date',
  COMPANY: 'Company',
  TYPE_OF_BUSINESS_TRIP: 'Type of business trip',
  STATUS: 'Status',
  APPROUVER: 'Approver',
  ON_APPROVAL: 'On approval',
  AGREED: 'Approved',
  DECLINE: 'Decline',
  DECLINED: 'Declined',
  APPROVAL_REQUESTS: 'Requests on approval',
  MY_REQUESTS: 'My requests',
  CREATE_REQUEST_FOR_AN_EMPLOYEE: 'Create request for an employee',
  CREATE_REQUEST: 'Create request',
  FILTERS: 'Filter',
  APPLY_FILTERS: 'Apply the filter',
  CLEAR: 'Clean the filter',
  WARNING: 'WARNING!',
  LOGOUT: 'Logout',
  DO_YOU_WANT_TO_LOG_OUT: 'Do you want to log out?',
  YOU_WILL_BE_RETURNED_TO_THE_AUTHORIZATION_SCREEN: 'You will be returned to the authorization'
    + ' screen, you can always log back into the system',
  CREATING_REQUEST: 'Сreating a requests',
  SAVE: 'Save',
  SEND_TO_APPROVAL: 'Send to approval',
  CANCEL: 'Cancel',
  GENERAL_INFO: 'General info',
  REQUIRED_FIELD: 'Required field',
  PURPOSE: 'Purpose',
  COUNTRY: 'Country',
  DESTINATION: 'Destination',
  DESTINATIONS: 'Destinations',
  COMMENTS: 'Comments',
  ADD_DESTINATION: 'Add extra destination',
  TICKETS: 'Tickets',
  TYPE_OF_TICKET: 'Type of ticket',
  CLASS_OF_TICKET: 'Class of ticket',
  FROM: 'From',
  TO: 'To',
  DEPARTURE_DATE: 'Departure date',
  DEPARTURE_TIME: 'Departure time',
  ADD_TICKETS: 'Add tickets',
  LOCALITY: 'Locality',
  EARLY_CHECKIN: 'Early check-in',
  LATE_CHECKOUT: 'Late check-out',
  CHECKIN_DATE: 'Check-in date',
  CHECKOUT_DATE: 'Check-out date',
  ADD_HOTEL: 'Add hotel',
  HOTELS: 'Hotels',
  ACCOMPANYING_SERVICES: 'Extra services',
  SERVICE: 'Services',
  ADD_SERVICE: 'Add extra service',
  FILTER: 'Filter',
  EDIT_TRIP: 'Edit business trip',
  NOT_VALID_DATE: 'Not valid date',
  DRAFT: 'Draft',
  REVOKED: 'Revoked',
  REVOKE_BUSINESS_TRIP: 'Revoke business trip',
  DECLINE_BUSINESS_TRIP: 'Decline business trip',
  YOU_ARE_GOING_TO_DECLINE_BUSINESS_TRIP: 'You are going to decline business trip',
  YOU_ARE_GOING_TO_REVOKE_BUSINESS_TRIP: 'You are going to revoke business trip',
  REVOKING_COMMENT_MESSAGE: 'To revoke a business trip please leave a comment',
  DECLINING_COMMENT_MESSAGE: 'To decline a business trip please leave a comment',
  EDIT: 'Edit',
  COMMENT: 'Comment',
  SUBMIT: 'Submit',
  REVOKE: 'Revoke',
  REJECT: 'Reject',
  APPROVE: 'Approve',
  APPROVAL_OF_REQUEST: 'Approval of request',
  DELETE: 'Delete',
  GOING_TO_DELETE_TRIP: 'You are going to delete the draft request',
  GOING_TO_DELETE_TRIP_EXTRA: 'In this case all request data will be deleted.',
  INITIATOR: 'Initiator',
  CREATION_DATE: 'Creation date',
  SOMETHING_WRONG: 'Something wrong',
  SUCCESSFULLY_DELETED: 'Successfully deleted',
  SUCCESSFULLY_DECLINED: 'Successfully declined',
  SUCCESSFULLY_CREATED: 'Successfully created',
  SUCCESSFULLY_SAVED: 'Successfully saved',
  LIMITS_DESCRIPTION: 'Limits description',
  DATE: 'Date',
  TRAVELED_EMPLOYEE: 'Traveled employee',
  ACTION: 'Action',
  APPROVER: 'Approver',
  ADD_FLIGHT_TRAIN: 'Add flight/train',
  SEND_TO_APPROVE: 'Send to approve',
  SENDING_TO_APPROVE: 'Sending to approve',
  FORM_HAS_ERRORS: 'Form has errors',
  NO_DATA_TO_DISPLAY: 'Requests list is empty',
  ERR_DUPLICATED_REQUEST: 'The request for these dates already exists',
  REVERTING_CHANGES: 'Undo changes',
  YES: 'Yes',
  NO: 'No',
  UNSAVED_DATA_MESSAGE: 'You are going to close the request without saving changes.',
  UNSAVED_DATA_MESSAGE_EXTRA: 'In this case all changes will be lost.',
  DRAFT_DELETING: 'Deleting of draft request',
  SUBMIT_CONFIRMATION_MESSAGE: 'You are going to send the request to approve.',
  APPROVE_CONFIRMATION_MESSAGE: 'You are going to approve the request.',
  COLLAPSE_ALL_BLOCKS: 'Collapse all',
  EXPAND_ALL_BLOCKS: 'Expand all',
  CONFIRM: 'Confirm',
  BACK: 'Back',
  PLEASE_CONFIRM_YOUR_ACTION: 'Please confirm your action.',
  MAIN_PAGE: 'start page',
  NOT_FOUND_TITLE: 'The page you are looking for does not exist or deleted.',
  TRY_START_WITH: 'Please, try following',
  ERR_APPROVAL_ROUTE_MISSING: 'There is no approval route configured for Employee. Please, contact',
  NO_ACCESS_PAGE_MESSAGE: 'There is no access rights for displaying this page.',
  NO_ACCESS_PAGE_SUB_MESSAGE: 'Please, contact your HR administrator or ',
  TECHNICAL_SUPPORT: 'technical support',
  SUPPORT: 'support',
};
export default TRANSLATIONS_EN;
