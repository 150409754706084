import React from 'react';
import { Edit } from '@material-ui/icons';
import { Button } from 'components/ui-lib';
import { useTranslation } from 'react-i18next';

import useAppStyles from 'containers/App/AppStyles';

const EditCaseButton = ({ onClick }) => {
  const appClasses = useAppStyles();
  const { t } = useTranslation();

  return (
    <Button
      startIcon={<Edit />}
      type="cart-secondary"
      className={appClasses.actionControl}
      disabled={false}
      onClick={onClick}
      size="large"
      data-test="edit-button"
    >
      {t('EDIT')}
    </Button>
  );
};

export default EditCaseButton;
