import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import useStyles from './ContentSpinnerStyles';

const ContentSpinner = ({ children, isLoading }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isLoading && (
        <div className={classes.overlay}>
          <CircularProgress className={classes.spinner} />
        </div>
      )}
      {children}
    </div>
  );
};

ContentSpinner.defaultProps = {
  isLoading: false,
  children: null,
};

ContentSpinner.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default ContentSpinner;
