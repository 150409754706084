import req from 'API/axiosSetting';

const API = {
  user: {
    getUserData: () => req({
      url: 'workflow/users/me',
    }),
    getAssistedUsers: () => req({
      url: 'workflow/users/assisted-users',
    }),
  },
  dictionary: {
    getRolesList: () => req({
      url: 'workflow/directories/roles',
    }),
    getCompaniesList: () => req({
      url: 'workflow/directories/companies',
    }),
    getBusinessTripLimits: () => req({
      url: 'workflow/directories/business-trip-limits',
    }),
    getBusinessTripTypes: () => req({
      url: 'workflow/directories/business-trip-types',
    }),
    getCountriesList: () => req({
      url: 'workflow/directories/countries',
    }),
    getServiceTypesList: () => req({
      url: 'workflow/directories/service-types',
    }),

    getTicketClassesList: () => req({
      url: 'workflow/directories/ticket-classes',
    }),
    getTicketTypesList: () => req({
      url: 'workflow/directories/ticket-types',
    }),
    getBusinessTripRequestStatusesList: () => req({
      url: 'workflow/directories/business-trip-request-statuses',
    }),
  },
  filters: {
    getAvailableStatuses: ({ requestTypeParam }) => req({
      url: requestTypeParam === 'MyRequests' ? 'workflow/requests/my/filter/available-statuses' : 'workflow/requests/filter/available-statuses',
    }),
    getAvailableEmployees: ({ requestTypeParam }) => req({
      url: requestTypeParam === 'MyRequests' ? 'workflow/requests/my/filter/available-employees' : 'workflow/requests/filter/available-employees',
    }),
    getAvailableCompanies: () => req({
      url: 'workflow/requests/filter/available-companies',
    }),
    getAvailableApprovers: () => req({
      url: 'workflow/requests/filter/available-approvers',
    }),
  },
  requests: {
    getRequestsList: (params) => {
      const { requestTypeParam, ...restParams } = params;
      return req({
        url: requestTypeParam === 'MyRequests' ? 'workflow/requests/my' : 'workflow/requests',
        params: restParams,
      });
    },
    getApprovePendingCount: (params) => req({
      url: 'workflow/requests/approve-pending-count',
      params,
    }),
  },
  trip: {
    createTrip: (data) => req({
      url: 'workflow/requests',
      method: 'POST',
      data,
    }),
    editTrip: (data) => req({
      url: `workflow/requests/${data.id}`,
      method: 'PUT',
      data,
    }),
    getTrip: (requestId) => req({
      url: `workflow/requests/${requestId}`,
    }),
    deleteTrip: (requestId) => req({
      url: `workflow/requests/${requestId}`,
      method: 'DELETE',
    }),
    getTripLimits: () => req({
      url: 'workflow/directories/business-trip-limits',
    }),
    submitTrip: (tripId) => req({
      url: `workflow/requests/${tripId}/submit`,
      method: 'POST',
      data: {},
    }),
    revokeTrip: ({ tripId, comment }) => req({
      url: `workflow/requests/${tripId}/revoke`,
      method: 'POST',
      data: { comment },
    }),
    approveTrip: (tripId) => req({
      url: `workflow/requests/${tripId}/approve`,
      method: 'POST',
      data: {},
    }),
    rejectTrip: ({ tripId, comment }) => req({
      url: `workflow/requests/${tripId}/reject`,
      method: 'POST',
      data: { comment },
    }),
  },
};

export default API;
