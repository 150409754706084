import React from 'react';
import { DialogDefault, ThemeProvider, Typography } from 'components/ui-lib';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const DialogComponent = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider>
      <DialogDefault
        dialogPaperDataTest="prevent-reverting-changes-dialog"
        title={t('REVERTING_CHANGES')}
        open
        handleClose={() => closeModal(false)}
        activeActionText={t('CONFIRM')}
        cancelActionText={t('BACK')}
        activeActionBtnType="default-secondary-yellow"
        handleActiveAction={() => closeModal(true)}
      >
        <Typography data-test="dialog-sub-title" variant="h2" style={{ lineHeight: '32px', marginBottom: 16 }}>
          {t('UNSAVED_DATA_MESSAGE')}
        </Typography>
        <Typography data-test="dialog-sub-title-extra" variant="subtitle2">{t('UNSAVED_DATA_MESSAGE_EXTRA')}</Typography>
        <Box style={{ marginTop: 8 }}>
          <Typography data-test="dialog-confirm-your-action" variant="subtitle2">{t('PLEASE_CONFIRM_YOUR_ACTION')}</Typography>
        </Box>
      </DialogDefault>
    </ThemeProvider>
  );
};

export default DialogComponent;
