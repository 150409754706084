import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import account from './account';
import requestsList from './requestsList';
import dictionaries from './dictionaries';
import notifications from './notifications';
import trip from './trip';

const reducer = combineReducers({
  account,
  dictionaries,
  requestsList,
  notifications,
  trip,
});
const store = configureStore({
  reducer,
});

export default store;
