/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { omit, get, isEmpty } from 'lodash';
import { format } from 'date-fns';
import API from 'API';
import {
  LOADING, SUCCEEDED, INIT_STATUS, FAILED,
} from 'constants/main';

export const loadRequestsList = createAsyncThunk(
  'requestsList/getRequestsList',
  async ({
    limit = 10,
    offset = 0,
    filtersState = {},
    sortingParams = {},
    requestTypeParam = '',
  }, { rejectWithValue }) => {
    try {
      const { requestDateFrom: requestDateFromString, requestDateTo: requestDateToString } = filtersState;
      const requestDateFrom = new Date(requestDateFromString);
      const requestDateTo = new Date(requestDateToString);
      let response = await API.requests.getRequestsList({
        limit,
        offset: limit * offset,
        requestTypeParam,
        ...omit(filtersState, ['requestDateFrom', 'requestDateTo']),
        ...Boolean(requestDateFromString) && { businessTripStartDate: format(requestDateFrom, 'yyyy-MM-dd') },
        ...Boolean(requestDateToString) && { businessTripFinishDate: format(requestDateTo, 'yyyy-MM-dd') },
        ...sortingParams,
      });
      const totalCount = get(response, 'data.totalCount', 0);
      const items = get(response, 'data.items', []);
      const shouldRequestPreviousPage = totalCount > 0 && isEmpty(items) && offset > 0;
      if (shouldRequestPreviousPage) {
        response = await API.cases.getRequestsList({
          limit,
          offset: limit * (offset - 1),
          requestTypeParam,
          ...filtersState,
          ...sortingParams,
        });
        return { ...response.data, offset: offset - 1 };
      }
      return response.data;
    } catch (err) {
      console.error(err);
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadApprovePendingCount = createAsyncThunk(
  'requestsList/getApprovePendingCount',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.requests.getApprovePendingCount(args);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadAvailableStatuses = createAsyncThunk(
  'requestsList/getAvailableStatuses',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.filters.getAvailableStatuses(args);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadAvailableEmployees = createAsyncThunk(
  'requestsList/getAvailableEmployees',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.filters.getAvailableEmployees(args);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadAvailableCompanies = createAsyncThunk(
  'requestsList/getAvailableCompanies',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.filters.getAvailableCompanies(args);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadAvailableApprovers = createAsyncThunk(
  'requestsList/getAvailableApprovers',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.filters.getAvailableApprovers(args);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const getUsers = createAsyncThunk(
  'account/getResponsible',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.user.getUsers(args);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

const initialStateMetadata = {
  status: INIT_STATUS,
  error: null,
};

const requestsSlice = createSlice({
  name: 'requestsList',
  initialState: {
    responsible: [],
    requestsList: [],
    pendingCount: 0,
    availableStatuses: [],
    availableStatusesLoadingStatus: INIT_STATUS,
    availableEmployees: [],
    availableEmployeesLoadingStatus: INIT_STATUS,
    availableCompanies: [],
    availableCompaniesLoadingStatus: INIT_STATUS,
    availableApprovers: [],
    availableApproversLoadingStatus: INIT_STATUS,
    loading: INIT_STATUS,
    limit: 10,
    offset: 0,
    totalCount: 0,
    filtersState: {},
    temporaryFiltersState: {},
    sortingParams: { sortingField: 'id', sortingOrder: 'desc' },
    requestTypeParam: null,
    ...initialStateMetadata,
  },
  reducers: {
    onFilterUpdate(state, action) {
      const { payload: { filterName, value } } = action;
      if (value) {
        state.temporaryFiltersState[filterName] = value;
      } else {
        state.temporaryFiltersState = omit(state.temporaryFiltersState, filterName);
      }
    },
    applyFilters(state) {
      state.filtersState = state.temporaryFiltersState;
    },
    resetTemporaryFiltersState(state) {
      state.temporaryFiltersState = state.filtersState;
    },
    resetFiltersState(state) {
      state.temporaryFiltersState = {};
      state.filtersState = {};
    },
    changeSorting(state, action) {
      const { payload: { sortingField, sortingOrder } } = action;
      state.sortingParams = { sortingField, sortingOrder };
    },
    setRequestTypeParam(state, action) {
      const { payload: { requestType } } = action;
      state.requestTypeParam = requestType;
    },
  },
  extraReducers: {
    [loadRequestsList.pending]: (state, action) => {
      const limit = get(action, 'meta.arg.limit');
      const offset = get(action, 'meta.arg.offset');
      state.limit = limit;
      state.offset = offset;
      state.loading = LOADING;
      state.requestsList = [];
      state.error = false;
    },
    [loadRequestsList.fulfilled]: (state, action) => {
      const { items, totalCount, offset } = action.payload;
      if (offset >= 0) {
        state.offset = offset;
      }
      state.requestsList = items;
      state.totalCount = totalCount;
      state.loading = SUCCEEDED;
    },
    [loadRequestsList.rejected]: (state, action) => {
      const { payload } = action;
      state.loading = FAILED;
      state.error = payload;
    },
    [loadApprovePendingCount.fulfilled]: (state, action) => {
      const { count } = action.payload;
      state.pendingCount = count;
    },
    [loadApprovePendingCount.rejected]: (state) => {
      state.pendingCount = 0;
    },
    [loadAvailableStatuses.pending]: (state) => {
      state.availableStatusesLoadingStatus = LOADING;
    },
    [loadAvailableStatuses.fulfilled]: (state, action) => {
      state.availableStatuses = action.payload;
      state.availableStatusesLoadingStatus = SUCCEEDED;
    },
    [loadAvailableStatuses.rejected]: (state) => {
      state.availableStatuses = [];
      state.availableStatusesLoadingStatus = FAILED;
    },
    [loadAvailableEmployees.pending]: (state) => {
      state.availableEmployeesLoadingStatus = LOADING;
    },
    [loadAvailableEmployees.fulfilled]: (state, action) => {
      state.availableEmployees = action.payload;
      state.availableEmployeesLoadingStatus = SUCCEEDED;
    },
    [loadAvailableEmployees.rejected]: (state) => {
      state.availableEmployees = [];
      state.availableEmployeesLoadingStatus = FAILED;
    },
    [loadAvailableCompanies.pending]: (state) => {
      state.availableCompaniesLoadingStatus = LOADING;
    },
    [loadAvailableCompanies.fulfilled]: (state, action) => {
      state.availableCompanies = action.payload;
      state.availableCompaniesLoadingStatus = SUCCEEDED;
    },
    [loadAvailableCompanies.rejected]: (state) => {
      state.availableCompanies = [];
      state.availableCompaniesLoadingStatus = FAILED;
    },
    [loadAvailableApprovers.pending]: (state) => {
      state.availableApproversLoadingStatus = LOADING;
    },
    [loadAvailableApprovers.fulfilled]: (state, action) => {
      state.availableApprovers = action.payload;
      state.availableApproversLoadingStatus = SUCCEEDED;
    },
    [loadAvailableApprovers.rejected]: (state) => {
      state.availableApprovers = [];
      state.availableApproversLoadingStatus = FAILED;
    },
  },
});

export default requestsSlice.reducer;
export const {
  onFilterUpdate,
  applyFilters,
  resetTemporaryFiltersState,
  resetFiltersState,
  changeSorting,
  setRequestTypeParam,
} = requestsSlice.actions;
