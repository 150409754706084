import React, { useState } from 'react';
import { get } from 'lodash';
import { Button, DialogDefault, Typography } from 'components/ui-lib';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  loadTrip,
  submitTrip,
  revokeTrip,
  approveTrip,
  rejectTrip,
} from 'store/trip';
import { useParams } from 'react-router-dom';
import { showNotification } from 'store/notifications';
import { Box, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 2,
    boxShadow: '0 -1px 4px 0 #ccc',
    padding: 24,
    justifyContent: 'flex-end',
  },
  actionBtn: {
    marginLeft: 24,
  },
  cover: { // 96
    position: 'relative',
    background: '#f9f9f9',
    height: 96,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: -9,
      top: 0,
      bottom: 0,
      width: 10,
      background: '#f9f9f9',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: -9,
      top: 0,
      bottom: 0,
      width: 10,
      background: '#f9f9f9',
    },
    // marginBottom: 80,
  },
  innerShadow: {
    position: 'absolute',
    height: 6,
    top: -6,
    left: -1,
    right: -1,
    borderBottom: '1px solid #e0e0e0',
    zIndex: 1,
    boxShadow: '2px 3px 4px 0px rgb(0 0 0 / 8%), -2px 3px 4px 0px rgb(0 0 0 / 8%)',
    borderRadius: '0 0 3px 3px',
  },
  revokeComment: {
    marginTop: 16,
    marginBottom: 24,
  },
}));

const ActionTripBtns = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const { requestId: tripId } = params;
  const [revokingComment, setRevokingComment] = useState('');
  const [revokingCommentError, setRevokingCommentError] = useState('');
  const [isOpenRevokeConfirmation, setIsOpenRevokeConfirmation] = useState(false);
  const [rejectComment, setRejectComment] = useState('');
  const [rejectingCommentError, setRejectingCommentError] = useState('');
  const [isOpenRejectConfirmation, setIsOpenRejectConfirmation] = useState(false);
  const [isOpenSubmitConfirmation, setIsOpenSubmitConfirmation] = useState(false);
  const [isOpenApproveConfirmation, setIsOpenApproveConfirmation] = useState(false);
  const {
    canRevokeRequest,
    canSubmitRequest,
    canApproveRequest,
    canRejectRequest,
  } = props;

  const handleSubmitTrip = () => {
    setIsOpenSubmitConfirmation(false);
    dispatch(submitTrip({ tripId }))
      .then((data) => {
        const errorMessage = get(data, 'payload.error.data.message');
        if (errorMessage) {
          dispatch(showNotification({
            type: 'error',
            message: {
              messageTemplate: {
                rows: [{
                  rowContent: [{
                    type: 'text',
                    text: errorMessage
                  }]
                }],
              },
            }
          }));
        } else {
          dispatch(loadTrip(params));
          dispatch(showNotification({
            type: 'success',
            message: {
              messageTemplate: {
                rows: [{
                  rowContent: [{
                    type: 'text',
                    text: 'Successfully sent'
                  }]
                }],
              },
            },
          }));
        }
      });
  };

  const handleApproveTrip = () => {
    setIsOpenApproveConfirmation(false);
    dispatch(approveTrip({ tripId }))
      .then((data) => {
        const errorMessage = get(data, 'payload.error.data.message');
        if (errorMessage) {
          dispatch(showNotification({
            type: 'error',
            message: {
              messageTemplate: {
                rows: [{
                  rowContent: [{
                    type: 'text',
                    text: errorMessage
                  }]
                }],
              },
            }
          }));
        } else {
          dispatch(loadTrip(params));
          dispatch(showNotification({
            type: 'success',
            message: {
              messageTemplate: {
                rows: [{
                  rowContent: [{
                    type: 'text',
                    text: 'Successfully approved'
                  }]
                }]
              }
            }
          }));
        }
      });
  };

  const handleRevokeTrip = (args) => {
    const isEmptyComment = revokingComment.trim().length < 1;
    if (isEmptyComment) {
      setRevokingCommentError('required');
    } else {
      setIsOpenRevokeConfirmation(false);
      dispatch(revokeTrip(args))
        .then((data) => {
          const errorMessage = get(data, 'payload.error.data.message');
          if (errorMessage) {
            dispatch(showNotification({
              type: 'error',
              message: {
                messageTemplate: {
                  rows: [{
                    rowContent: [{
                      type: 'text',
                      text: errorMessage
                    }]
                  }]
                }
              }
            }));
          } else {
            dispatch(loadTrip(params));
            dispatch(showNotification({
              type: 'success',
              message: {
                messageTemplate: {
                  rows: [{
                    rowContent: [{
                      type: 'text',
                      text: 'Successfully revoked'
                    }]
                  }]
                }
              }
            }));
          }
        });
    }
  };

  const handleRejectTrip = (args) => {
    const isEmptyComment = rejectComment.trim().length < 1;
    if (isEmptyComment) {
      setRejectingCommentError('required');
    } else {
      setIsOpenRejectConfirmation(false);
      dispatch(rejectTrip(args))
        .then((data) => {
          const errorMessage = get(data, 'payload.error.data.message');
          if (errorMessage) {
            dispatch(showNotification({
              type: 'error',
              message: {
                messageTemplate: {
                  rows: [{
                    rowContent: [{
                      type: 'text',
                      text: errorMessage
                    }]
                  }]
                }
              }
            }));
          } else {
            dispatch(loadTrip(params));
            dispatch(showNotification({
              type: 'success',
              message: {
                messageTemplate: {
                  rows: [{
                    rowContent: [{
                      type: 'text',
                      text: t('SUCCESSFULLY_DECLINED')
                    }]
                  }]
                }
              }
            }));
          }
        });
    }
  };

  return (
    <>
      <div className={classes.cover}>
        <div className={classes.innerShadow} />
      </div>
      <div className={classes.wrapper}>
        {canRevokeRequest && (
          <Button
            data-test="revoke-action"
            className={classes.actionBtn}
            type="default-secondary-yellow"
            color="primary"
            onClick={() => setIsOpenRevokeConfirmation(true)}
          >
            {t('REVOKE')}
          </Button>
        )}
        {canSubmitRequest && (
          <Button
            data-test="submit-action"
            className={classes.actionBtn}
            type="default-primary-yellow"
            color="primary"
            onClick={() => setIsOpenSubmitConfirmation(true)}
          >
            {t('SEND_TO_APPROVE')}
          </Button>
        )}
        {canRejectRequest && (
          <Button
            data-test="decline-action"
            className={classes.actionBtn}
            type="default-secondary-yellow"
            color="primary"
            onClick={() => setIsOpenRejectConfirmation(true)}
          >
            {t('DECLINE')}
          </Button>
        )}
        {canApproveRequest && (
          <Button
            data-test="approve-action"
            className={classes.actionBtn}
            type="default-primary-yellow"
            color="primary"
            onClick={() => setIsOpenApproveConfirmation(true)}
          >
            {t('APPROVE')}
          </Button>
        )}
      </div>
      <DialogDefault
        dialogPaperDataTest="revoke-business-trip-dialog"
        title={t('REVOKE_BUSINESS_TRIP')}
        activeActionBtnType="default-secondary-yellow"
        open={isOpenRevokeConfirmation}
        handleClose={() => setIsOpenRevokeConfirmation(false)}
        activeActionText={t('REVOKE')}
        cancelActionText={t('CANCEL')}
        handleActiveAction={() => {
          handleRevokeTrip({ tripId, comment: revokingComment });
        }}
      >
        <Typography
          data-test="dialog-sub-title"
          variant="h2"
          style={{ lineHeight: '32px', paddingBottom: 6 }}
        >
          {t('YOU_ARE_GOING_TO_REVOKE_BUSINESS_TRIP')}
        </Typography>
        <Typography data-test="dialog-text" variant="subtitle2">{t('REVOKING_COMMENT_MESSAGE')}</Typography>
        <Box className={classes.revokeComment}>
          <TextField
            data-test="revoke-comment"
            helperText={t('REQUIRED_FIELD')}
            error={Boolean(revokingCommentError)}
            maxRows={10}
            multiline
            fullWidth
            inputProps={{ maxLength: 1024 }}
            variant="outlined"
            label={t('COMMENT')}
            value={revokingComment}
            onChange={({ target }) => {
              setRevokingComment(target.value);
              if (revokingCommentError) setRevokingCommentError('');
            }}
          />
        </Box>
      </DialogDefault>
      <DialogDefault
        dialogPaperDataTest="decline-business-trip-dialog"
        title={t('DECLINE_BUSINESS_TRIP')}
        activeActionBtnType="default-secondary-yellow"
        open={isOpenRejectConfirmation}
        handleClose={() => setIsOpenRejectConfirmation(false)}
        activeActionText={t('DECLINE')}
        cancelActionText={t('CANCEL')}
        handleActiveAction={() => {
          handleRejectTrip({ tripId, comment: rejectComment });
        }}
      >
        <Typography
          data-test="dialog-sub-title"
          variant="h2"
          style={{ lineHeight: '32px', paddingBottom: 6 }}
        >
          {t('YOU_ARE_GOING_TO_DECLINE_BUSINESS_TRIP')}
        </Typography>
        <Typography data-test="dialog-text" variant="subtitle2">{t('DECLINING_COMMENT_MESSAGE')}</Typography>
        <Box className={classes.revokeComment}>
          <TextField
            data-test="decline-comment"
            helperText={t('REQUIRED_FIELD')}
            error={Boolean(rejectingCommentError)}
            maxRows={10}
            multiline
            fullWidth
            inputProps={{ maxLength: 1024 }}
            variant="outlined"
            label={t('COMMENT')}
            value={rejectComment}
            onChange={({ target }) => {
              setRejectComment(target.value);
              if (rejectingCommentError) setRejectingCommentError('');
            }}
          />
        </Box>
      </DialogDefault>
      <DialogDefault
        dialogPaperDataTest="send-to-approve-dialog"
        title={t('SENDING_TO_APPROVE')}
        activeActionBtnType="default-secondary-yellow"
        open={isOpenSubmitConfirmation}
        handleClose={() => setIsOpenSubmitConfirmation(false)}
        activeActionText={t('CONFIRM')}
        cancelActionText={t('BACK')}
        handleActiveAction={handleSubmitTrip}
      >
        <Typography
          data-test="dialog-sub-title"
          variant="h2"
          style={{ lineHeight: '32px', marginBottom: 8 }}
        >
          {t('SUBMIT_CONFIRMATION_MESSAGE')}
        </Typography>
        <Box style={{ marginTop: 8 }}>
          <Typography data-test="dialog-confirm-your-action" variant="subtitle2">{t('PLEASE_CONFIRM_YOUR_ACTION')}</Typography>
        </Box>
      </DialogDefault>
      <DialogDefault
        dialogPaperDataTest="approve-dialog"
        title={t('APPROVAL_OF_REQUEST')}
        activeActionBtnType="default-secondary-yellow"
        open={isOpenApproveConfirmation}
        handleClose={() => setIsOpenApproveConfirmation(false)}
        activeActionText={t('CONFIRM')}
        cancelActionText={t('BACK')}
        handleActiveAction={handleApproveTrip}
      >
        <Typography
          data-test="dialog-sub-title"
          variant="h2"
          style={{ lineHeight: '32px', marginBottom: 8 }}
        >
          {t('APPROVE_CONFIRMATION_MESSAGE')}
        </Typography>
        <Box style={{ marginTop: 8 }}>
          <Typography data-test="dialog-confirm-your-action" variant="subtitle2">{t('PLEASE_CONFIRM_YOUR_ACTION')}</Typography>
        </Box>
      </DialogDefault>
    </>
  );
};

export default ActionTripBtns;
