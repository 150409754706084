import { formatDateStr } from 'utils/dateTime';

export function mapBeDataForClient(data) {
  return {
    id: data.id || null,
    status: data.status || null,
    creationDate: data.creationDate || null,
    employeeId: data?.employee?.id || null,
    employeeName: data?.employee?.value || null,
    initiatorId: data?.initiator?.id || null,
    initiatorName: data?.initiator?.value || null,
    company: data?.company?.value || null,
    approver1Approved: data?.approver1Approved,
    approver2Approved: data?.approver2Approved,
    approvalRoute: data?.approvalRoute,
    businessTripTypeId: data?.businessTripType?.id || null,
    businessTripTypeName: data?.businessTripType?.value || null,
    businessTripStartDate: new Date(new Date(data?.businessTripStartDate).setHours(0, 0, 0, 0)).toISOString(),
    businessTripFinishDate: new Date(new Date(data?.businessTripFinishDate).setHours(0, 0, 0, 0)).toISOString(),
    businessTripGoal: data?.businessTripGoal || '',
    consolidation: data?.consolidation,
    destinations: data?.destinations.map((it) => ({
      id: it?.id,
      countryId: it?.country?.id || null,
      countryName: it?.country?.value || null,
      destination: it?.destination || '',
      startDate: new Date(new Date(it?.startDate).setHours(0, 0, 0, 0)).toISOString(),
      finishDate: new Date(new Date(it?.finishDate).setHours(0, 0, 0, 0)).toISOString(),
      comment: it?.comment || '',
    })),
    tickets: data?.tickets.map((it) => ({
      id: it.id || null,
      ticketTypeId: it?.ticketType?.id || null,
      ticketTypeName: it?.ticketType?.value || null,
      ticketClassId: it?.ticketClass?.id || null,
      ticketClassName: it?.ticketClass?.value || null,
      from: it?.from || '',
      to: it?.to || '',
      departureDate: it?.departureDate ? new Date(new Date(it?.departureDate).setHours(0, 0, 0, 0)).toISOString() : null,
      departureTime: it?.departureTime ? it.departureTime.slice(0, 5) : null,
      comment: it?.comment,
    })),
    hotels: data?.hotels.map((it) => ({
      id: it.id || null,
      location: it?.location || '',
      arrivalDate: it?.arrivalDate ? new Date(new Date(it?.arrivalDate).setHours(0, 0, 0, 0)).toISOString() : null,
      departureDate: it?.departureDate ? new Date(new Date(it?.departureDate).setHours(0, 0, 0, 0)).toISOString() : null,
      comment: it?.comment || '',
      earlyCheckIn: it?.earlyCheckIn || false,
      lateCheckOut: it?.lateCheckOut || false,
    })),
    additionalServices: data?.additionalServices.map((it) => ({
      id: it.id || null,
      serviceId: it?.service?.id || null,
      serviceName: it?.service?.value || null,
      comment: it?.comment || '',
    })),
  };
}

export function mapDataForBe(data) {
  return {
    ...data,
    businessTripGoal: data.businessTripGoal || null,
    businessTripStartDate: formatDateStr(new Date(data.businessTripStartDate)),
    businessTripFinishDate: formatDateStr(new Date(data.businessTripFinishDate)),
    destinations: data?.destinations.map((it) => ({
      ...it,
      comment: it.comment || null,
      startDate: formatDateStr(new Date(it.startDate)),
      finishDate: formatDateStr(new Date(it.finishDate)),
    })),
    tickets: data?.tickets.map((it) => ({
      ...it,
      comment: it.comment || null,
      departureDate: formatDateStr(new Date(it.departureDate)),
      departureTime: it.departureTime || null,
    })),
    hotels: data?.hotels.map((it) => ({
      ...it,
      comment: it.comment || null,
      arrivalDate: formatDateStr(new Date(it.arrivalDate)),
      departureDate: formatDateStr(new Date(it.departureDate)),
    })),
    additionalServices: data?.additionalServices.map((it) => ({
      ...it,
      comment: it.comment || null,
    })),
  };
}
