import { makeStyles } from '@material-ui/core/styles';
import {
  lightSelection,
  primaryLight,
  semiLightSelection,
  black10,
  black20,
  black70,
} from '../ThemeProvider/theme/colors';

export const defaultSwitchStyles = makeStyles(() => ({
  switchBase: {
    '&$checked': {
      '& + $track': {
        backgroundColor: lightSelection,
      },
      '& $circle': {
        backgroundColor: primaryLight,
        color: primaryLight,
        '&$active': {
          backgroundColor: semiLightSelection,
        },
      },
      '&.Mui-disabled': {
        '& + $track': {
          opacity: 1,
          backgroundColor: black20,
        },
        '& $circle': {
          backgroundColor: black10,
        },
      },
    },
    '&.Mui-disabled': {
      '& + $track': {
        opacity: 1,
        backgroundColor: black20,
      },
      '& $circle': {
        backgroundColor: black10,
      },
    },
  },
  checked: {},
  colorSecondary: {},
  track: {
    opacity: 1,
    backgroundColor: black70,
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: black10,
    color: black70,
  },
  active: {
    backgroundColor: semiLightSelection,
  },
}
));

export default defaultSwitchStyles;
