import { showNotification } from 'store/notifications';
import store from 'store';

export const headerHeight = 64;
export const titleHeight = 65;

export const INIT_STATUS = 'idle';
export const LOADING = 'loading';
export const FAILED = 'failed';
export const SUCCEEDED = 'succeeded';

export const ERR_DUPLICATED_REQUEST_CODE = 7;
export const ERR_APPROVAL_ROUTE_MISSING = 8;

export const blocksName = {
  DESTINATION: 'destination',
  TICKET: 'ticket',
  HOTEL: 'hotel',
  ADDITIONAL_SERVICE: 'additionalService',
};

export const showNotValidFormNotification = (err, formik, t) => {
  if (err?.response?.data?.code === ERR_DUPLICATED_REQUEST_CODE) {
    formik.setErrors({
      businessTripStartDate: t('REQUIRED_FIELD'),
      businessTripFinishDate: t('REQUIRED_FIELD'),
    });

    return store.dispatch(showNotification({
      type: 'error',
      message: {
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('ERR_DUPLICATED_REQUEST')
            }]
          }],
        }
      },
    }));
  }

  if (err?.response?.data?.code === ERR_APPROVAL_ROUTE_MISSING) {
    return store.dispatch(showNotification({
      type: 'error',
      message: {
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('ERR_APPROVAL_ROUTE_MISSING')
            }, {
              type: 'mailto', text: t('SUPPORT'), url: 'mailto:helpdesk@dtek.com?subject=Business Trips approval route configuration error'
            }]
          }],
        },
      },
    }));
  }

  store.dispatch(showNotification({
    type: 'error',
    message: {
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('FORM_HAS_ERRORS')
          }]
        }],
      },
    },
  }));
};

export default module;
