import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ButtonBase } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import OverflowTip from 'components/ui-lib/OverflowTip';
import { formatDate } from 'utils/dateTime';
import { darkGray } from 'components/ui-lib/ThemeProvider/theme/colors';
import useWindowDimensions from 'components/ui-lib/Tabs/useWindowDimensions';
import Status from 'components/StatusText';
import { useTranslation } from 'react-i18next';
import NoResultsBlock from '../NoResultsBlock';

const INPUT_EMPTY_VALUE = '—';

const useRowStyles = makeStyles((theme) => ({
  tableLink: {
    color: theme.colors.primaryLight,
    fontSize: 16,
  },
  root: {
    height: 72,
    '& .MuiTableCell-root': {
      padding: '11px 8px',
      '&:first-child': {
        paddingLeft: 40,
      },
    },
    '& > *': {
      borderBottom: 'unset',
    },
    '&:hover': {
      cursor: 'pointer',
      '& $tableLink': {
        textDecoration: 'underline',
      },
    },
  },
  evenRow: {
    background: theme.colors.white,
  },
  oddRow: {
    background: theme.colors.black5,
  },
  collapsedHeaderRow: {
    '& .MuiTableCell-root': {
      paddingLeft: 8,
      paddingRight: 8,
      '&:first-child': {
        paddingLeft: 0,
      },
      fontSize: 12,
      paddingTop: 8,
      paddingBottom: 8,
      borderBottom: `1px solid ${theme.colors.black10}`,
      color: theme.colors.darkGray,
      lineHeight: 1.2,
    },
  },
  collapsedContent: {
    paddingLeft: 16, paddingRight: 16, paddingBottom: 8, backgroundColor: theme.colors.black5,
  },
  collapsedRow: {
    height: 48,
    '& .MuiTableCell-root': {
      paddingLeft: 8,
      paddingRight: 8,
      '&:first-child': {
        paddingLeft: 0,
      },
      fontSize: 12,
      paddingTop: 'unset',
      paddingBottom: 'unset',
      borderBottom: `1px solid ${theme.colors.black10}`,
    },
  },
  restorativeRepairs: {
    color: theme.colors.success,
  },
  emergencyRepairs: {
    color: theme.colors.alert,
  },
  damaged: {
    color: theme.colors.alert,
  },
  destroyed: {
    color: theme.colors.error,
  },
  captured: {
    color: theme.colors.error,
  },
  temporarily_lost_control: {
    color: theme.colors.alert,
  },
  propertyNameCell: {
    width: 274,
  },
  inventoryNumberCell: {
    width: 170,
  },
  lossesAmountCell: {
    width: 144,
  },
  amountCellValue: {
    paddingLeft: 40,
  },
  collapseHeaderItem: {
    color: theme.colors.darkGray,
  },
  environmentalRisksCell: {
    width: 170,
  },
  damageCategoryCell: {
    width: 90,
  },
  pendingStatus: {
    color: theme.colors.alert,
  },
  agreedStatus: {
    color: theme.colors.success,
  },
  rejectedStatus: {
    color: theme.colors.error,
  },
  draftStatus: {
    color: theme.colors.darkGray,
  },
  tabletSecondedName: {
    color: theme.colors.darkGray,
  },
}
));
const useTableStyles = makeStyles((theme) => ({
  tableContainer: {
    boxShadow: 'none',
    maxHeight: ({ tabsAreVisible }) => (tabsAreVisible
      ? 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 64px)'
      : 'calc(100vh - 64px - 32px - 24px - 62px - 64px)'
    ),
    minHeight: ({ tabsAreVisible }) => (tabsAreVisible
      ? 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 64px)'
      : 'calc(100vh - 64px - 32px - 24px - 62px - 64px)'
    ),
  },
  mainHeaderItem: {
    color: theme.colors.darkGray, fontSize: 12, lineHeight: 1.33,
  },
  mainHeaderRow: {
    height: 56,
    '& .MuiTableCell-root': {
      padding: '4px 8px',
      borderBottom: `1px solid ${theme.colors.black20}`,
      '&:first-child': {
        paddingLeft: 40,
      },
    },
  },
  checkBoxCell: {
    width: 48,
  },
  tabletIdCell: {
    width: '20%',
    paddingLeft: 40,
  },
  idCell: {
    width: '10%',
  },
  dateCell: {
    width: ({ isPendingMyApprovalRequests }) => (isPendingMyApprovalRequests ? '10%' : '20%'),
  },
  employeeCell: {
    width: '10%',
  },
  startDateCell: {
    width: '10%',
  },
  endDateCell: {
    width: '10%',
  },
  companyCell: {
    width: '10%',
  },
  typeCell: {
    width: ({ isPendingMyApprovalRequests }) => (isPendingMyApprovalRequests ? '10%' : '20%'),
  },
  approverCell: {
    width: '10%',
  },
  tabletRegistrationDateCell: {
    width: ({ isPendingMyApprovalRequests }) => (isPendingMyApprovalRequests ? '20%' : '35%'),
  },
  tabletStartEndDatesCell: {
    width: ({ isPendingMyApprovalRequests }) => (isPendingMyApprovalRequests ? '25%' : '35%'),
  },
  tabletApproverCompanyCell: {
    width: '25%',
  },
  statusCell: {
    width: 120,
  },
  tabletStatusCell: {
    // width: 200,
  },
  noDataCell: {
    textAlign: 'center', borderBottom: '0 none', color: theme.colors.darkGray,
  },
  noDataCellContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    maxHeight: ({ tabsAreVisible }) => (tabsAreVisible
      ? 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 64px - 120px)'
      : 'calc(100vh - 64px - 32px - 24px - 62px - 64px - 120px)'
    ),
    minHeight: ({ tabsAreVisible }) => (tabsAreVisible
      ? 'calc(100vh - 64px - 64px - 32px - 24px - 62px - 64px - 120px)'
      : 'calc(100vh - 64px - 32px - 24px - 62px - 64px - 120px)'
    ),
  },
  seconded: {
    // width: 200,
  },
  company: {
    width: 180,
  },
  coordinator: {
    // width: 180,
  },
  startDate: {
    width: 100,
  },
  endDate: {
    width: 120,
  },
  sortingButton: {
    display: 'inline-block',
    marginLeft: 8,
  },
  sortingButtonActive: {
    color: theme.colors.black,
  },
}
));

const TabletTabletRow = (props) => {
  const {
    row, isEven, onRowClick, requestTypeParam,
  } = props;
  const isPendingMyApprovalRequests = requestTypeParam === 'PendingMyApproval';
  const classes = useRowStyles();

  return (
    <TableRow
      className={clsx(classes.root, isEven ? classes.evenRow : classes.oddRow)}
      onClick={() => onRowClick(row.id)}
      data-test={`row-${row.id}`}
    >
      <TableCell
        className={classes.tableLink}
        data-test="id-cell"
      >
        {row.id}
      </TableCell>
      <TableCell data-test="registration-date-seconded-cell">
        <div className={classes.creationDate} data-test="registrationDate">
          {formatDate(row.creationDate)}
        </div>
        <div
          className={classes.tabletSecondedName}
          data-test="secondedName"
        >
          {row?.employee?.value}
        </div>
      </TableCell>
      <TableCell data-test="start-end-date-type-cell">
        <div className={classes.startEndDates} data-test="start-end-dates">
          {`${formatDate(row.businessTripStartDate)} - ${formatDate(row.businessTripFinishDate)}`}
        </div>
        <div className={classes.businessTripType} data-test="business-trip-type">
          <OverflowTip
            textProps={{ style: { color: darkGray, fontSize: 14, lineHeight: 1.29 } }}
            maxLine={1}
            text={row?.businessTripType?.value}
            tooltipTitle={row?.businessTripType?.value}
            data-test="business-trip-type"
          />
        </div>
      </TableCell>
      {isPendingMyApprovalRequests && (
      <TableCell data-test="approuver-company-cell">
        <div className={classes.approuver} data-test="approuver">
          {row?.pendingApprover?.value || INPUT_EMPTY_VALUE}
        </div>
        <div className={classes.company} data-test="company">
          <OverflowTip
            textProps={{ style: { color: darkGray, fontSize: 14, lineHeight: 1.29 } }}
            maxLine={1}
            text={row?.company?.value}
            tooltipTitle={row?.company?.value}
            data-test="business-trip-type"
          />
        </div>
      </TableCell>
      )}
      <TableCell data-test="status-cell">
        <Status status={row.status} />
      </TableCell>
    </TableRow>
  );
};

const Row = (props) => {
  const {
    row, isEven, onRowClick, requestTypeParam,
  } = props;
  const isPendingMyApprovalRequests = requestTypeParam === 'PendingMyApproval';
  const classes = useRowStyles({ isPendingMyApprovalRequests });

  return (
    <TableRow
      className={clsx(classes.root, isEven ? classes.evenRow : classes.oddRow)}
      onClick={() => onRowClick(row.id, row.holdingId)}
      data-test={`row-${row.id}`}
    >
      <TableCell
        className={classes.tableLink}
        data-test="id-cell"
      >
        {row.id}
      </TableCell>
      <TableCell data-test="registration-date-cell">
        <div className={classes.registrationDate} data-test="registration-date">
          {formatDate(row.creationDate)}
        </div>
      </TableCell>
      <TableCell data-test="employee-cell">
        <div
          data-test="employee"
        >
          {row?.employee?.value}
        </div>
      </TableCell>
      <TableCell data-test="start-date-cell">
        <div className={classes.startEndDates} data-test="start-date">
          {formatDate(row.businessTripStartDate)}
        </div>
      </TableCell>
      <TableCell data-test="end-date-cell">
        <div className={classes.startEndDates} data-test="end-date">
          {formatDate(row.businessTripFinishDate)}
        </div>
      </TableCell>
      {isPendingMyApprovalRequests && (
      <TableCell data-test="company-cell">
        <OverflowTip
          textProps={{ style: { color: darkGray, fontSize: 14, lineHeight: 1.29 } }}
          maxLine={1}
          text={row?.company?.value}
          tooltipTitle={row?.company?.value}
          data-test="business-trip-type"
        />
      </TableCell>
      )}
      <TableCell data-test="type-cell">
        <OverflowTip
          textProps={{ style: { color: darkGray, fontSize: 14, lineHeight: 1.29 } }}
          maxLine={1}
          text={row?.businessTripType?.value}
          tooltipTitle={row?.businessTripType?.value}
          data-test="business-trip-type"
        />
      </TableCell>
      {isPendingMyApprovalRequests && (
      <TableCell data-test="coordinator-cell">
        <OverflowTip
          textProps={{ style: { color: darkGray, fontSize: 14, lineHeight: 1.29 } }}
          maxLine={1}
          text={row?.pendingApprover?.value || INPUT_EMPTY_VALUE}
          tooltipTitle={row?.pendingApprover?.value || INPUT_EMPTY_VALUE}
          data-test="business-trip-type"
        />
      </TableCell>
      )}
      <TableCell data-test="status-cell">
        <Status status={row.status} />
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    businessTripStartDate: PropTypes.string.isRequired,
    businessTripFinishDate: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default function AdaptiveTable({
  data,
  onRowClick,
  loading,
  onChangeSorting,
  sortingParams,
  requestTypeParam,
  tabsAreVisible,
}) {
  const { t } = useTranslation();
  const isPendingMyApprovalRequests = requestTypeParam === 'PendingMyApproval';
  const classes = useTableStyles({ isPendingMyApprovalRequests, tabsAreVisible });
  const tableRef = createRef(null);
  const { width } = useWindowDimensions();
  const isTabletView = width <= 1024;

  useEffect(() => {
    if (!data || isEmpty(data)) return;
    if (tableRef.current) tableRef.current.scrollTop = 0;
  }, [data]);

  const renderTable = () => {
    if (isTabletView) {
      return (
        <>
          <TableHead>
            <TableRow className={classes.mainHeaderRow} data-test="header-row">
              <TableCell
                className={clsx(classes.mainHeaderItem, classes.tabletIdCell)}
                data-test="header-id-cell"
              >
                <ButtonBase
                  disableRipple
                  onClick={() => onChangeSorting({ sortingField: 'id' })}
                >
                  <span>
                    ID
                  </span>
                  {sortingParams.sortingField === 'id' && sortingParams.sortingOrder === 'asc'
                    ? (
                      <ArrowDownIcon
                        className={clsx(classes.sortingButton, classes.sortingButtonActive)}
                      />
                    )
                    : (
                      <ArrowUpIcon
                        className={clsx(classes.sortingButton, sortingParams.sortingField === 'id' && classes.sortingButtonActive)}
                      />
                    )}
                </ButtonBase>
              </TableCell>
              <TableCell
                className={clsx(classes.mainHeaderItem, classes.tabletRegistrationDateCell)}
                data-test="header-registration-date-seconded-cell"
              >
                <div
                  className={classes.mainHeaderItem}
                  data-test="header-registration-date"
                >
                  {t('DATE_OF_REGISTRATION')}
                </div>
                <div
                  className={classes.mainHeaderItem}
                  data-test="header-seconded"
                >
                  {t('EMPLOYEE')}
                </div>
              </TableCell>
              <TableCell
                className={clsx(classes.mainHeaderItem, classes.tabletStartEndDatesCell)}
                data-test="header-start-end-dates-type-cell"
              >
                <div
                  // className={classes.mainHeaderItem}
                  data-test="header-start-end-dates"
                >
                  {`${t('START_DATE')} / ${t('COMPLETION')}`}
                </div>
                <div
                  data-test="header-type"
                >
                  {t('TYPE_OF_BUSINESS_TRIP')}
                </div>
              </TableCell>
              {isPendingMyApprovalRequests && (
                <TableCell
                  className={clsx(classes.mainHeaderItem, classes.tabletApproverCompanyCell)}
                  data-test="header-approuver-company-cell"
                >
                  <div
                    className={classes.mainHeaderItem}
                    data-test="header-approver"
                  >
                    {t('APPROUVER')}
                  </div>
                  <div
                    data-test="header-company"
                    className={classes.mainHeaderItem}
                  >
                    {t('COMPANY')}
                  </div>
                </TableCell>
              )}
              <TableCell
                className={clsx(classes.mainHeaderItem, classes.tabletStatusCell)}
                data-test="header-status-cell"
              >
                <ButtonBase
                  disableRipple
                  onClick={() => onChangeSorting({ sortingField: 'status' })}
                >
                  <span>
                    {t('STATUS')}
                  </span>
                  {(sortingParams.sortingField === 'status' && sortingParams.sortingOrder === 'asc'
                  )
                    ? (
                      <ArrowDownIcon
                        className={clsx(classes.sortingButton, classes.sortingButtonActive)}
                      />
                    )
                    : (
                      <ArrowUpIcon
                        className={clsx(classes.sortingButton, sortingParams.sortingField === 'status' && classes.sortingButtonActive)}
                      />
                    )}
                </ButtonBase>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TabletTabletRow
                requestTypeParam={requestTypeParam}
                key={row.id}
                row={row}
                isEven={rowIndex % 2 === 0}
                onRowClick={onRowClick}
              />
            ))}
            {isEmpty(data) && !loading && (
              <TableCell colSpan={8} className={classes.noDataCell}>
                <div className={classes.noDataCellContent}>
                  <NoResultsBlock
                    title={t('NO_DATA_TO_DISPLAY')}
                  />
                </div>
              </TableCell>
            )}
          </TableBody>
        </>
      );
    }
    return (
      <>
        <TableHead>
          <TableRow className={classes.mainHeaderRow} data-test="header-row">
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.idCell)} // 80
              data-test="header-id-cell"
            >
              <ButtonBase
                disableRipple
                onClick={() => onChangeSorting({ sortingField: 'id' })}
              >
                <span>
                  ID
                </span>
                {sortingParams.sortingField === 'id' && sortingParams.sortingOrder === 'asc'
                  ? (
                    <ArrowDownIcon
                      className={clsx(classes.sortingButton, classes.sortingButtonActive)}
                    />
                  )
                  : (
                    <ArrowUpIcon
                      className={clsx(classes.sortingButton, sortingParams.sortingField === 'id' && classes.sortingButtonActive)}
                    />
                  )}
              </ButtonBase>
            </TableCell>
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.dateCell)}
              data-test="header-registration-date-cell"
            >
              <div
                className={classes.mainHeaderItem}
                data-test="header-registration-date"
              >
                {t('DATE_OF_REGISTRATION')}
              </div>
            </TableCell>
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.employeeCell)}
              data-test="header-seconded-cell"
            >
              <div
                className={classes.seconded}
                data-test="header-seconded"
              >
                {t('EMPLOYEE')}
              </div>
            </TableCell>
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.startDateCell)}
              data-test="header-start-date-cell"
            >
              <div
                className={classes.startDate}
                data-test="header-start-date"
              >
                {t('START_DATE')}
              </div>
            </TableCell>
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.endDateCell)}
              data-test="header-end-date-cell"
            >
              <div
                className={classes.endDate}
                data-test="header-end-date"
              >
                {t('COMPLETION_DATE')}
              </div>
            </TableCell>
            {isPendingMyApprovalRequests && (
              <TableCell
                className={clsx(classes.mainHeaderItem, classes.companyCell)}
                data-test="header-company-cell"
              >
                <div
                  className={classes.company}
                  data-test="header-company"
                >
                  {t('COMPANY')}
                </div>
              </TableCell>
            )}
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.typeCell)}
              data-test="header-type-cell"
            >
              <div
                className={classes.mainHeaderItem}
                data-test="header-type"
              >
                {t('TYPE_OF_BUSINESS_TRIP')}
              </div>
            </TableCell>
            {isPendingMyApprovalRequests && (
              <TableCell
                className={clsx(classes.mainHeaderItem, classes.approverCell)}
                data-test="header-coordinator-cell"
              >
                <div
                  className={classes.coordinator}
                  data-test="header-coordinator"
                >
                  {t('APPROUVER')}
                </div>
              </TableCell>
            )}
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.statusCell)}
              data-test="header-status-cell"
            >
              <ButtonBase
                disableRipple
                onClick={() => onChangeSorting({ sortingField: 'status' })}
              >
                <span>
                  {t('STATUS')}
                </span>
                {(sortingParams.sortingField === 'status' && sortingParams.sortingOrder === 'asc'
                )
                  ? (
                    <ArrowDownIcon
                      className={clsx(classes.sortingButton, classes.sortingButtonActive)}
                    />
                  )
                  : (
                    <ArrowUpIcon
                      className={clsx(classes.sortingButton, sortingParams.sortingField === 'status' && classes.sortingButtonActive)}
                    />
                  )}
              </ButtonBase>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <Row
              key={row.id}
              requestTypeParam={requestTypeParam}
              row={row}
              isEven={rowIndex % 2 === 0}
              onRowClick={onRowClick}
            />
          ))}
          {isEmpty(data) && !loading && (
            <TableCell colSpan={8} className={classes.noDataCell}>
              <div className={classes.noDataCellContent}>
                <NoResultsBlock
                  title={t('NO_DATA_TO_DISPLAY')}
                />
              </div>
            </TableCell>
          )}
        </TableBody>
      </>
    );
  };

  return (
    <TableContainer
      component={Paper}
      className={classes.tableContainer}
      ref={tableRef}
    >
      <Table
        aria-label="collapsible table"
        stickyHeader
      >
        {renderTable()}
      </Table>
    </TableContainer>
  );
}
