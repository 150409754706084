import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import LinesEllipsis from 'react-lines-ellipsis';

import useStyles from './styles';

const OverflowTip = ({
  tooltipTitle,
  text,
  tooltipProps,
  textProps,
  'data-test': dataTest,
  maxLine,
}) => {
  const classes = useStyles();
  const [isClamped, setIsClamped] = useState(false);
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  const { style: textPropsStyle } = textProps;

  return (
    <Tooltip
      title={tooltipTitle}
      interactive
      disableHoverListener={!isClamped}
      classes={{ tooltip: classes.tooltip }}
      {...tooltipProps}
    >
      <div
        className={classes.responsiveEllipsisWrapper}
        {...textPropsStyle && { style: { ...textPropsStyle } }}
      >
        <ResponsiveEllipsis
          {...dataTest && { 'data-test': dataTest, value: text }}
          onReflow={({ clamped }) => {
            if (clamped !== isClamped) {
              setIsClamped(clamped);
            }
          }}
          text={text || ''}
          maxLine={maxLine}
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
    </Tooltip>
  );
};

OverflowTip.defaultProps = {
  text: '',
  tooltipTitle: '',
  tooltipProps: {},
  textProps: {},
  'data-test': '',
  maxLine: 1,
  notClampedEndAdornment: null,
};

OverflowTip.propTypes = {
  tooltipTitle: PropTypes.string,
  text: PropTypes.string,
  maxLine: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltipProps: PropTypes.shape({
    placement: PropTypes.string,
  }),
  textProps: PropTypes.shape({
    style: PropTypes.any // eslint-disable-line
  }),
  'data-test': PropTypes.string,
  notClampedEndAdornment: PropTypes.node,
};

export default OverflowTip;
