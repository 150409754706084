import React from 'react';
import { Typography } from 'components/ui-lib';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoAccessSvg } from './assets/noAccess.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  mailToLink: {
    color: theme.colors.primaryLight,
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textDecoration: 'none',
  },
}
));

const NoAccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <NoAccessSvg />
      <Typography variant="h2" data-test="no-access-title">
        {t('NO_ACCESS_PAGE_MESSAGE')}
      </Typography>
      <Typography
        variant="subtitle2"
        color="secondary"
        data-test="no-access-subtitle"
      >
        {t('NO_ACCESS_PAGE_SUB_MESSAGE')}
        &nbsp;
        <a
          data-test="linkToMainPage"
          className={classes.mailToLink}
          href="mailto:helpdesk@dtek.com?subject=Business Trips rights access"
        >
          {t('TECHNICAL_SUPPORT')}
        </a>
      </Typography>
    </div>
  );
};

export default NoAccess;
