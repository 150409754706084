import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tabs: {
    '& .MuiTab-root': {
      borderBottom: `2px solid ${theme.colors.black20}`,
      textTransform: 'none',
      fontSize: 16,
      color: theme.colors.fullBlack,
    },
    '& .Mui-selected': {
      fontWeight: 'bold',
      backgroundColor: theme.colors.dtekLightYellow,
    },
  },
  indicator: {
    height: 4,
    backgroundColor: theme.colors.dtekYellow,
  },
}
));

export default useStyles;
