import React from 'react';
import PropTypes from 'prop-types';
import { Switch as MaterialSwitch, CircularProgress } from '@material-ui/core';

import { defaultSwitchStyles } from './styles';

const Switch = (props) => {
  const { isUpdating, checked, type } = props;

  const defaultSwitchClasses = defaultSwitchStyles();

  const {
    switchBase, checked: checkedClass, colorSecondary, track,
  } = defaultSwitchClasses;

  const typeConfigs = {
    default: {
      classes: {
        switchBase, checked: checkedClass, colorSecondary, track,
      },
      disableRipple: true,
      color: 'primary',
    },
  };

  const config = typeConfigs[type] || typeConfigs.default;

  const Icon = () => (
    <div
      className={`${defaultSwitchClasses.circle} ${checked && isUpdating ? defaultSwitchClasses.active : ''}`}
    >
      {isUpdating && (
        <CircularProgress
          size={14}
          thickness={4}
          color="inherit"
        />
      )}
    </div>
  );

  return (
    <MaterialSwitch
      checked={checked}
      {...config}
      checkedIcon={<Icon />}
      icon={<Icon />}
    />
  );
};

Switch.defaultProps = {
  isUpdating: false,
  checked: false,
  type: 'default',
};

Switch.propTypes = {
  isUpdating: PropTypes.bool,
  checked: PropTypes.bool,
  type: PropTypes.oneOf(['default']),
};

export default Switch;
