import {
  format,
  isValid as isValidDateFns,
  parseISO,
  formatISO,
  parse,
  isMatch,
} from 'date-fns';

export const DISPLAY_DATE_FORMAT = 'dd/MM/yyyy';
export const DISPLAY_DATE_FORMAT_FILENAME = 'dd-MM-yyyy';
export const BE_DATE_FORMAT = 'yyyy-MM-dd';

export const DISPLAY_TIME_FORMAT = 'HH:mm';
export const DISPLAY_TIME_FORMAT_FILENAME = 'HH-mm';

export const isValid = (date, noParse) => isValidDateFns(noParse ? date : parseISO(date));

export const formatDate = (date, type = DISPLAY_DATE_FORMAT) => {
  if (isValid(date)) return format(new Date(parseISO(date)), type);
  return '';
};

export const formatTime = (date, type = DISPLAY_TIME_FORMAT) => {
  if (isValid(date)) return format(new Date(parseISO(date)), type);
  return '';
};

export const toRepresentativeFormat = (date) => {
  if (isValid(date)) return formatISO(date);
  return '';
};

export const formatDateStr = (date, formatStr = BE_DATE_FORMAT) => format(date, formatStr);

export const parseStrToDate = (dateStr) => {
  if (!isMatch(dateStr, BE_DATE_FORMAT)) return new Date();
  return parse(dateStr, BE_DATE_FORMAT, new Date());
};
