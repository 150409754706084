import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Tab, Tabs, Typography, TextField, FormControl, FormHelperText, FormControlLabel,
  Divider,
} from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { v4 as aniqueId } from 'uuid';
import { isValid } from 'date-fns';
import clsx from 'clsx';

import {
  loadAssistedUsers,
} from 'store/trip';
import CustomSelect from 'components/PartCustomSelect';
import FullWidthButton from 'components/FullWidthButton';
import Switch from 'components/Switch';
import { IconButton } from 'components/ui-lib';
import { blocksName } from 'constants/main';
import DatePicker from 'components/DatePicker/SimpleDatePicker';
import LimitsTab from 'pages/Request/compoments/LimitsTab';
import useAppStyles from 'containers/App/AppStyles';
import useStyles from './RequestFormStyles';

const RequestForm = ({
  isCreateTripForSomeOne,
  values,
  errors,
  initBlockValues,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const appClasses = useAppStyles();
  const dispatch = useDispatch();

  const {
    businessTripTypesDictionary,
    countriesDictionary,
    serviceTypesDictionary,
    ticketClassesDictionary,
    ticketTypesDictionary,
    businessTripLimitsDictionary,
  } = useSelector((state) => state.dictionaries?.dictionaries);

  const {
    assistedUsers = [],
  } = useSelector((state) => state.trip);

  const [tabIndex, setTabIndex] = useState(0);

  const handleAddBlock = (blockNameStr) => {
    const blockListKey = `${blockNameStr}s`;

    if (!values[blockListKey]) {
      return console.error(`UI - The set value "${blockListKey}" is not exists`);
    }

    setFieldValue(
      blockListKey,
      [...values[blockListKey], {
        ...initBlockValues[blockNameStr],
        tempId: aniqueId(),
      }],
      false,
    );
  };

  const handleRemoveBlock = (blockNameStr, indexForRemove) => {
    const blockListKey = `${blockNameStr}s`;
    if (!values[blockListKey]) {
      return console.error(`UI - The set value "${blockListKey}" is not exists`);
    }

    setFieldValue(
      blockListKey,
      values[blockListKey].filter((item, index) => index !== indexForRemove),
      false,
    );
  };

  const renderRemoveBlockBtn = (blockNameStr, indexForRemove) => (
    <IconButton
      type="secondary"
      data-test={`remove-block-${indexForRemove}`}
      className={classes.removeBlockBtn}
      onClick={() => handleRemoveBlock(blockNameStr, indexForRemove)}
    >
      <DeleteOutline />
    </IconButton>
  );

  useEffect(() => {
    if (!isCreateTripForSomeOne) return;

    dispatch(loadAssistedUsers());
  }, [isCreateTripForSomeOne]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        variant="fullWidth"
        classes={{ root: clsx(appClasses.tabs, appClasses.stickyTabs), indicator: appClasses.indicator }}
      >
        <Tab label={t('REQUEST')} data-test="request-tab" />
        <Tab label={t('LIMITS')} data-test="limits-tab" />
      </Tabs>
      {tabIndex === 0 && (
        <Box px={3} pt={1} pb={3} className={classes.formSection}>
          <Typography
            variant="h2"
            data-test="event-attributes-title"
          >
            {t('GENERAL_INFO')}
          </Typography>
          <Box style={{ padding: '0 80px' }}>
            <Box className={classes.eventsAttrs}>
              <Box className={classes.eventsAttrsLeft}>
                {isCreateTripForSomeOne && (
                  <FormControl variant="outlined" className={classes.formControlRow}>
                    <CustomSelect
                      label={t('EMPLOYEE')}
                      data-test="employee"
                      options={assistedUsers}
                      optionValue="name"
                      error={Boolean(errors?.employeeId)}
                      value={assistedUsers.find(({ id }) => id === values.employeeId)}
                      onChange={(val) => {
                        setFieldValue('employeeId', val.id, false);
                      }}
                    />
                    <FormHelperText error={Boolean(errors?.employeeId)}>
                      {t('REQUIRED_FIELD')}
                    </FormHelperText>
                  </FormControl>
                )}
                {!isCreateTripForSomeOne && <FormControl variant="outlined" className={classes.formControlRow} />}
                <FormControl variant="outlined" className={classes.formControlRow}>
                  <CustomSelect
                    label={t('TYPE_OF_BUSINESS_TRIP')}
                    data-test="type-of-business-trip"
                    options={businessTripTypesDictionary}
                    optionValue="value"
                    value={businessTripTypesDictionary.find(({ id }) => id === values.businessTripTypeId)}
                    error={Boolean(errors?.businessTripTypeId)}
                    onChange={(val) => {
                      setFieldValue('businessTripTypeId', val.id, false);
                    }}
                  />
                  <FormHelperText error={Boolean(errors?.businessTripTypeId)}>
                    {t('REQUIRED_FIELD')}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.eventsAttrsRight}>
                <FormControl variant="outlined" className={classes.formControlRow}>
                  <DatePicker
                    fullWidth
                    data-test="business-trip-start-date"
                    // addOneMonth
                    minDate={new Date()}
                    selectedDate={values.businessTripStartDate}
                    label={t('START_DATE')}
                    helperText={t(errors?.businessTripStartDate || 'REQUIRED_FIELD')}
                    error={Boolean(errors.businessTripStartDate)}
                    handleDateChange={(date) => {
                      setFieldValue(
                        'businessTripStartDate',
                        isValid(date) ? date.toISOString() : date,
                        false,
                      );
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControlRow}>
                  <DatePicker
                    fullWidth
                    data-test="business-trip-finish-date"
                    minDate={new Date(values.businessTripStartDate)}
                    selectedDate={values.businessTripFinishDate}
                    label={t('COMPLETION_DATE')}
                    helperText={t(errors?.businessTripFinishDate || 'REQUIRED_FIELD')}
                    error={Boolean(errors?.businessTripFinishDate)}
                    handleDateChange={(date) => {
                      setFieldValue(
                        'businessTripFinishDate',
                        isValid(date) ? date.toISOString() : date,
                        false,
                      );
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={classes.formControlRow}>
              <TextField
                fullWidth
                multiline
                data-test="business-trip-goal"
                inputProps={{ maxLength: 1024 }}
                variant="outlined"
                label={t('PURPOSE')}
                value={values.businessTripGoal}
                onChange={({ target }) => {
                  setFieldValue('businessTripGoal', target.value, false);
                }}
              />
            </Box>
          </Box>

          <Divider className={classes.blockDivider} />
          <Box>
            <Typography
              className={classes.blockTitle}
              variant="h2"
              data-test="destinations-block"
            >
              {t('DESTINATIONS')}
            </Typography>
            {values.destinations.map((it, index) => (
              <Box
                key={it.id || it.tempId}
                className={classes.shadowBlock}
                data-test={`destinations-${index}`}
              >
                {values.destinations.length > 1 && renderRemoveBlockBtn(blocksName.DESTINATION, index)}
                <Box className={appClasses.flexWrapBetween}>
                  <Box className={classes.eventsAttrsLeft}>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <CustomSelect
                        data-test="country"
                        autocomplete
                        label={t('COUNTRY')}
                        options={countriesDictionary}
                        optionValue="value"
                        value={countriesDictionary.find(({ id }) => id === it.countryId)}
                        error={Boolean(errors?.destinations?.[index]?.countryId)}
                        onChange={(val) => {
                          setFieldValue(`destinations[${index}].countryId`, val.id, false);
                        }}
                      />
                      <FormHelperText error={Boolean(errors?.destinations?.[index]?.countryId)}>
                        {t('REQUIRED_FIELD')}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        data-test="destination"
                        helperText={t('REQUIRED_FIELD')}
                        label={t('DESTINATION')}
                        value={it.destination}
                        error={Boolean(errors?.destinations?.[index]?.destination)}
                        onChange={({ target }) => {
                          setFieldValue(`destinations[${index}].destination`, target.value, false);
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box className={classes.eventsAttrsRight}>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <DatePicker
                        fullWidth
                        data-test={`destinations-${index}-start-date`}
                        disabled={!(values.businessTripStartDate && values.businessTripFinishDate)}
                        minDate={new Date(values.businessTripStartDate)}
                        maxDate={new Date(values.businessTripFinishDate)}
                        selectedDate={it.startDate}
                        label={t('START_DATE')}
                        helperText={t(errors?.destinations?.[index]?.startDate || 'REQUIRED_FIELD')}
                        error={Boolean(errors?.destinations?.[index]?.startDate)}
                        handleDateChange={(date) => {
                          // setFieldValue(`destinations[${index}].startDate`, isValid(date) ?
                          // new Date(date) : date, false);
                          setFieldValue(`destinations[${index}].startDate`, isValid(date) ? date.toISOString() : date, false);
                        }}
                      />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <DatePicker
                        fullWidth
                        data-test={`destinations-${index}-finish-date`}
                        disabled={!(values.businessTripStartDate && values.businessTripFinishDate)}
                        minDate={new Date(values.businessTripStartDate)}
                        maxDate={new Date(values.businessTripFinishDate)}
                        selectedDate={it.finishDate}
                        label={t('COMPLETION_DATE')}
                        helperText={t(errors?.destinations?.[index]?.finishDate || 'REQUIRED_FIELD')}
                        error={Boolean(errors?.destinations?.[index]?.finishDate)}
                        handleDateChange={(date) => {
                          // setFieldValue(`destinations[${index}].finishDate`, isValid(date) ?
                          // new Date(date) : date, false);
                          setFieldValue(`destinations[${index}].finishDate`, isValid(date) ? date.toISOString() : date, false);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box className={classes.formControlRowWithMargin}>
                  <TextField
                    fullWidth
                    multiline
                    data-test={`destinations-${index}-comment`}
                    maxRows={10}
                    variant="outlined"
                    label={t('COMMENTS')}
                    inputProps={{ maxLength: 1024 }}
                    value={it.comment}
                    onChange={({ target }) => {
                      setFieldValue(`destinations[${index}].comment`, target.value, false);
                    }}
                  />
                </Box>
              </Box>
            ))}
            {values.destinations.length < 7 && (
              <FullWidthButton
                fullWidth
                data-test="add-destination"
                size="large"
                type="cart-dashed"
                startIcon={<Add />}
                onClick={() => handleAddBlock(blocksName.DESTINATION)}
              >
                {t('ADD_DESTINATION')}
              </FullWidthButton>
            )}
          </Box>
          <Divider className={classes.blockDivider} />

          <Box>
            <Typography
              className={classes.blockTitle}
              variant="h2"
              data-test="tickets-block"
            >
              {t('TICKETS')}
            </Typography>
            {values.tickets.map((it, index) => (
              <Box
                data-test={`tickets-${index}`}
                key={it.id || it.tempId}
                className={classes.shadowBlock}
              >
                {renderRemoveBlockBtn(blocksName.TICKET, index)}
                <Box className={appClasses.flexWrapBetween}>
                  <Box className={classes.eventsAttrsLeft}>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <CustomSelect
                        label={t('TYPE_OF_TICKET')}
                        data-test={`tickets-${index}-type`}
                        options={ticketTypesDictionary}
                        optionValue="value"
                        error={Boolean(errors?.tickets?.[index]?.ticketTypeId)}
                        value={ticketTypesDictionary.find(({ id }) => id === it.ticketTypeId)}
                        onChange={(val) => {
                          setFieldValue(`tickets[${index}].ticketTypeId`, val.id, false);
                        }}
                      />
                      <FormHelperText error={Boolean(errors?.tickets?.[index]?.ticketTypeId)}>
                        {t('REQUIRED_FIELD')}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        data-test={`tickets-${index}-from`}
                        helperText={t('REQUIRED_FIELD')}
                        error={Boolean(errors?.tickets?.[index]?.from)}
                        label={t('FROM')}
                        value={it.from}
                        onChange={({ target }) => {
                          setFieldValue(`tickets[${index}].from`, target.value, false);
                        }}
                      />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={t('TO')}
                        data-test={`tickets-${index}-to`}
                        helperText={t('REQUIRED_FIELD')}
                        error={Boolean(errors?.tickets?.[index]?.to)}
                        value={it.to}
                        onChange={({ target }) => {
                          setFieldValue(`tickets[${index}].to`, target.value, false);
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box className={classes.eventsAttrsRight}>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <CustomSelect
                        label={t('CLASS_OF_TICKET')}
                        data-test={`tickets-${index}-class`}
                        options={ticketClassesDictionary}
                        optionValue="value"
                        value={ticketClassesDictionary.find(({ id }) => id === it.ticketClassId)}
                        onChange={(val) => {
                          setFieldValue(`tickets[${index}].ticketClassId`, val.id, false);
                        }}
                      />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <DatePicker
                        fullWidth
                        data-test={`tickets-${index}-departure-date`}
                        disabled={!(values.businessTripStartDate && values.businessTripFinishDate)}
                        minDate={new Date(values.businessTripStartDate)}
                        maxDate={new Date(values.businessTripFinishDate)}
                        selectedDate={it.departureDate}
                        label={t('DEPARTURE_DATE')}
                        helperText={t(errors?.tickets?.[index]?.departureDate || 'REQUIRED_FIELD')}
                        error={Boolean(errors?.tickets?.[index]?.departureDate)}
                        handleDateChange={(date) => {
                          setFieldValue(`tickets[${index}].departureDate`, isValid(date) ? date.toISOString() : date, false);
                        }}
                      />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        data-test={`tickets-${index}-departure-time`}
                        label={t('DEPARTURE_TIME')}
                        type="time"
                        value={it.departureTime}
                        onChange={({ target }) => {
                          setFieldValue(`tickets[${index}].departureTime`, target.value, false);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box className={classes.formControlRowWithMargin}>
                  <TextField
                    fullWidth
                    multiline
                    maxRows={10}
                    data-test={`tickets-${index}-comment`}
                    inputProps={{ maxLength: 1024 }}
                    variant="outlined"
                    label={t('COMMENTS')}
                    value={it.comment}
                    onChange={({ target }) => {
                      setFieldValue(`tickets[${index}].comment`, target.value, false);
                    }}
                  />
                </Box>
              </Box>
            ))}
            {values.tickets.length < 10 && (
              <FullWidthButton
                fullWidth
                size="large"
                type="cart-dashed"
                startIcon={<Add />}
                onClick={() => handleAddBlock(blocksName.TICKET)}
                data-test="add-flight-train"
              >
                {t('ADD_FLIGHT_TRAIN')}
              </FullWidthButton>
            )}
          </Box>
          <Divider className={classes.blockDivider} />

          <Box>
            <Typography
              className={classes.blockTitle}
              variant="h2"
              data-test="hotels-block"
            >
              {t('HOTELS')}
            </Typography>
            {values.hotels.map((it, index) => (
              <Box
                key={it.id || it.tempId}
                className={classes.shadowBlock}
                data-test={`hotels-${index}`}
              >
                {renderRemoveBlockBtn(blocksName.HOTEL, index)}
                <Box className={appClasses.flexWrapBetween}>
                  <Box className={classes.eventsAttrsLeft}>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={t('LOCALITY')}
                        data-test={`hotels-${index}.location`}
                        helperText={t('REQUIRED_FIELD')}
                        error={Boolean(errors?.hotels?.[index]?.location)}
                        value={it.location}
                        onChange={({ target }) => {
                          setFieldValue(`hotels[${index}].location`, target.value, false);
                        }}
                      />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <FormControlLabel
                        control={(
                          <Switch
                            data-test={`hotels-${index}.earlyCheckIn`}
                            name="developmentFilter"
                            color="primary"
                            checked={it.earlyCheckIn}
                            value={it.earlyCheckIn}
                            onChange={(e, val) => {
                              setFieldValue(`hotels[${index}].earlyCheckIn`, val, false);
                            }}
                          />
                        )}
                        label={(<Typography variant="subtitle2">{t('EARLY_CHECKIN')}</Typography>)}
                      />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <FormControlLabel
                        control={(
                          <Switch
                            data-test={`hotels-${index}-late-check-out`}
                            name="developmentFilter"
                            color="primary"
                            checked={it.lateCheckOut}
                            value={it.lateCheckOut}
                            onChange={(e, val) => {
                              setFieldValue(`hotels[${index}].lateCheckOut`, val, false);
                            }}
                          />
                        )}
                        label={(<Typography variant="subtitle2">{t('LATE_CHECKOUT')}</Typography>)}
                      />
                    </FormControl>
                  </Box>
                  <Box className={classes.eventsAttrsRight}>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <DatePicker
                        fullWidth
                        data-test={`hotels-${index}-arrival-date`}
                        disabled={!(values.businessTripStartDate && values.businessTripFinishDate)}
                        minDate={new Date(values.businessTripStartDate)}
                        maxDate={new Date(values.businessTripFinishDate)}
                        selectedDate={it.arrivalDate}
                        label={t('CHECKIN_DATE')}
                        helperText={t(errors?.hotels?.[index]?.arrivalDate || 'REQUIRED_FIELD')}
                        error={Boolean(errors?.hotels?.[index]?.arrivalDate)}
                        handleDateChange={(date) => {
                          setFieldValue(`hotels[${index}].arrivalDate`, isValid(date) ? date.toISOString() : date, false);
                        }}
                      />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <DatePicker
                        fullWidth
                        data-test={`hotels-${index}-departure-date`}
                        disabled={!(values.businessTripStartDate && values.businessTripFinishDate)}
                        minDate={new Date(values.businessTripStartDate)}
                        maxDate={new Date(values.businessTripFinishDate)}
                        selectedDate={it.departureDate}
                        label={t('CHECKOUT_DATE')}
                        helperText={t(errors?.hotels?.[index]?.departureDate || 'REQUIRED_FIELD')}
                        error={Boolean(errors?.hotels?.[index]?.departureDate)}
                        handleDateChange={(date) => {
                          setFieldValue(`hotels[${index}].departureDate`, isValid(date) ? date.toISOString() : date, false);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box className={classes.formControlRowWithMargin}>
                  <TextField
                    fullWidth
                    multiline
                    maxRows={10}
                    data-test={`hotels-${index}-comment`}
                    inputProps={{ maxLength: 1024 }}
                    variant="outlined"
                    label={t('COMMENTS')}
                    value={it.comment}
                    onChange={({ target }) => {
                      setFieldValue(`hotels[${index}].comment`, target.value, false);
                    }}
                  />
                </Box>
              </Box>
            ))}
            {values.hotels.length < 10 && (
              <FullWidthButton
                fullWidth
                size="large"
                type="cart-dashed"
                startIcon={<Add />}
                onClick={() => handleAddBlock(blocksName.HOTEL)}
                data-test="add-hotel"
              >
                {t('ADD_HOTEL')}
              </FullWidthButton>
            )}
          </Box>
          <Divider className={classes.blockDivider} />

          <Box>
            <Typography
              className={classes.blockTitle}
              variant="h2"
              data-test="accompanying-services-block"
            >
              {t('ACCOMPANYING_SERVICES')}
            </Typography>
            {values.additionalServices.map((it, index) => (
              <Box
                data-test={`accompanying-services-${index}`}
                key={it.id || it.tempId}
                className={classes.shadowBlock}
              >
                {renderRemoveBlockBtn(blocksName.ADDITIONAL_SERVICE, index)}
                <Box className={appClasses.flexWrapBetween}>
                  <Box className={classes.eventsAttrsLeft}>
                    <FormControl variant="outlined" className={classes.formControlRow}>
                      <CustomSelect
                        data-test={`accompanying-services-${index}-service-id`}
                        label={t('SERVICE')}
                        options={serviceTypesDictionary}
                        optionValue="value"
                        error={Boolean(errors?.additionalServices?.[index]?.serviceId)}
                        value={serviceTypesDictionary.find(({ id }) => id === it.serviceId)}
                        onChange={(val) => {
                          setFieldValue(`additionalServices[${index}].serviceId`, val.id, false);
                        }}
                      />
                      <FormHelperText error={Boolean(errors?.additionalServices?.[index]?.serviceId)}>
                        {t('REQUIRED_FIELD')}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box className={classes.eventsAttrsRight} />
                </Box>
                <Box className={classes.formControlRowWithMargin}>
                  <TextField
                    data-test={`accompanying-services-${index}-comment`}
                    fullWidth
                    multiline
                    maxRows={10}
                    variant="outlined"
                    label={t('COMMENTS')}
                    value={it.comment}
                    onChange={({ target }) => {
                      setFieldValue(`additionalServices[${index}].comment`, target.value, false);
                    }}
                  />
                </Box>
              </Box>
            ))}
            {values.additionalServices.length < 10 && (
              <FullWidthButton
                fullWidth
                size="large"
                type="cart-dashed"
                startIcon={<Add />}
                onClick={() => handleAddBlock(blocksName.ADDITIONAL_SERVICE)}
                data-test="add-service"
              >
                {t('ADD_SERVICE')}
              </FullWidthButton>
            )}
          </Box>
          <Divider className={classes.blockDivider} />
        </Box>
      )}
      {tabIndex === 1 && <LimitsTab limits={businessTripLimitsDictionary} />}
    </>
  );
};

export default RequestForm;
