import routes from 'routes';
import NoAccess from 'pages/NoAccess';
import Main from 'pages/Main';
import CreateRequest from 'pages/CreateRequest';
import Request from 'pages/Request';
import EditRequest from 'pages/EditRequest';

import {
  CREATE_REQUEST,
  CREATE_REQUEST_FOR_SOMEONE,
} from 'constants/permissions';

const routesList = [
  {
    name: 'main',
    path: routes.main,
    component: Main,
    exact: true,
  },
  {
    name: 'createTrip',
    path: routes.createTrip,
    component: CreateRequest,
    exact: true,
    permission: CREATE_REQUEST,
  },
  {
    name: 'createTripForSomeone',
    path: routes.createTripForSomeone,
    component: CreateRequest,
    exact: true,
    permission: CREATE_REQUEST_FOR_SOMEONE,
  },
  {
    name: 'request',
    path: routes.request,
    component: Request,
    exact: true,
  },
  {
    name: 'editEvent',
    path: routes.editEvent,
    component: EditRequest,
    exact: true,
  },
  {
    name: 'noAccess',
    path: routes.noAccess,
    component: NoAccess,
    exact: false,
  },
];

export default routesList;
