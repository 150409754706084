import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  markDownTable: {
    borderCollapse: 'collapse',
    marginBottom: 24,
    width: '100%',
    tableLayout: 'fixed',
    '& tr': {
      borderTop: `1px solid ${theme.colors.black20}`,
      background: '#fff',
      '&:nth-child(2n)': {
        background: theme.colors.black5,
      },
    },
    '& td': {
      wordBreak: 'break-word',
      padding: '6px 13px',
      border: `1px solid ${theme.colors.gray}`,
    },
    '& th': {
      background: theme.colors.black10,
      padding: '6px 13px',
      border: `1px solid ${theme.colors.gray}`,
    },
  },
}
));

export default useStyles;
