import React from 'react';
import { Typography } from 'components/ui-lib';

import { Box } from '@material-ui/core';

const TextBlock = ({
  title, children, mb = 3, 'data-test': dataTest, ...props
}) => (
  <Box {...props} mb={mb} style={{ minHeight: 48, minWidth: 200 }}>
    <Typography
      variant="caption"
      color="secondary"
      {...dataTest && { 'data-test': `${dataTest}-title` }}
    >
      {title}
    </Typography>
    <Typography
      variant="subtitle2"
      {...dataTest && { 'data-test': `${dataTest}-value` }}
    >
      {children}
    </Typography>
  </Box>
);

export default TextBlock;
