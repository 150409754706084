import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import useStyles from './ToggleAllBtnStyles';

const ToggleAllBtn = ({ unit }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.expandAllBtn}>
      {Object.keys(unit).length > 0 ? <ExpandLess /> : <ExpandMore />}
      {' '}
      {Object.keys(unit).length > 0 ? t('COLLAPSE_ALL_BLOCKS') : t('EXPAND_ALL_BLOCKS')}
    </div>
  );
};

export default ToggleAllBtn;
