import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadBusinessTripLimits,
  loadBusinessTripTypes,
  loadCompaniesList,
  loadCountriesList,
  loadRolesList,
  loadServiceTypesList,
  loadTicketClassesList,
  loadTicketTypesList,
  loadBusinessTripRequestStatusesList,
} from 'store/dictionaries';
import { SUCCEEDED } from 'constants/main';
import ContentSpinner from 'components/ContentSpinner';

const DictionariesLoader = ({ children }) => {
  const dispatch = useDispatch();
  const {
    businessTripLimitsDictionaryStatus,
    businessTripTypesDictionaryStatus,
    companiesDictionaryStatus,
    countriesDictionaryStatus,
    rolesDictionaryStatus,
    serviceTypesDictionaryStatus,
    ticketClassesDictionaryStatus,
    ticketTypesDictionaryStatus,
    businessTripRequestStatusesDictionaryStatus,
  } = useSelector((state) => state.dictionaries?.dictionaries);

  useEffect(() => {
    dispatch(loadBusinessTripLimits());
    dispatch(loadBusinessTripTypes());
    dispatch(loadCompaniesList());
    dispatch(loadCountriesList());
    dispatch(loadRolesList());
    dispatch(loadServiceTypesList());
    dispatch(loadTicketClassesList());
    dispatch(loadTicketTypesList());
    dispatch(loadBusinessTripRequestStatusesList());
  }, []);

  const dictionariesAreLoading = [
    businessTripLimitsDictionaryStatus,
    businessTripTypesDictionaryStatus,
    companiesDictionaryStatus,
    countriesDictionaryStatus,
    rolesDictionaryStatus,
    serviceTypesDictionaryStatus,
    ticketClassesDictionaryStatus,
    ticketTypesDictionaryStatus,
    businessTripRequestStatusesDictionaryStatus,
  ].some((dictionaryStatus) => dictionaryStatus !== SUCCEEDED);

  if (dictionariesAreLoading) {
    return <ContentSpinner isLoading />;
  }

  return children;
};

export default DictionariesLoader;
