/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'API';
import { LOADING, SUCCEEDED, INIT_STATUS } from 'constants/main';

export const loadBusinessTripLimits = createAsyncThunk('dictionaries/loadBusinessTripLimits', async (args, { rejectWithValue }) => {
  try {
    const response = await API.dictionary.getBusinessTripLimits();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const loadBusinessTripTypes = createAsyncThunk('dictionaries/loadBusinessTripTypes', async (args, { rejectWithValue }) => {
  try {
    const response = await API.dictionary.getBusinessTripTypes();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const loadCompaniesList = createAsyncThunk('dictionaries/loadCompaniesList', async (args, { rejectWithValue }) => {
  try {
    const response = await API.dictionary.getCompaniesList();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const loadCountriesList = createAsyncThunk('dictionaries/loadCountriesList', async (args, { rejectWithValue }) => {
  try {
    const response = await API.dictionary.getCountriesList();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const loadRolesList = createAsyncThunk('dictionaries/loadRolesList', async (args, { rejectWithValue }) => {
  try {
    const response = await API.dictionary.getRolesList();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const loadServiceTypesList = createAsyncThunk('dictionaries/serviceTypesList', async (args, { rejectWithValue }) => {
  try {
    const response = await API.dictionary.getServiceTypesList();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const loadTicketClassesList = createAsyncThunk('dictionaries/loadTicketClassesList', async (args, { rejectWithValue }) => {
  try {
    const response = await API.dictionary.getTicketClassesList();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const loadTicketTypesList = createAsyncThunk('dictionaries/loadTicketTypesList', async (args, { rejectWithValue }) => {
  try {
    const response = await API.dictionary.getTicketTypesList();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const loadBusinessTripRequestStatusesList = createAsyncThunk('dictionaries/loadBusinessTripRequestStatusesList', async (args, { rejectWithValue }) => {
  try {
    const response = await API.dictionary.getBusinessTripRequestStatusesList();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

const initialStateMetadata = {
  status: INIT_STATUS,
  error: null,
};

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState: {
    dictionaries: {
      businessTripLimitsDictionary: [],
      businessTripTypesDictionary: [],
      companiesDictionary: [],
      countriesDictionary: [],
      rolesDictionary: [],
      serviceTypesDictionary: [],
      ticketClassesDictionary: [],
      ticketTypesDictionary: [],
      businessTripRequestStatusesDictionary: [],

      businessTripLimitsDictionaryStatus: INIT_STATUS,
      businessTripTypesDictionaryStatus: INIT_STATUS,
      companiesDictionaryStatus: INIT_STATUS,
      countriesDictionaryStatus: INIT_STATUS,
      rolesDictionaryStatus: INIT_STATUS,
      serviceTypesDictionaryStatus: INIT_STATUS,
      ticketClassesDictionaryStatus: INIT_STATUS,
      ticketTypesDictionaryStatus: INIT_STATUS,
      businessTripRequestStatusesDictionaryStatus: INIT_STATUS,
      ...initialStateMetadata,
    },
  },
  reducers: {
  },
  extraReducers: {
    [loadBusinessTripTypes.pending]: (state) => {
      state.dictionaries.businessTripTypesDictionaryStatus = LOADING;
    },
    [loadBusinessTripTypes.fulfilled]: (state, action) => {
      state.dictionaries.businessTripTypesDictionary = action.payload;
      state.dictionaries.businessTripTypesDictionaryStatus = SUCCEEDED;
    },

    [loadBusinessTripLimits.pending]: (state) => {
      state.dictionaries.businessTripLimitsDictionaryStatus = LOADING;
    },
    [loadBusinessTripLimits.fulfilled]: (state, action) => {
      state.dictionaries.businessTripLimitsDictionary = action.payload;
      state.dictionaries.businessTripLimitsDictionaryStatus = SUCCEEDED;
    },

    [loadCompaniesList.pending]: (state) => {
      state.dictionaries.enterprisesDictionaryStatus = LOADING;
    },
    [loadCompaniesList.fulfilled]: (state, action) => {
      state.dictionaries.companiesDictionary = action.payload;
      // state.dictionaries.companiesNamesObject = keyBy(action.payload, ({ id } = {}) => id);
      state.dictionaries.companiesDictionaryStatus = SUCCEEDED;
    },

    [loadCountriesList.pending]: (state) => {
      state.dictionaries.countriesDictionaryStatus = LOADING;
    },
    [loadCountriesList.fulfilled]: (state, action) => {
      state.dictionaries.countriesDictionary = action.payload;
      state.dictionaries.countriesDictionaryStatus = SUCCEEDED;
    },

    [loadRolesList.pending]: (state) => {
      state.dictionaries.rolesDictionaryStatus = LOADING;
    },
    [loadRolesList.fulfilled]: (state, action) => {
      state.dictionaries.rolesDictionary = action.payload;
      state.dictionaries.rolesDictionaryStatus = SUCCEEDED;
    },

    [loadServiceTypesList.pending]: (state) => {
      state.dictionaries.serviceTypesDictionaryStatus = LOADING;
    },
    [loadServiceTypesList.fulfilled]: (state, action) => {
      state.dictionaries.serviceTypesDictionary = action.payload;
      state.dictionaries.serviceTypesDictionaryStatus = SUCCEEDED;
    },

    [loadTicketClassesList.pending]: (state) => {
      state.dictionaries.ticketClassesDictionaryStatus = LOADING;
    },
    [loadTicketClassesList.fulfilled]: (state, action) => {
      state.dictionaries.ticketClassesDictionary = action.payload;
      state.dictionaries.ticketClassesDictionaryStatus = SUCCEEDED;
    },

    [loadTicketTypesList.pending]: (state) => {
      state.dictionaries.ticketTypesDictionaryStatus = LOADING;
    },
    [loadTicketTypesList.fulfilled]: (state, action) => {
      state.dictionaries.ticketTypesDictionary = action.payload;
      state.dictionaries.ticketTypesDictionaryStatus = SUCCEEDED;
    },
    [loadBusinessTripRequestStatusesList.fulfilled]: (state, action) => {
      state.dictionaries.businessTripRequestStatusesDictionary = action.payload;
      state.dictionaries.businessTripRequestStatusesDictionaryStatus = SUCCEEDED;
    },
  },
});

export default dictionariesSlice.reducer;
export const {
  getFilteredEnterprises,
  getFilteredUnits,
  getFilteredRegionDistricts,
} = dictionariesSlice.actions;
