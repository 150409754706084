import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  filterApplyButtonWrapper: {
    height: 48,
    width: '100%',
    backgroundColor: theme.colors.white,
  },
  filterApplyButton: {
    height: 48,
    width: 'calc(100% - 16px)',
    marginRight: 8,
    marginLeft: 8,
    '&.MuiButtonBase-root': {
      backgroundColor: theme.colors.primaryLight,
    },
    '& .MuiButton-label': {
      color: '#fff',
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textAlign: 'center',
    },
    '&:disabled': {
      '&.MuiButtonBase-root': {
        backgroundColor: theme.colors.darkGray,
      },
    },
  },
}));

export default useStyles;
