import React, { useState, useEffect, useRef } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { isEmpty, get, isEqual } from 'lodash';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button } from 'components/ui-lib';
import { Add } from '@material-ui/icons';
import PageHeader from 'components/PageHeader';
import CustomTags from 'components/FilterElement';
import useAppStyles from 'containers/App/AppStyles';
import { Link, useHistory } from 'react-router-dom';
import FilterButton from 'components/FilterButton';
import DottedLoader from 'components/DottedLoader';
import ContentSpinner from 'components/ContentSpinner';
import FilterPanel from 'components/FilterPanel';
import RequestsTable from 'pages/Main/components/RequestsTable';
import Pagination from 'components/ui-lib/Pagination';
import perPageParams from 'constants/pagination';
import {
  CREATE_REQUEST, CREATE_REQUEST_FOR_SOMEONE,
} from 'constants/permissions';
import Can from 'containers/RoleModel';
import routes, { routeBits } from 'routes';
import {
  loadRequestsList,
  loadApprovePendingCount,
  loadAvailableStatuses,
  loadAvailableEmployees,
  loadAvailableCompanies,
  loadAvailableApprovers,
  onFilterUpdate,
  applyFilters,
  resetTemporaryFiltersState,
  resetFiltersState,
  changeSorting,
  setRequestTypeParam,
} from 'store/requestsList';
import DatePicker from 'components/DatePicker/SimpleDatePicker';
import { isBefore, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SUCCEEDED } from 'constants/main';
import { getUserSelector } from 'store/account';
import useStyles from './styles';

const Main = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const appClasses = useAppStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterOpened, setFilterOpened] = useState(false);
  const [datesValidationState, setDatesValidationState] = useState({});

  const requestDateFromInputRef = useRef(null);
  const requestDateToInputRef = useRef(null);

  const {
    requestsList,
    pendingCount,
    availableStatuses,
    availableStatusesLoadingStatus,
    availableEmployees,
    availableEmployeesLoadingStatus,
    availableCompanies,
    availableCompaniesLoadingStatus,
    availableApprovers,
    availableApproversLoadingStatus,
    totalCount,
    limit,
    offset,
    filtersState,
    temporaryFiltersState,
    loading,
    sortingParams,
    requestTypeParam,
  } = useSelector((state) => state.requestsList);

  const {
    isPendingMyApproveTabVisible,
    isMyRequestsTabVisible,
    tabsAreVisible,
    initialTab,
  } = useSelector(getUserSelector);

  const {
    requestDateFrom = null,
    requestDateTo = null,
    statuses = [],
    companiesIds = [],
    employeeIds = [],
    approverIds = [],
  } = temporaryFiltersState;

  const handleTabChange = (event, newValue) => {
    dispatch(setRequestTypeParam({ requestType: newValue }));
    dispatch(resetFiltersState());
    dispatch(resetTemporaryFiltersState());
    dispatch(loadRequestsList({
      limit, offset: 0, filtersState: {}, sortingParams, requestTypeParam: newValue,
    }));
  };

  useEffect(() => {
    if (!isPendingMyApproveTabVisible && !isMyRequestsTabVisible) {
      return;
    }
    if (!requestTypeParam) {
      dispatch(setRequestTypeParam({ requestType: initialTab }));
      dispatch(loadRequestsList({
        limit, offset, filtersState, sortingParams, requestTypeParam: initialTab,
      }));
      return;
    }
    dispatch(loadRequestsList({
      limit, offset, filtersState, sortingParams, requestTypeParam,
    }));
  }, []);

  useEffect(() => {
    setDatesValidationState({});
    if (isPendingMyApproveTabVisible && Boolean(requestTypeParam)) {
      dispatch(loadApprovePendingCount());
    }
    if (isPendingMyApproveTabVisible && requestTypeParam === 'PendingMyApproval') {
      dispatch(loadAvailableCompanies());
      dispatch(loadAvailableApprovers());
    }
    dispatch(loadAvailableStatuses({ requestTypeParam }));
    dispatch(loadAvailableEmployees({ requestTypeParam }));
  }, [requestTypeParam]);

  const handleChangePagination = ({ limit: newLimit, offset: newOffset }) => {
    if (limit !== newLimit || offset !== newOffset) {
      dispatch(loadRequestsList({
        limit: newLimit, offset: newOffset, filtersState, sortingParams, requestTypeParam,
      }));
    }
  };

  const isStoreDateValid = (date) => Boolean(date) && isValid(new Date(date));

  const isDisabledApply = isEqual(temporaryFiltersState, filtersState);

  const onChangeSorting = ({ sortingField }) => {
    const { sortingField: storedSortingField, sortingOrder: storedSortingOrder } = sortingParams;
    let newSortingOrder = 'desc';
    if (sortingField === storedSortingField && storedSortingOrder === 'desc') {
      newSortingOrder = 'asc';
    }
    const newSortingParams = { sortingField, sortingOrder: newSortingOrder };
    dispatch(changeSorting(newSortingParams));
    dispatch(loadRequestsList({
      limit, offset, filtersState, sortingParams: newSortingParams, requestTypeParam,
    }));
  };

  const isValidFormInputDate = (date) => {
    const [day, month, year] = date.split('/');
    return day.length === 2 && month.length === 2 && year.length === 4 && isValid(new Date(`${month}/${day}/${year}`));
  };

  const isEndDateBeforeStartDate = (startDate, endDate) => !isBefore(new Date(startDate).setHours(0, 0, 0), new Date(endDate).setHours(23, 59, 59));

  const validateFiltersForm = () => {
    let isValidForm = true;
    const requestDateFromValue = get(requestDateFromInputRef, 'current.value', '');
    const requestDateToValue = get(requestDateToInputRef, 'current.value', '');

    if (requestDateFromValue) {
      const isValidInputRequestDateFrom = isValidFormInputDate(requestDateFromValue);
      if (!isValidInputRequestDateFrom || !isStoreDateValid(requestDateFrom)) {
        isValidForm = false;
        setDatesValidationState({ ...datesValidationState, requestDateFrom: { ...datesValidationState.requestDateFrom, error: t('NOT_VALID_DATE') } });
      } else if (isStoreDateValid(requestDateTo) && isEndDateBeforeStartDate(requestDateFrom, requestDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          requestDateFrom: { ...datesValidationState.requestDateFrom, error: t('NOT_VALID_DATE') },
          requestDateTo: { ...datesValidationState.requestDateTo, error: t('NOT_VALID_DATE') },
        });
      }
    }

    if (requestDateToValue) {
      const isValidInputRequestDateTo = isValidFormInputDate(requestDateToValue);
      if (!isValidInputRequestDateTo || !isStoreDateValid(requestDateTo)) {
        isValidForm = false;
        setDatesValidationState({ ...datesValidationState, requestDateTo: { ...datesValidationState.requestDateTo, error: t('NOT_VALID_DATE') } });
      } else if (isStoreDateValid(requestDateFrom) && isEndDateBeforeStartDate(requestDateFrom, requestDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          requestDateFrom: { ...datesValidationState.requestDateFrom, error: t('NOT_VALID_DATE') },
          requestDateTo: { ...datesValidationState.requestDateTo, error: t('NOT_VALID_DATE') },
        });
      }
    }
    return isValidForm;
  };

  const handleApplyFilters = () => {
    const isFilterFormValid = validateFiltersForm();
    if (isFilterFormValid) {
      setFilterOpened(false);
      dispatch(applyFilters());
      dispatch(loadRequestsList({
        limit, offset: 0, filtersState: temporaryFiltersState, sortingParams, requestTypeParam,
      }));
    }
  };

  const isExtenderFilters = requestTypeParam === 'PendingMyApproval';

  const onRowClick = (id) => {
    history.push(`${routeBits.request}/${id}`);
  };

  const getPageHeaderLabel = () => {
    if (isPendingMyApproveTabVisible && !isMyRequestsTabVisible) {
      return `${t('APPROVAL_REQUESTS')} (${pendingCount})`;
    }
    if (!isPendingMyApproveTabVisible && isMyRequestsTabVisible) {
      return t('MY_REQUESTS');
    }
    return t('REQUESTS_LIST');
  };

  return (
    <div>
      {tabsAreVisible && (
        <Tabs
          value={requestTypeParam || initialTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          variant="fullWidth"
          classes={{ root: appClasses.tabs, indicator: appClasses.indicator }}
        >
          <Tab
            label={t('MY_REQUESTS')}
            value="MyRequests"
            data-test="my-requests-tab"
          />
          <Tab
            label={`${t('APPROVAL_REQUESTS')} (${pendingCount})`}
            value="PendingMyApproval"
            data-test="pending-my-approval-tab"
          />
        </Tabs>
      )}
      <PageHeader
        title={t('REQUESTS_LIST')}
      >
        <Box className={appClasses.pageHeader}>
          <Typography variant="h1">{getPageHeaderLabel()}</Typography>
          <Box>
            <Can
              perform={CREATE_REQUEST_FOR_SOMEONE}
              yes={() => (
                <Button
                  className={classes.createButton}
                  size="large"
                  startIcon={<Add />}
                  component={Link}
                  to={routes.createTripForSomeone}
                  type="cart-secondary"
                  data-test="assistant-create-button"
                >
                  {t('CREATE_REQUEST_FOR_AN_EMPLOYEE')}
                </Button>
              )}
            />
            <Can
              perform={CREATE_REQUEST}
              yes={() => (
                <Button
                  className={classes.createButton}
                  size="large"
                  startIcon={<Add />}
                  component={Link}
                  to={routes.createTrip}
                  type="cart-secondary"
                  data-test="create-button"
                >
                  {t('CREATE_REQUEST')}
                </Button>
              )}
            />
            <FilterButton
              onClickFilterButton={() => setFilterOpened(!filterOpened)}
              hasActiveFilters={!isEmpty(filtersState)}
            />
          </Box>
        </Box>
      </PageHeader>
      <div style={{ position: 'relative' }}>
        <ContentSpinner isLoading={loading !== SUCCEEDED}>
          <RequestsTable
            tabsAreVisible={tabsAreVisible}
            requestTypeParam={requestTypeParam}
            data={requestsList}
            onRowClick={onRowClick}
            loading={loading !== SUCCEEDED}
            onChangeSorting={onChangeSorting}
            sortingParams={sortingParams}
          />
        </ContentSpinner>
        <FilterPanel
          filterOpened={filterOpened}
          filterApplyBtnLabel={t('APPLY_FILTERS')}
          headerLabel={t('FILTERS')}
          headerClearBtnLabel={t('CLEAR')}
          hasDiff={!isDisabledApply}
          hasActiveFilters={!isEmpty(filtersState)}
          handleClearFilters={() => {
            if (!isEmpty(filtersState)) {
              dispatch(loadRequestsList({
                limit,
                offset: 0,
                filtersState: {},
                sortingParams,
                requestTypeParam,
              }));
            }
            dispatch(resetFiltersState());
          }}
          setFilters={() => {
            handleApplyFilters();
          }}
        >
          <Box className={appClasses.filterElement}>
            <DatePicker
              invalidDateMessage=""
              withCalendarIcon={false}
              className={classes.amountInput}
              selectedDate={requestDateFrom}
              label={t('START_DATE')}
              {...requestDateTo && isValid(new Date(requestDateTo)) && { maxDate: new Date(requestDateTo) }}
              handleDateChange={(value) => {
                dispatch(onFilterUpdate({
                  filterName: 'requestDateFrom',
                  value: isValid(value) ? new Date(value).toISOString() : value,
                }));
                setDatesValidationState({
                  ...datesValidationState,
                  requestDateFrom: { value: get(datesValidationState, 'requestDateFrom.value', null) },
                });
                const isrequestDateToValid = isStoreDateValid(requestDateTo);
                setDatesValidationState({
                  ...datesValidationState,
                  requestDateFrom: { value: get(datesValidationState, 'requestDateFrom.value', null) },
                  ...isrequestDateToValid && { requestDateTo: { value: get(datesValidationState, 'requestDateTo.value', null) } },
                });
              }}
              helperText={datesValidationState?.requestDateFrom?.error}
              error={Boolean(datesValidationState?.requestDateFrom?.error)}
              inputProps={{
                ref: requestDateFromInputRef,
                'data-test': 'filter-date-from',
                onChange: (event) => setDatesValidationState({
                  ...datesValidationState,
                  requestDateFrom: { value: event.target.value },
                }),
              }}
            />
          </Box>
          <Box className={appClasses.filterElement}>
            <DatePicker
              invalidDateMessage=""
              withCalendarIcon={false}
              className={classes.amountInput}
              selectedDate={requestDateTo}
              label={t('COMPLETION_DATE')}
              {...requestDateFrom && isValid(new Date(requestDateFrom)) && { minDate: new Date(requestDateFrom) }}
              handleDateChange={(value) => {
                dispatch(onFilterUpdate({
                  filterName: 'requestDateTo',
                  value: isValid(value) ? new Date(value.setHours(0, 0)).toISOString() : value,
                }));
                const isRequestDateFromValid = isStoreDateValid(requestDateFrom);
                setDatesValidationState({
                  ...datesValidationState,
                  requestDateTo: { value: get(datesValidationState, 'requestDateTo.value', null) },
                  ...isRequestDateFromValid && { requestDateFrom: { value: get(datesValidationState, 'requestDateFrom.value', null) } },
                });
              }}
              helperText={datesValidationState?.requestDateTo?.error}
              error={Boolean(datesValidationState?.requestDateTo?.error)}
              inputProps={{
                ref: requestDateToInputRef,
                'data-test': 'filter-date-to',
                onChange: (event) => setDatesValidationState({
                  ...datesValidationState,
                  requestDateTo: { value: event.target.value },
                }),
              }}
            />
          </Box>
          <Box className={appClasses.filterElement}>
            <DottedLoader isLoading={availableEmployeesLoadingStatus !== SUCCEEDED}>
              <CustomTags
                multiple
                filterTitle={t('EMPLOYEE')}
                valueKey="id"
                titleKey="value"
                label={t('EMPLOYEE')}
                value={employeeIds}
                onChange={(enumValue) => {
                  dispatch(onFilterUpdate({
                    filterName: 'employeeIds',
                    value: employeeIds.includes(enumValue) ? employeeIds.filter((it) => it !== enumValue) : [...employeeIds, enumValue],
                  }));
                }}
                options={availableEmployees}
                data-test="filter-employee-ids"
              />
            </DottedLoader>
          </Box>
          {isExtenderFilters && (
            <>
              <Box className={appClasses.filterElement}>
                <DottedLoader isLoading={availableApproversLoadingStatus !== SUCCEEDED}>
                  <CustomTags
                    multiple
                    filterTitle={t('APPROUVER')}
                    valueKey="id"
                    titleKey="value"
                    label={t('APPROUVER')}
                    value={approverIds}
                    onChange={(enumValue) => {
                      dispatch(onFilterUpdate({
                        filterName: 'approverIds',
                        value: approverIds.includes(enumValue) ? approverIds.filter((it) => it !== enumValue) : [...approverIds, enumValue],
                      }));
                    }}
                    options={availableApprovers}
                    data-test="filter-approver-ids"
                  />
                </DottedLoader>
              </Box>
              <Box className={appClasses.filterElement}>
                <DottedLoader isLoading={availableCompaniesLoadingStatus !== SUCCEEDED}>
                  <CustomTags
                    multiple
                    filterTitle={t('COMPANY')}
                    valueKey="id"
                    titleKey="value"
                    label={t('COMPANY')}
                    value={companiesIds}
                    onChange={(enumValue) => {
                      dispatch(onFilterUpdate({
                        filterName: 'companiesIds',
                        value: companiesIds.includes(enumValue) ? companiesIds.filter((it) => it !== enumValue) : [...companiesIds, enumValue],
                      }));
                    }}
                    options={availableCompanies}
                    data-test="filter-companies-ids"
                  />
                </DottedLoader>
              </Box>
            </>
          )}
          <Box className={appClasses.filterElement}>
            <DottedLoader isLoading={availableStatusesLoadingStatus !== SUCCEEDED}>
              <CustomTags
                multiple
                filterTitle={t('STATUS')}
                valueKey="id"
                titleKey="value"
                label={t('STATUS')}
                value={statuses}
                onChange={(enumValue) => {
                  dispatch(onFilterUpdate({
                    filterName: 'statuses',
                    value: statuses.includes(enumValue) ? statuses.filter((it) => it !== enumValue) : [...statuses, enumValue],
                  }));
                }}
                options={availableStatuses}
                data-test="filter-statuses"
              />
            </DottedLoader>
          </Box>
        </FilterPanel>
      </div>
      <Pagination
        limit={limit}
        offset={offset}
        itemsCount={totalCount}
        limitsValues={perPageParams}
        handleChangePagination={handleChangePagination}
      />
    </div>
  );
};

export default Main;
