import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../Button';
import Typography from '../Typography';

const useStyles = makeStyles(() => ({
  paper: {
    width: 576,
  },
}
));

const DialogDefault = ({
  title,
  open,
  cancelActionText,
  activeActionText,
  handleActiveAction,
  handleClose,
  customActions,
  activeActionBtnType,
  dialogPaperDataTest,
  children,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: styles.wrapper }}
      PaperProps={{ 'data-test': dialogPaperDataTest }}
      {...rest}
    >
      <Box mx={3} mt={2} mb={1}>
        <Typography
          variant="h1"
          data-test="dialog-title"
        >
          {title}
        </Typography>
      </Box>
      <Box mx={3} mb={2}>
        {children}
      </Box>
      <Divider />
      <DialogActions disableSpacing>
        <Box mx={3} my={2}>
          {!customActions && (
            <>
              <Box mr={3} component="span">
                <Button
                  type="default-secondary"
                  data-test="dialog-cancel-action-btn"
                  onClick={handleClose}
                >
                  {cancelActionText}
                </Button>
              </Box>
              <Button
                type={activeActionBtnType}
                data-test="dialog-active-action-btn"
                onClick={handleActiveAction}
              >
                {activeActionText}
              </Button>
            </>
          )}

        </Box>
      </DialogActions>
    </Dialog>
  );
};

DialogDefault.defaultProps = {
  activeActionBtnType: 'default-primary',
  cancelActionText: 'Cancel',
  activeActionText: 'Оk',
  customActions: null,
  dialogPaperDataTest: 'dialog-paper',
};

DialogDefault.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  cancelActionText: PropTypes.string,
  activeActionText: PropTypes.string,
  activeActionBtnType: PropTypes.string,
  handleActiveAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  dialogPaperDataTest: PropTypes.string,
  customActions: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
};

export default DialogDefault;
