import React, { useState, useEffect } from 'react';
import {
  Box, Divider, Tabs, Tab,
} from '@material-ui/core';
import { ExpandMore, ExpandLess, DeleteOutline } from '@material-ui/icons';
import { get } from 'lodash';
import { isFuture } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  loadTrip,
  deleteTrip,
  loadAssistedUsers,
  resetStore,
} from 'store/trip';
import { showNotification } from 'store/notifications';
import routes, { routeBits } from 'routes';
import { formatDateStr, DISPLAY_DATE_FORMAT } from 'utils/dateTime';
import PageHeader from 'components/PageHeader';
import { Button, Typography, DialogDefault } from 'components/ui-lib';
import ContentSpinner from 'components/ContentSpinner';
import TextBlock from 'components/TextBlock';
import Status from 'components/StatusText';
import { LOADING, SUCCEEDED } from 'constants/main';
import useAppStyles from 'containers/App/AppStyles';
import { ReactComponent as IconIsClock } from 'assets/icons-clock.svg';
import EditCaseButton from './compoments/EditCaseButton';
import ToggleAllBtn from './compoments/ToggleAllBtn';

import LimitsTab from './compoments/LimitsTab';
import AgreementTab from './compoments/AgreementTab';
import ActionTripBtns from './compoments/ActionTripBtns';
import useStyles from './RequestStyles';

const EMPTY_VALUE = '–';

const Request = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [additionalServicesExpanded, setAdditionalServicesExpanded] = useState([]);
  const [hotelsExpanded, setHotelsExpanded] = useState([]);
  const [ticketsExpanded, setTicketsExpanded] = useState([]);
  const appClasses = useAppStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const {
    data = {},
    status,
    error,
  } = useSelector((state) => state.trip?.trip);
  const { roles = [] } = useSelector((state) => state.account?.user);
  const {
    businessTripLimitsDictionary,
  } = useSelector((state) => state.dictionaries?.dictionaries);
  const {
    assistedUsers = [],
  } = useSelector((state) => state.trip);

  const { id: userId } = useSelector(({
    account: {
      user: { name, id },
    },
  }) => ({ name, id }));

  const {
    status: requestStatus, initiatorId, approvalRoute = {}, businessTripStartDate, approver1Approved, approver2Approved, employeeId,
  } = data;

  const approver1Id = get(approvalRoute, 'approver1.id');
  const approver2Id = get(approvalRoute, 'approver2.id');
  const isBusinessTripStartDateInFuture = isFuture(new Date(businessTripStartDate).setHours(23, 59, 0));
  const isEmployeeAssistant = assistedUsers.some(({ id }) => id === employeeId);
  const canRevokeRequest = (requestStatus === 'OnApproval' || requestStatus === 'Approved') && (initiatorId === userId || employeeId === userId || isEmployeeAssistant) && isBusinessTripStartDateInFuture;
  const canSubmitRequest = requestStatus === 'Draft' && (initiatorId === userId || employeeId === userId || isEmployeeAssistant) && isBusinessTripStartDateInFuture;
  const canApproveRequest = requestStatus === 'OnApproval' && ((approver1Id === userId && !approver1Approved)
    || (approver2Id === userId && approver1Approved && !approver2Approved));
  const canRejectRequest = requestStatus === 'OnApproval' && ((approver1Id === userId && !approver1Approved) || (approver2Id === userId && approver1Approved && !approver2Approved));
  const showActionTripBtns = canRevokeRequest || canSubmitRequest || canApproveRequest || canRejectRequest;
  const canEditTrip = requestStatus === 'Draft' && (initiatorId === userId || employeeId === userId || isEmployeeAssistant);
  const canDeleteTrip = canEditTrip || (requestStatus === 'Draft' && employeeId === userId);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const deleteCurrentEvent = () => {
    setIsOpenDeleteConfirmation(false);
    dispatch(deleteTrip(params)).then((respData) => {
      setIsDeleting(false);
      if (respData.error) {
        dispatch(showNotification({
          type: 'error',
          message: {
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: t('SOMETHING_WRONG')
                }]
              }],
            }
          }
        }));
      } else {
        history.push(routes.main);
        dispatch(showNotification({
          type: 'success',
          message: {
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: t('SUCCESSFULLY_DELETED')
                }]
              }]
            }
          }
        }));
      }
    });
  };

  const handleEditEvent = () => history.push(`${routeBits.request}/${data.id}/edit`);

  const handleChangeItemExpand = (id, entityIds, setEntityIds) => {
    const isExpanded = entityIds.includes(id);

    if (isExpanded) {
      setEntityIds(entityIds.filter((it) => it !== id));
    } else {
      setEntityIds([...entityIds, id]);
    }
  };

  useEffect(() => {
    dispatch(loadTrip(params));
    if (roles.includes('Assistant')) {
      dispatch(loadAssistedUsers());
    }
    return () => dispatch(resetStore());
  }, []);

  useEffect(() => {
    if (!error) return;
    const errorStatus = get(error, 'status', '');
    if (errorStatus === 404) {
      history.replace(routes.notFound);
    }
  }, [error]);

  return (
    <>
      <ContentSpinner isLoading={status === LOADING || isDeleting}>
        {status === SUCCEEDED && (
          <>
            <Box className={appClasses.root}>
              <PageHeader
                hasBackArrow
                title={t('REQUEST_VIEW')}
              >
                <Box className={appClasses.pageHeader}>
                  <Typography variant="h1">{t('REQUEST_VIEW')}</Typography>
                  <Box>
                    {canDeleteTrip && (
                      <Button
                        startIcon={<DeleteOutline />}
                        type="cart-error"
                        onClick={() => setIsOpenDeleteConfirmation(true)}
                        size="large"
                        data-test="delete-button"
                      >
                        {t('DELETE')}
                      </Button>
                    )}
                    {canEditTrip && (
                      <EditCaseButton onClick={handleEditEvent} />
                    )}
                  </Box>
                </Box>
              </PageHeader>
              <Tabs
                value={tabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                variant="fullWidth"
                classes={{ root: appClasses.tabs, indicator: appClasses.indicator }}
              >
                <Tab label={t('REQUEST')} data-test="request-tab" />
                <Tab label={t('LIMITS')} data-test="limits-tab" />
                <Tab label={t('APPROVAL')} data-test="approval-tab" />
              </Tabs>
              {tabIndex === 0 && (
                <>
                  <Box px={3} pt={1}>
                    <Typography variant="h2" data-test="event-attributes-title">
                      {t('GENERAL_INFO')}
                    </Typography>
                    <Box style={{ padding: '0 80px' }}>
                      <Box className={appClasses.flexWraIndented}>
                        <Box className={classes.eventsAttrsLeft}>
                          <TextBlock title="ID" data-test="id">
                            {data.id}
                          </TextBlock>
                          <TextBlock title={t('INITIATOR')} data-test="initiator">
                            {data.initiatorName}
                          </TextBlock>
                          <TextBlock title={t('CREATION_DATE')} data-test="creation-date">
                            {data.creationDate ? formatDateStr(new Date(data.creationDate), DISPLAY_DATE_FORMAT) : '—'}
                          </TextBlock>
                          <TextBlock title={t('COMPANY')} data-test="company">
                            {data.company || EMPTY_VALUE}
                          </TextBlock>
                          <TextBlock title={t('TYPE_OF_BUSINESS_TRIP')} data-test="type">
                            {data.businessTripTypeName}
                          </TextBlock>
                        </Box>
                        <Box className={classes.eventsAttrsRight}>
                          <TextBlock title={t('STATUS')} data-test="status">
                            <Status status={data.status} noColor />
                          </TextBlock>
                          <TextBlock title={t('EMPLOYEE')} data-test="employee">
                            {data.employeeName}
                          </TextBlock>
                          <TextBlock title={t('START_DATE')} data-test="business-trip-start-date">
                            {formatDateStr(new Date(data.businessTripStartDate), DISPLAY_DATE_FORMAT)}
                          </TextBlock>
                          <TextBlock title={t('COMPLETION_DATE')} data-test="business-trip-finish-date">
                            {formatDateStr(new Date(data.businessTripFinishDate), DISPLAY_DATE_FORMAT)}
                          </TextBlock>
                        </Box>
                      </Box>
                      <Box className={appClasses.flexWraIndented}>
                        <TextBlock title={t('PURPOSE')} data-test="business-trip-goal">
                          {data.businessTripGoal || EMPTY_VALUE}
                        </TextBlock>
                        <div />
                      </Box>
                    </Box>
                  </Box>

                  <Divider className={classes.blockDivider} />

                  <Box px={3} pt={1}>
                    <Typography variant="h2" data-test="destinations-block">{t('DESTINATIONS')}</Typography>
                    {data.destinations?.map((it, index) => (
                      <Box
                        data-test={`destinations-${index}`}
                        key={it.id}
                        className={classes.shadowBlock}
                      >
                        <Box className={appClasses.flexWraIndented}>
                          <Box className={classes.eventsAttrsLeft}>
                            <TextBlock title={t('COUNTRY')} data-test={`destinations-${index}-country`}>
                              {it.countryName}
                            </TextBlock>
                            <TextBlock title={t('DESTINATION')} data-test={`destinations-${index}-destination`}>
                              {it.destination}
                            </TextBlock>
                            <TextBlock title={t('COMMENTS')} data-test={`destinations-${index}-comment`}>
                              {it.comment || EMPTY_VALUE}
                            </TextBlock>
                          </Box>
                          <Box className={classes.eventsAttrsRight}>
                            <TextBlock title={t('START_DATE')} data-test={`destinations-${index}-start-date`}>
                              {formatDateStr(new Date(it.startDate), DISPLAY_DATE_FORMAT)}
                            </TextBlock>
                            <TextBlock title={t('COMPLETION_DATE')} data-test={`destinations-${index}-finish-date`}>
                              {formatDateStr(new Date(it.finishDate), DISPLAY_DATE_FORMAT)}
                            </TextBlock>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>

                  <Divider className={classes.blockDivider} />

                  <Box px={3} pt={1}>
                    <Box
                      pb={0.5}
                      data-test="expand-tickets-block"
                      className={clsx(appClasses.flexWrapBetween, classes.toggleAllWrap)}
                      onClick={() => {
                        if (ticketsExpanded.length < 1) {
                          setTicketsExpanded(data.tickets.map(({ id }) => id));
                        } else {
                          setTicketsExpanded([]);
                        }
                      }}
                    >
                      <Typography variant="h2" data-test="tickets-block">
                        {t('TICKETS')}
                      </Typography>
                      <ToggleAllBtn unit={ticketsExpanded} />
                    </Box>
                    {data.tickets?.map((it, index) => {
                      const isExpand = ticketsExpanded.includes(it.id);
                      const expandStyle = isExpand ? classes.expandedBlockBtn : {};

                      return (
                        <Box
                          data-test={`tickets-${index}`}
                          key={it.id}
                          className={classes.shadowBlock}
                        >
                          <Box
                            className={clsx(classes.expandBlockBtn, expandStyle)}
                            data-test="expand-tickets-block"
                            onClick={() => handleChangeItemExpand(it.id, ticketsExpanded, setTicketsExpanded)}
                          >
                            {isExpand ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
                          </Box>
                          {isExpand ? (
                            <>
                              <Box className={appClasses.flexWraIndented}>
                                <Box className={classes.eventsAttrsLeft}>
                                  <TextBlock title={t('TYPE_OF_TICKET')} data-test={`tickets-${index}-type`}>
                                    {it.ticketTypeName}
                                  </TextBlock>
                                  <TextBlock title={t('CLASS_OF_TICKET')} data-test={`tickets-${index}-class`}>
                                    {it.ticketClassName}
                                  </TextBlock>
                                  <TextBlock title={t('FROM')} data-test={`tickets-${index}-from`}>
                                    {it.from}
                                  </TextBlock>
                                  <TextBlock title={t('TO')} data-test={`tickets-${index}-to`}>
                                    {it.to}
                                  </TextBlock>
                                </Box>
                                <Box className={classes.eventsAttrsRight}>
                                  <TextBlock title={t('DEPARTURE_DATE')} data-test={`tickets-${index}-departure-date`}>
                                    {formatDateStr(new Date(it.departureDate), DISPLAY_DATE_FORMAT)}
                                  </TextBlock>
                                  <TextBlock title={t('DEPARTURE_TIME')} data-test={`tickets-${index}-departure-time`}>
                                    {it.departureTime}
                                  </TextBlock>
                                </Box>
                              </Box>
                              <Box className={appClasses.flexWraIndented}>
                                <TextBlock title={t('COMMENTS')} data-test={`tickets-${index}-comment`}>
                                  {it.comment || EMPTY_VALUE}
                                </TextBlock>
                                <div />
                              </Box>
                            </>
                          ) : (
                            <Box className={appClasses.flexWraIndented}>
                              <Box className={classes.eventsAttrsLeft}>
                                <TextBlock title={t('TYPE_OF_TICKET')} mb={0} data-test={`tickets-${index}-type`}>
                                  {it.ticketTypeName}
                                </TextBlock>
                              </Box>
                              <Box className={classes.eventsAttrsRight}>
                                <TextBlock title={t('CLASS_OF_TICKET')} mb={0} data-test={`tickets-${index}-class`}>
                                  {it.ticketClassName}
                                </TextBlock>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>

                  <Divider className={classes.blockDivider} />

                  <Box px={3} pt={1}>
                    <Box
                      pb={0.5}
                      data-test="expand-hotels-block"
                      className={clsx(appClasses.flexWrapBetween, classes.toggleAllWrap)}
                      onClick={() => {
                        if (hotelsExpanded.length < 1) {
                          setHotelsExpanded(data.hotels.map(({ id }) => id));
                        } else {
                          setHotelsExpanded([]);
                        }
                      }}
                    >
                      <Typography variant="h2" data-test="hotels-block">
                        {t('HOTELS')}
                      </Typography>
                      <ToggleAllBtn unit={hotelsExpanded} />
                    </Box>
                    {data.hotels?.map((it, index) => {
                      const isExpand = hotelsExpanded.includes(it.id);
                      const expandStyle = isExpand ? classes.expandedBlockBtn : {};

                      return (
                        <Box
                          data-test={`hotels-${index}`}
                          key={it.id}
                          className={classes.shadowBlock}
                        >
                          <Box
                            className={clsx(classes.expandBlockBtn, expandStyle)}
                            data-test="expand-accompanying-services-block"
                            onClick={() => handleChangeItemExpand(it.id, hotelsExpanded, setHotelsExpanded)}
                          >
                            {isExpand ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
                          </Box>
                          {isExpand ? (
                            <>
                              <Box className={appClasses.flexWraIndented}>
                                <Box className={classes.eventsAttrsLeft}>
                                  <TextBlock title={t('LOCALITY')} data-test={`hotels-${index}-location`}>
                                    {it.location}
                                  </TextBlock>
                                  <TextBlock title="&nbsp;">
                                    <Box className={classes.timeFrame}>
                                      {it.earlyCheckIn && (
                                        <Box className={classes.timeFrameItem} data-test={`hotels-${index}-early-check-in`}>
                                          <IconIsClock />
                                          <span className={classes.timeFrameText}>{t('EARLY_CHECKIN')}</span>
                                        </Box>
                                      )}
                                      {it.lateCheckOut && (
                                        <Box className={classes.timeFrameItem} data-test={`hotels-${index}-late-check-out`}>
                                          <IconIsClock />
                                          <span className={classes.timeFrameText}>{t('LATE_CHECKOUT')}</span>
                                        </Box>
                                      )}
                                    </Box>
                                  </TextBlock>
                                </Box>
                                <Box className={classes.eventsAttrsRight}>
                                  <TextBlock title={t('CHECKIN_DATE')} data-test={`hotels-${index}-arrival-date`}>
                                    {formatDateStr(new Date(it.arrivalDate), DISPLAY_DATE_FORMAT)}
                                  </TextBlock>
                                  <TextBlock title={t('CHECKOUT_DATE')} data-test={`hotels-${index}-departure-date`}>
                                    {formatDateStr(new Date(it.departureDate), DISPLAY_DATE_FORMAT)}
                                  </TextBlock>
                                </Box>
                              </Box>
                              <Box className={appClasses.flexWraIndented}>
                                <TextBlock title={t('COMMENTS')} data-test={`hotels-${index}-comment`}>
                                  {it.comment || EMPTY_VALUE}
                                </TextBlock>
                                <div />
                              </Box>
                            </>
                          ) : (
                            <Box className={appClasses.flexWraIndented}>
                              <Box className={classes.eventsAttrsLeft}>
                                <TextBlock title={t('LOCALITY')} mb={0} data-test={`hotels-${index}-location`}>
                                  {it.location}
                                </TextBlock>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>

                  <Divider className={classes.blockDivider} />

                  <Box px={3} pt={1} className={classes.accompanyingServicesBlock}>
                    <Box
                      pb={0.5}
                      data-test="expand-performers-block"
                      className={clsx(appClasses.flexWrapBetween, classes.toggleAllWrap)}
                      onClick={() => {
                        if (additionalServicesExpanded.length < 1) {
                          setAdditionalServicesExpanded(data.additionalServices.map(({ id }) => id));
                        } else {
                          setAdditionalServicesExpanded([]);
                        }
                      }}
                    >
                      <Typography variant="h2" data-test="accompanying-services-block">
                        {t('ACCOMPANYING_SERVICES')}
                      </Typography>
                      <ToggleAllBtn unit={additionalServicesExpanded} />
                    </Box>

                    {data.additionalServices?.map((it, index) => {
                      const isExpand = additionalServicesExpanded.includes(it.id);
                      const expandStyle = isExpand ? classes.expandedBlockBtn : {};

                      return (
                        <Box
                          data-test={`accompanying-services-block-${index}`}
                          key={it.id}
                          className={classes.shadowBlock}
                        >
                          <Box
                            className={clsx(classes.expandBlockBtn, expandStyle)}
                            data-test="expand-accompanying-services-block"
                            onClick={() => handleChangeItemExpand(it.id, additionalServicesExpanded, setAdditionalServicesExpanded)}
                          >
                            {isExpand ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
                          </Box>
                          {isExpand ? (
                            <Box className={appClasses.flexWraIndented}>
                              <Box className={classes.eventsAttrsLeft}>
                                <TextBlock title={t('SERVICE')} data-test={`accompanying-services-block-${index}-service`}>
                                  {it.serviceName}
                                </TextBlock>
                                <TextBlock title={t('COMMENTS')} data-test={`accompanying-services-block-${index}-comment`}>
                                  {it.comment || EMPTY_VALUE}
                                </TextBlock>
                              </Box>
                              <Box />
                            </Box>
                          ) : (
                            <Box className={appClasses.flexWraIndented}>
                              <Box className={classes.eventsAttrsLeft}>
                                <TextBlock title={t('SERVICE')} mb={0} data-test={`accompanying-services-block-${index}-service`}>
                                  {it.serviceName}
                                </TextBlock>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                </>
              )}
              {tabIndex === 1 && <LimitsTab limits={businessTripLimitsDictionary} />}
              {tabIndex === 2 && <AgreementTab data={data} />}
            </Box>
            {showActionTripBtns && (
              <ActionTripBtns
                canRevokeRequest={canRevokeRequest}
                canSubmitRequest={canSubmitRequest}
                canApproveRequest={canApproveRequest}
                canRejectRequest={canRejectRequest}
              />
            )}
          </>
        )}
      </ContentSpinner>
      <DialogDefault
        dialogPaperDataTest="draft-deleting-dialog"
        title={t('DRAFT_DELETING')}
        open={isOpenDeleteConfirmation}
        handleClose={() => setIsOpenDeleteConfirmation(false)}
        activeActionText={t('CONFIRM')}
        cancelActionText={t('BACK')}
        activeActionBtnType="default-error"
        handleActiveAction={deleteCurrentEvent}
      >
        <Typography data-test="dialog-sub-title" variant="h2" style={{ lineHeight: '32px', marginBottom: 16 }}>
          {t('GOING_TO_DELETE_TRIP')}
        </Typography>
        <Typography data-test="dialog-sub-title-extra" variant="subtitle2">{t('GOING_TO_DELETE_TRIP_EXTRA')}</Typography>
        <Box style={{ marginTop: 8 }}>
          <Typography data-test="dialog-confirm-your-action" variant="subtitle2">{t('PLEASE_CONFIRM_YOUR_ACTION')}</Typography>
        </Box>
      </DialogDefault>
    </>
  );
};

export default Request;
