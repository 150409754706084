import React from 'react';
import { Typography } from 'components/ui-lib';
import { ReactComponent as LogoSvg } from './assets/logo.svg';
import useStyles from './styles';

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.loader}>
        <div className={classes.pacman} />
        <div className={classes.balls}>
          <div />
          <div />
          <div />
        </div>
        <LogoSvg className={classes.logo} />
        <Typography variant="body2" className={classes.text}>
          Почекайте, йде завантаження
        </Typography>
      </div>
    </div>
  );
};

export default Loader;
