import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  expandAllBtn: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primaryLight,
    '&:hover': {
      color: theme.colors.lightPrimaryHover,
    },
  },
}));

export default useStyles;
