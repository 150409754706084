/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  type: 'info',
  message: JSON.stringify(''),
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification(state, action) {
      state.open = true;
      state.type = action.payload.type;
      state.message = JSON.stringify(action.payload.message);
    },
    closeNotification() {
      return initialState;
    },
  },
});

export const getNotificationData = (state) => state.notifications;

export const { showNotification, closeNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
