/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'API';

export const getUserSelector = (state) => state.account?.user;
export const getUserRoleSelector = (state) => state.account?.user?.access;
export const getCurrentUserRoleSelector = (state) => state.account?.user?.roles;

export const loadUserData = createAsyncThunk(
  'account/getUserData',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.user.getUserData();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

const getMainPageTabsConfig = (currentUserRoles = []) => {
  const getPendingMyApproveTabStatus = (userRoles) => userRoles.includes('Approver') || userRoles.includes('HrAdministrator') || userRoles.includes('AdministrationDepartment');
  const getMyRequestsTabStatus = (userRoles) => !(userRoles.includes('AdministrationDepartment') && userRoles.length === 1);
  const isPendingMyApproveTabVisible = getPendingMyApproveTabStatus(currentUserRoles);
  const isMyRequestsTabVisible = getMyRequestsTabStatus(currentUserRoles);
  const tabsAreVisible = isPendingMyApproveTabVisible && isMyRequestsTabVisible;
  const initialTab = isMyRequestsTabVisible ? 'MyRequests' : 'PendingMyApproval';
  return {
    isPendingMyApproveTabVisible,
    isMyRequestsTabVisible,
    tabsAreVisible,
    initialTab,
  };
};

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    user: {
      name: 'No name',
      isPendingMyApproveTabVisible: false,
      isMyRequestsTabVisible: false,
      tabsAreVisible: false,
    },
  },
  extraReducers: {
    [loadUserData.fulfilled]: (state, action) => {
      state.user = {
        ...action.payload,
        ...getMainPageTabsConfig(action.payload?.roles),
      };
    },
  },
});

export default accountSlice.reducer;
