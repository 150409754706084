import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // eslint-disable-line

import TRANSLATIONS_EN from './en/translations';
import TRANSLATIONS_UA from './ua/translations'; // eslint-disable-line
import TRANSLATIONS_RU from './ru/translations'; // eslint-disable-line

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      // ua: {
      //   translation: TRANSLATIONS_UA
      // },
      // ru: {
      //   translation: TRANSLATIONS_RU
      // },
    },
    fallbackLng: 'en',
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  });

export default i18n;
