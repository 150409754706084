import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  FAILED, INIT_STATUS, LOADING, SUCCEEDED
} from 'constants/main';
import { mapBeDataForClient } from 'utils/mappers';
import API from '../API';

export const createTrip = createAsyncThunk('trip/createTrip', async (arg, { rejectWithValue }) => {
  try {
    const { data } = arg;
    const response = await API.trip.createTrip(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      error: {
        ...err,
        message: 'Something went wrong with trip creation',
      },
    });
  }
});

export const editTrip = createAsyncThunk('trip/editTrip', async (arg, { rejectWithValue }) => {
  try {
    const { data } = arg;
    const response = await API.trip.editTrip(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      error: {
        ...err,
        message: 'Something went wrong with trip edit',
      },
    });
  }
});

export const loadTrip = createAsyncThunk('trip/loadTrip', async (args, { rejectWithValue }) => {
  try {
    const { requestId } = args;
    const response = await API.trip.getTrip(requestId);
    return mapBeDataForClient(response.data);
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const submitTrip = createAsyncThunk('trip/submitTrip', async (args, { rejectWithValue }) => {
  try {
    const { tripId } = args;
    const response = await API.trip.submitTrip(tripId);
    return mapBeDataForClient(response.data);
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      error: err.response,
      status: err.response?.status,
    });
  }
});

export const revokeTrip = createAsyncThunk('trip/revokeTrip', async (args, { rejectWithValue }) => {
  try {
    const response = await API.trip.revokeTrip(args);
    return mapBeDataForClient(response.data);
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      error: err.response,
      status: err.response?.status,
    });
  }
});

export const approveTrip = createAsyncThunk('trip/approveTrip', async (args, { rejectWithValue }) => {
  try {
    const { tripId } = args;
    const response = await API.trip.approveTrip(tripId);
    return mapBeDataForClient(response.data);
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      error: err.response,
      status: err.response?.status,
    });
  }
});

export const rejectTrip = createAsyncThunk('trip/rejectTrip', async (args, { rejectWithValue }) => {
  try {
    const response = await API.trip.rejectTrip(args);
    return mapBeDataForClient(response.data);
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      error: err.response,
      status: err.response?.status,
    });
  }
});

export const deleteTrip = createAsyncThunk('trip/deleteTrip', async (args, { rejectWithValue }) => {
  try {
    const { requestId } = args;
    await API.trip.deleteTrip(requestId);
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

export const loadAssistedUsers = createAsyncThunk('trip/loadAssistedUsers', async (args, { rejectWithValue }) => {
  try {
    const response = await API.user.getAssistedUsers();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      ...err.payload,
      status: err.response?.status,
    });
  }
});

const initialStateMetadata = {
  status: INIT_STATUS,
  error: null,
};

const tripSlice = createSlice({
  name: 'trip',
  initialState: {
    trip: {
      data: undefined,
      error: null,
      ...initialStateMetadata,
    },
    assistedUsers: [],
  },
  reducers: {
    resetStore(state) {
      state.trip.status = INIT_STATUS;
      state.trip.error = null;
    }
  },
  extraReducers: {
    [loadTrip.pending]: (state) => {
      state.trip.status = LOADING;
      state.trip.error = null;
    },
    [loadTrip.fulfilled]: (state, action) => {
      state.trip.data = action.payload;
      state.trip.status = SUCCEEDED;
      state.trip.error = null;
    },
    [loadTrip.rejected]: (state, action) => {
      state.trip.status = FAILED;
      state.trip.error = action.payload;
    },
    [submitTrip.pending]: (state) => {
      state.trip.status = LOADING;
      state.trip.error = null;
    },
    [submitTrip.fulfilled]: (state) => {
      state.trip.status = SUCCEEDED;
    },
    [submitTrip.rejected]: (state, action) => {
      state.trip.status = SUCCEEDED;
      state.trip.error = action.payload;
    },
    [approveTrip.pending]: (state) => {
      state.trip.status = LOADING;
      state.trip.error = null;
    },
    [approveTrip.fulfilled]: (state) => {
      state.trip.status = SUCCEEDED;
    },
    [approveTrip.rejected]: (state, action) => {
      state.trip.status = SUCCEEDED;
      state.trip.error = action.payload;
    },
    [revokeTrip.pending]: (state) => {
      state.trip.status = LOADING;
      state.trip.error = null;
    },
    [revokeTrip.fulfilled]: (state) => {
      state.trip.status = SUCCEEDED;
    },
    [revokeTrip.rejected]: (state, action) => {
      state.trip.status = SUCCEEDED;
      state.trip.error = action.payload;
    },
    [rejectTrip.pending]: (state) => {
      state.trip.status = LOADING;
      state.trip.error = null;
    },
    [rejectTrip.fulfilled]: (state) => {
      state.trip.status = SUCCEEDED;
      state.trip.error = undefined;
    },
    [rejectTrip.rejected]: (state, action) => {
      state.trip.status = SUCCEEDED;
      state.trip.error = action.payload;
    },
    [loadAssistedUsers.pending]: (state) => {
      state.trip.assistedUsersStatus = LOADING;
      state.trip.error = null;
    },
    [loadAssistedUsers.fulfilled]: (state, action) => {
      state.assistedUsers = action.payload;
      state.trip.assistedUsersStatus = SUCCEEDED;
    },
  },
});

export default tripSlice.reducer;

export const {
  resetStore
} = tripSlice.actions;
