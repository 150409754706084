import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  IconButton,
  ButtonBase,
} from '@material-ui/core';
import {
  PowerSettingsNew,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Typography, DialogDefault } from 'components/ui-lib';

import routes from 'routes';
import { msal } from 'containers/MSAuth/MSAuth';

import {
  resetFiltersState,
  resetTemporaryFiltersState,
  setRequestTypeParam,
} from 'store/requestsList';
import { ReactComponent as LogoSvg } from './logo.svg';

import useStyles from './HeaderStyles';
import { getUserSelector } from '../../store/account';

const msalLogout = msal.logout;

const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    initialTab,
  } = useSelector(getUserSelector);

  const [isOpenLogoutModal, setOpenLogoutModal] = useState(false);

  const { name: userName } = useSelector(({
    account: {
      user: { name },
    },
  }) => ({ name }
  ));

  const handleOpenLogoutWindow = () => setOpenLogoutModal(true);

  const handleCloseLogoutWindow = () => setOpenLogoutModal(false);

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        color="inherit"
        classes={{
          root: classes.appbarRoot,
        }}
      >
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <div className={classes.headerLeft}>
            <ButtonBase
              disableRipple
              onClick={() => {
                dispatch(resetTemporaryFiltersState());
                dispatch(resetFiltersState());
                dispatch(setRequestTypeParam({ requestType: initialTab }));
                history.push(routes.main);
              }}
              data-test="logo"
            >
              <LogoSvg className={classes.logo} />
            </ButtonBase>
            <NavLink to={routes.main} className={classes.logoLink} />
            <div className={classes.projectTitle}>Business Trips</div>
          </div>
          <div className={classes.accountWrapper}>
            <div className={classes.userInfo}>
              <Typography
                data-test="auth-user-name"
                className={classes.usersName}
                variant="body2"
                component="span"
              >
                {userName}
              </Typography>
            </div>
            <IconButton
              aria-label="Account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleOpenLogoutWindow}
              color="inherit"
              classes={{ root: classes.defaultBtnStyle }}
              data-test="account-menu-btn"
            >
              <PowerSettingsNew />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <DialogDefault
        dialogPaperDataTest="logout-dialog"
        title={t('WARNING')}
        open={isOpenLogoutModal}
        handleClose={handleCloseLogoutWindow}
        activeActionText={t('LOGOUT')}
        handleActiveAction={msalLogout}
      >
        <Typography data-test="dialog-sub-title" variant="h2">{t('DO_YOU_WANT_TO_LOG_OUT')}</Typography>
        <Typography data-test="dialog-text" variant="subtitle2">{t('YOU_WILL_BE_RETURNED_TO_THE_AUTHORIZATION_SCREEN')}</Typography>
      </DialogDefault>
    </div>
  );
};

export default Header;
