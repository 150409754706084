import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { v4 as aniqueId } from 'uuid';

import {
  createTrip,
} from 'store/trip';
import { showNotification } from 'store/notifications';
import routes, { routeBits } from 'routes';
import { LOADING, blocksName, showNotValidFormNotification } from 'constants/main';
import { mapDataForBe } from 'utils/mappers';
import createTripFormValidation from 'utils/validation';
import { usePrompt } from 'utils/useBeforeUnloadPrompt';
import { Typography, Button } from 'components/ui-lib';
import ContentSpinner from 'components/ContentSpinner';

import PageHeader from 'components/PageHeader';
import RequestForm from 'components/RequestForm';
import useAppStyles from 'containers/App/AppStyles';

const initBlockValues = {
  [blocksName.DESTINATION]: {
    tempId: aniqueId(),
    countryId: null,
    destination: '',
    startDate: null,
    finishDate: null,
    comment: '',
  },
  [blocksName.TICKET]: {
    tempId: aniqueId(),
    ticketTypeId: null,
    ticketClassId: null,
    from: null,
    to: null,
    departureDate: null,
    departureTime: null,
    comment: '',
  },
  [blocksName.HOTEL]: {
    tempId: aniqueId(),
    location: null,
    arrivalDate: null,
    departureDate: null,
    comment: '',
    earlyCheckIn: false,
    lateCheckOut: false,
  },
  [blocksName.ADDITIONAL_SERVICE]: {
    tempId: aniqueId(),
    serviceId: null,
    comment: '',
  },
};

const Create = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successfullySavedId, setSuccessfullySavedId] = useState(undefined);
  const appClasses = useAppStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const isCreateTripForSomeOne = Boolean(useRouteMatch(routes.createTripForSomeone));

  const { status } = useSelector((state) => state.trip?.trip);
  const isLoadingPage = isSubmitting || status === LOADING;

  const formikInitialValues = {
    ...isCreateTripForSomeOne ? { employeeId: null } : {},
    businessTripTypeId: null,
    businessTripStartDate: null,
    businessTripFinishDate: null,
    businessTripGoal: '',
    destinations: [initBlockValues.destination],
    tickets: [],
    hotels: [],
    additionalServices: [],
  };
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: createTripFormValidation,
    onSubmit: (values) => {
      setIsSubmitting(true);
      dispatch(createTrip({
        data: mapDataForBe(values),
      })).then((data) => {
        if (data.error) {
          setIsSubmitting(false);
          const { error } = data.payload;
          showNotValidFormNotification(error, formik, t);
        } else {
          const { id } = data.payload;
          setSuccessfullySavedId(id);
          dispatch(showNotification({
            type: 'success',
            message: {
              messageTemplate: {
                rows: [{
                  rowContent: [{
                    type: 'text',
                    text: t('SUCCESSFULLY_CREATED')
                  }]
                }],
              },
            }
          }));
        }
      });
    },
  });

  useEffect(() => {
    if (!successfullySavedId) return;
    history.push(`${routeBits.request}/${successfullySavedId}`);
  }, [successfullySavedId]);

  const formHasChanges = !isEqual(formik.values, formikInitialValues);

  const Prompt = usePrompt({
    when: formHasChanges && !successfullySavedId,
    message: t('UNSAVED_DATA_MESSAGE'),
  });

  return (
    <Box className={appClasses.root}>
      <Prompt />
      <PageHeader title={t('CREATING_REQUEST')}>
        <div className={appClasses.pageHeader}>
          <Typography variant="h1">{t('CREATING_REQUEST')}</Typography>
          <Box>
            <Button
              startIcon={<CancelOutlined />}
              type="cart-secondary"
              component={Link}
              to={routes.main}
              disabled={isLoadingPage}
              data-test="cancel-create-trip-btn"
            >
              {t('CANCEL')}
            </Button>
            <Button
              startIcon={<CheckCircleOutline />}
              type="cart-secondary"
              className={appClasses.actionControl}
              disabled={isLoadingPage || !formHasChanges}
              onClick={() => {
                formik.validateForm()
                  .then((errors) => {
                    const isValid = isEmpty(errors);
                    if (isValid) {
                      formik.submitForm();
                    } else {
                      showNotValidFormNotification(null, formik, t);
                    }
                  })
                  .catch(() => {
                    showNotValidFormNotification(null, formik, t);
                  });
              }}
              data-test="create-trip-btn"
            >
              {t('SAVE')}
            </Button>
          </Box>
        </div>
      </PageHeader>
      <ContentSpinner isLoading={isLoadingPage}>
        <RequestForm
          isCreateTripForSomeOne={isCreateTripForSomeOne}
          initBlockValues={initBlockValues}
          setFieldValue={(field, value, shouldValidate) => {
            formik.setFieldValue(field, value, shouldValidate);
            const fieldError = get(formik?.errors, field);
            if (fieldError) formik.setFieldError(field);
          }}
          values={formik.values}
          errors={formik.errors}
        />
      </ContentSpinner>
    </Box>
  );
};

export default Create;
