import React, { useState } from 'react';
import PropType from 'prop-types';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars-2';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  ClickAwayListener, Paper, IconButton, Typography, Checkbox, Grid, Button, Box, SvgIcon
} from '@material-ui/core';
import {
  ArrowDropUp, Add,
} from '@material-ui/icons';
import { ReactComponent as CloseIcon } from './assets/error.svg';

import useStyles from './styles';

const FilterElement = (props) => {
  const classes = useStyles();

  const visibleOptionsCount = 3;
  const optionHeight = 48;
  const maxOptionWrapperHeight = 250;

  const {
    value = [],
    multiple,
    label,
    options = [],
    onChange,
    valueKey,
    titleKey,
    'data-test': dataTest,
  } = props;

  const [isVisibleSearch, handleChangeSearchState] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleDisplaySearch = () => {
    if (!isVisibleSearch) {
      setFocused(true);
    }
    handleChangeSearchState(!isVisibleSearch);
  };

  const clickAway = () => {
    if (focused) setFocused(false);
    if (!isVisibleSearch) return;
    handleChangeSearchState(!isVisibleSearch);
  };

  const onFilterChanged = (option) => {
    const key = valueKey || 'id';
    const optionValue = get(option, key);
    if (onChange) {
      onChange(optionValue);
    }
    if (!multiple) handleDisplaySearch();
  };

  const renderSearchForm = () => (
    <form noValidate spellCheck="false">
      <div
        data-test={`${dataTest}-wrapper`}
        className={classes.filterElementWrapper}
      >
        {isEmpty(value) && !isEmpty(options) && (
          <button
            aria-label="buttonOverlay"
            className={classes.buttonOverlay}
            onClick={handleDisplaySearch}
            type="button"
            data-test="button-overlay"
          />
        )}
        <IconButton
          disabled={isEmpty(options)}
          data-test="toggle-filter-options-button"
          onClick={handleDisplaySearch}
          className={classes.filterOptionsCollapseBtn}
        >
          <SvgIcon
            component={!isVisibleSearch ? Add : ArrowDropUp}
          />
        </IconButton>
        <div
          className={clsx(
            classes.filterLabel,
            (!isEmpty(value)
            ) ? classes.filterLabelToTop : classes.filterLabelToCenter,
            focused ? classes.focusedLabel : classes.unfocusedLabel,
          )}
        >
          {label}
        </div>
        {isEmpty(value) && <div className={classes.emptyLabel} />}
        {!isEmpty(value) && (
        <div className={classes.activeOptionWrapper}>
          {options.map((option) => {
            const { id } = option;
            const optionTitle = get(option, titleKey);
            const val = get(option, valueKey);
            const isSelected = value.includes(val);
            if (isSelected) {
              return (
                <Button
                  data-test={`clear-option-${id}`}
                  key={id}
                  onClick={() => onFilterChanged(option)}
                >
                  <SvgIcon
                    component={CloseIcon}
                    className={classes.closeIcon}
                  />
                  <span
                    className={classes.activeOptionLabel}
                    data-test="active-option-title"
                  >
                    {optionTitle}
                  </span>
                </Button>
              );
            }
            return null;
          })}
        </div>
        )}
      </div>
    </form>
  );

  const renderSearchSelect = () => (
    <div>
      <Paper classes={{ root: classes.selectWrapper121 }}>
        <Scrollbars
          style={{
            height: options.length > visibleOptionsCount
              ? maxOptionWrapperHeight
              : optionHeight * (options.length
              ),
          }}
          renderThumbVertical={({ style, ...restProps }) => (
            <div
              {...restProps}
              style={{
                ...style, backgroundColor: 'rgb(225, 225, 225)', borderRadius: 6, width: 4,
              }}
            />
          )}
          renderTrackVertical={({ style, ...restProps }) => {
            if (options.length > visibleOptionsCount) {
              return (
                <div
                  {...restProps}
                  style={{
                    ...style,
                    position: 'absolute',
                    backgroundColor: 'rgb(243, 243, 243)',
                    width: 4,
                    right: 8,
                    bottom: 2,
                    top: 2,
                    borderRadius: 3,
                  }}
                />
              );
            }
            return (<div />
            );
          }}
        >
          <Box classes={{ root: classes.searchMenuWrapper }}>
            {options.map((option) => {
              const { id } = option;
              const optionTitle = get(option, titleKey);
              const isSelected = () => {
                let isOptionSelected = false;
                const val = get(option, valueKey);
                if (!isEmpty(value)
                  && value.includes(val)) {
                  isOptionSelected = true;
                }
                return isOptionSelected;
              };
              return (
                <Button
                  key={id}
                  onClick={() => onFilterChanged(option)}
                  className={classes.menuItem}
                >
                  <Grid
                    container
                    directon="row"
                    alignItems="center"
                  >
                    <Checkbox
                      data-test="filterOptionCheckbox"
                      checked={isSelected()}
                      value="checkedB"
                      color="primary"
                    />
                    <Typography
                      variant="caption"
                      className={classes.optionLabel}
                      color="secondary"
                    >
                      {optionTitle}
                    </Typography>
                  </Grid>
                </Button>
              );
            })}
          </Box>
        </Scrollbars>
      </Paper>
    </div>
  );

  return (
    <div className={clsx(classes.searchWrapper, focused ? classes.focused : classes.unfocused)}>
      <ClickAwayListener onClickAway={clickAway}>
        <div>
          {renderSearchForm()}
          {isVisibleSearch && renderSearchSelect()}
        </div>
      </ClickAwayListener>
    </div>
  );
};

FilterElement.defaultProps = {
  titleKey: 'name',
  value: [],
  multiple: false,
  title: '',
  location: {},
  options: [],
  onChange: () => {},
};

FilterElement.propTypes = {
  value: PropType.arrayOf(PropType.string),
  multiple: PropType.bool,
  title: PropType.string,
  titleKey: PropType.string,
  location: PropType.shape({
    pathname: PropType.string,
    search: PropType.string,
  }),
  options: PropType.arrayOf(PropType.shape({
    id: PropType.oneOfType([
      PropType.string,
      PropType.number,
    ]),
    name: PropType.string,
  })),
  onChange: PropType.func,
};

export default FilterElement;
