import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons';
import {
  Link, useParams, useHistory, Redirect
} from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import routes, { routeBits } from 'routes';
import {
  blocksName,
  LOADING,
  SUCCEEDED,
  showNotValidFormNotification,
} from 'constants/main';
import createTripFormValidation from 'utils/validation';
import { Typography, Button } from 'components/ui-lib';
import ContentSpinner from 'components/ContentSpinner';
import PageHeader from 'components/PageHeader';

import {
  editTrip, loadAssistedUsers, loadTrip, resetStore
} from 'store/trip';
import { showNotification } from 'store/notifications';
import { mapDataForBe } from 'utils/mappers';
import { formatDateStr } from 'utils/dateTime';
import { usePrompt } from 'utils/useBeforeUnloadPrompt';
import RequestForm from 'components/RequestForm';
import useAppStyles from 'containers/App/AppStyles';

const initBlockValues = {
  [blocksName.DESTINATION]: {
    tempId: 1,
    countryId: null,
    destination: '',
    startDate: formatDateStr(new Date()),
    finishDate: formatDateStr(new Date()),
    comment: ''
  },
  [blocksName.TICKET]: {
    ticketTypeId: null,
    ticketClassId: null,
    from: '',
    to: '',
    departureDate: formatDateStr(new Date()),
    departureTime: '',
    comment: '',
  },
  [blocksName.HOTEL]: {
    location: '',
    arrivalDate: formatDateStr(new Date()),
    departureDate: formatDateStr(new Date()),
    comment: '',
    earlyCheckIn: false,
    lateCheckOut: false
  },
  [blocksName.ADDITIONAL_SERVICE]: {
    serviceId: null,
    comment: '',
  }
};

const EditRequest = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successfullySavedId, setSuccessfullySavedId] = useState(undefined);
  const appClasses = useAppStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      employeeId: null,
      company: null,
      businessTripTypeId: null,
      businessTripStartDate: formatDateStr(new Date()),
      businessTripFinishDate: formatDateStr(new Date()),
      businessTripGoal: '',
      destinations: [initBlockValues.destination],
      tickets: [],
      hotels: [],
      additionalServices: [],
    },
    validationSchema: createTripFormValidation,
    onSubmit: (values) => {
      setIsSubmitting(true);
      dispatch(editTrip({
        data: mapDataForBe(values),
      })).then((data) => {
        if (data.error) {
          setIsSubmitting(false);
          const { error } = data.payload;
          showNotValidFormNotification(error, formik, t);
        } else {
          const { id } = data.payload;
          setSuccessfullySavedId(id);
          dispatch(showNotification({
            type: 'success',
            message: {
              messageTemplate: {
                rows: [{
                  rowContent: [{
                    type: 'text',
                    text: t('SUCCESSFULLY_SAVED')
                  }]
                }],
              },
            }
          }));
        }
      });
    },
  });

  const {
    data,
    status,
    error,
  } = useSelector((state) => state.trip?.trip);

  const formHasChanges = !isEqual(formik.values, data) && status === SUCCEEDED;

  const Prompt = usePrompt({
    when: formHasChanges && !successfullySavedId,
    message: t('UNSAVED_DATA_MESSAGE')
  });

  const {
    assistedUsers = [], assistedUsersStatus,
  } = useSelector((state) => state.trip);
  const { id: userId } = useSelector(({
    account: {
      user: { id },
    },
  }) => ({ id }));
  const { roles = [] } = useSelector((state) => state.account?.user);

  const requestStatus = data?.status;
  const initiatorId = data?.initiatorId;
  const employeeId = data?.employeeId;
  const isEmployeeAssistant = assistedUsers.some(({ id }) => id === employeeId);
  const canEditTrip = requestStatus === 'Draft' && (initiatorId === userId || employeeId === userId || isEmployeeAssistant);

  useEffect(() => {
    dispatch(loadTrip(params));
    if (roles.includes('Assistant')) {
      dispatch(loadAssistedUsers());
    }
    return () => dispatch(resetStore());
  }, []);

  useEffect(() => {
    if (!successfullySavedId) return;
    history.push(`${routeBits.request}/${successfullySavedId}`);
  }, [successfullySavedId]);

  useEffect(() => {
    if (status !== SUCCEEDED) return;

    formik.setValues(data);
  }, [status]);

  useEffect(() => {
    if (!error) return;
    const errorStatus = get(error, 'status', '');
    if (errorStatus === 404) {
      history.replace(routes.notFound);
    }
  }, [error]);

  if (!canEditTrip && status === SUCCEEDED && assistedUsersStatus === SUCCEEDED) {
    return <Redirect to={routes.noAccess} />;
  }

  return (
    <>
      <Prompt />
      <ContentSpinner isLoading={status === LOADING}>
        {status === SUCCEEDED && (
          <Box className={appClasses.root}>
            <PageHeader title={t('EDIT_TRIP')}>
              <div className={appClasses.pageHeader}>
                <Typography variant="h1">Edit trip</Typography>
                <Box>
                  <Button
                    startIcon={<CancelOutlined />}
                    type="cart-secondary"
                    component={Link}
                    to={`${routeBits.request}/${params.requestId}`}
                    disabled={isSubmitting}
                    data-test="cancel-create-trip-btn"
                  >
                    {t('CANCEL')}
                  </Button>
                  <Button
                    startIcon={<CheckCircleOutline />}
                    type="cart-secondary"
                    className={appClasses.actionControl}
                    disabled={isSubmitting || !formHasChanges}
                    onClick={() => {
                      formik.validateForm()
                        .then((errors) => {
                          const isValid = isEmpty(errors);
                          if (isValid) {
                            formik.submitForm();
                          } else {
                            showNotValidFormNotification(null, formik, t);
                          }
                        })
                        .catch((err) => {
                          showNotValidFormNotification(err, formik, t);
                        });
                    }}
                    data-test="create-trip-btn"
                  >
                    {t('SAVE')}
                  </Button>
                </Box>
              </div>
            </PageHeader>
            <ContentSpinner isLoading={isSubmitting}>
              <RequestForm
                initBlockValues={initBlockValues}
                setFieldValue={(field, value, shouldValidate) => {
                  formik.setFieldValue(field, value, shouldValidate);
                  const fieldError = get(formik?.errors, field);
                  if (fieldError) formik.setFieldError(field);
                }}
                values={formik.values}
                errors={formik.errors}
              />
            </ContentSpinner>
          </Box>
        )}
      </ContentSpinner>
    </>
  );
};

export default EditRequest;
