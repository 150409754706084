import { createTheme } from '@material-ui/core';
import * as colors from './colors';
import typography from './typography';

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.primaryLight,
      dark: colors.lightPrimaryHover,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  overrides: {
    MuiFormHelperText: {
      contained: {
        color: colors.darkGray,
        marginRight: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        fontSize: 16,
        lineHeight: '24px',
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primaryLight,
          },
        },
        '&.Mui-error': {
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.error,
            },
          },
        },
        '&.Mui-disabled': {
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
      },
      input: {
        paddingTop: '10.5px !important',
        paddingBottom: '10.5px !important',
      },
      inputMultiline: {
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
      },
      outlined: {
        transform: 'translate(14px, 13px) scale(1)',
      },
      shrink: {
        color: colors.darkGray,
      },
    },
    MuiSelect: {
      root: {
        fontWeight: 'normal',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 'normal',
        color: colors.darkGray,
      },
      filled: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },

    MuiInputBase: {
      root: {},
      input: {
        fontWeight: 'normal',
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: 'normal',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      sizeLarge: {
        height: 48,
      },
    },
  },
  typography,
  colors,
});

export default lightTheme;
