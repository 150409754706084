export const fullBlack = '#000';
export const black = '#343434';
export const secondary = '#8a8a8a';
export const white = '#fff';
export const primaryLight = '#4499ee';
export const lightPrimaryHover = '#55aaff';
export const error = '#dd4747';
export const disabledError = '#DD474780';
export const success = '#44bb44'; // positive
export const alert = '#ff9900';
export const dtekLightYellow = '#fffddd';
export const yellow = '#ffe300';
export const dtekYellow = '#ffe500';

export const lightGray = '#f9f9f9';
export const black10 = '#f3f3f3';
export const gray = '#e3e3e3';
export const black20 = '#ccc';
export const darkGray = '#a1a1a1';
export const black70 = '#767676';
export const lightSelection = '#ecf4fc';
export const semiLightSelection = '#e9f0f9';
export const black5 = '#fafafa';
