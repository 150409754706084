import * as Yup from 'yup';
import 'yup-phone';
import {
  isBefore, isValid, isSameDay, isAfter,
} from 'date-fns';

const createTripFormValidation = Yup.object({
  employeeId: Yup.mixed().test(
    'employeeId',
    'REQUIRED_FIELD',
    (value) => {
      if (value === undefined) {
        return true;
      }

      return Boolean(value);
    },
  ),
  businessTripTypeId: Yup.string().required(),
  businessTripStartDate: Yup.date()
    .nullable()
    .typeError('NOT_VALID_DATE')
    .test('', 'REQUIRED_FIELD', (value) => Boolean(value))
    .test('', 'NOT_VALID_DATE', (value, props) => {
      const businessTripFinishDate = new Date(props?.parent?.businessTripFinishDate);

      if (isBefore(value, new Date().setHours(0, 0, 0))) {
        return false;
      }

      if (!isValid(businessTripFinishDate)) {
        return isValid(value);
      }

      return isValid(value) && isValid(businessTripFinishDate) && (isBefore(value, businessTripFinishDate) || isSameDay(value, businessTripFinishDate));
    }),
  businessTripFinishDate: Yup.date()
    .nullable()
    .typeError('NOT_VALID_DATE')
    .test('', 'REQUIRED_FIELD', (value) => Boolean(value))
    .test('', 'NOT_VALID_DATE', (value, props) => {
      const businessTripStartDate = new Date(props?.parent?.businessTripStartDate);

      if (isBefore(value, new Date().setHours(0, 0, 0))) {
        return false;
      }

      if (!isValid(businessTripStartDate)) {
        return isValid(value);
      }

      return isValid(value) && isValid(businessTripStartDate) && (isAfter(value, businessTripStartDate) || isSameDay(value, businessTripStartDate));
    }),
  destinations: Yup.array().of(
    Yup.object().shape({
      countryId: Yup.number().required(),
      destination: Yup.string().required(),
      startDate: Yup.date()
        .nullable()
        .typeError('NOT_VALID_DATE')
        .test('', 'REQUIRED_FIELD', (value) => Boolean(value))
        .test('', 'NOT_VALID_DATE', (value, props) => {
          const [parentFieldsForm, allFieldsForm] = props?.from;
          const businessTripStartDate = new Date(allFieldsForm.value.businessTripStartDate);
          const businessTripFinishDate = new Date(allFieldsForm.value.businessTripFinishDate);

          if (!isValid(businessTripStartDate) || !isValid(businessTripFinishDate)) {
            return false;
          }

          if (isAfter(value, businessTripFinishDate.setHours(23, 59, 0)) || isBefore(value, businessTripStartDate.setHours(0, 0, 0))) {
            return false;
          }

          if (isValid(parentFieldsForm.value.finishDate) && isAfter(value, parentFieldsForm.value.finishDate.setHours(23, 59, 0))) {
            return false;
          }

          return isValid(value);
        }),
      finishDate: Yup.date()
        .nullable()
        .typeError('NOT_VALID_DATE')
        .test('', 'REQUIRED_FIELD', (value) => Boolean(value))
        .test('', 'NOT_VALID_DATE', (value, props) => {
          const [parentFieldsForm, allFieldsForm] = props?.from;
          const businessTripStartDate = new Date(allFieldsForm.value.businessTripStartDate);
          const businessTripFinishDate = new Date(allFieldsForm.value.businessTripFinishDate);

          if (!isValid(businessTripStartDate) || !isValid(businessTripFinishDate)) {
            return false;
          }

          if (isAfter(value, businessTripFinishDate.setHours(23, 59, 0)) || isBefore(value, businessTripStartDate.setHours(0, 0, 0))) {
            return false;
          }

          if (isValid(parentFieldsForm.value.startDate) && isBefore(value, parentFieldsForm.value.startDate.setHours(0, 0, 0))) {
            return false;
          }

          return isValid(value);
        }),
    }),
  ),
  tickets: Yup.array().of(
    Yup.object().shape({
      ticketTypeId: Yup.number().required(),
      from: Yup.string().required(),
      to: Yup.string().required(),
      departureDate: Yup.date()
        .nullable()
        .typeError('NOT_VALID_DATE')
        .test('', 'REQUIRED_FIELD', (value) => Boolean(value))
        .test('', 'NOT_VALID_DATE', (value, props) => {
          const [, allFieldsForm] = props?.from;
          const businessTripStartDate = new Date(allFieldsForm.value.businessTripStartDate);
          const businessTripFinishDate = new Date(allFieldsForm.value.businessTripFinishDate);

          if (!isValid(businessTripStartDate) || !isValid(businessTripFinishDate)) {
            return false;
          }

          if (isAfter(value, businessTripFinishDate.setHours(23, 59, 0)) || isBefore(value, businessTripStartDate.setHours(0, 0, 0))) {
            return false;
          }

          return isValid(value);
        }),
    }),
  ),
  hotels: Yup.array().of(
    Yup.object().shape({
      location: Yup.string().required(),
      arrivalDate: Yup.date()
        .nullable()
        .typeError('NOT_VALID_DATE')
        .test('', 'REQUIRED_FIELD', (value) => Boolean(value))
        .test('', 'NOT_VALID_DATE', (value, props) => {
          const [parentFieldsForm, allFieldsForm] = props?.from;
          const businessTripStartDate = new Date(allFieldsForm.value.businessTripStartDate);
          const businessTripFinishDate = new Date(allFieldsForm.value.businessTripFinishDate);

          if (!isValid(businessTripStartDate) || !isValid(businessTripFinishDate)) {
            return false;
          }

          if (isAfter(value, businessTripFinishDate.setHours(23, 59, 0)) || isBefore(value, businessTripStartDate.setHours(0, 0, 0))) {
            return false;
          }

          if (isValid(parentFieldsForm.value.departureDate) && isAfter(value, parentFieldsForm.value.departureDate.setHours(23, 59, 0))) {
            return false;
          }

          return isValid(value);
        }),
      departureDate: Yup.date()
        .nullable()
        .typeError('NOT_VALID_DATE')
        .test('', 'REQUIRED_FIELD', (value) => Boolean(value))
        .test('', 'NOT_VALID_DATE', (value, props) => {
          const [parentFieldsForm, allFieldsForm] = props?.from;
          const businessTripStartDate = new Date(allFieldsForm.value.businessTripStartDate);
          const businessTripFinishDate = new Date(allFieldsForm.value.businessTripFinishDate);

          if (!isValid(businessTripStartDate) || !isValid(businessTripFinishDate)) {
            return false;
          }

          if (isAfter(value, businessTripFinishDate.setHours(23, 59, 0)) || isBefore(value, businessTripStartDate.setHours(0, 0, 0))) {
            return false;
          }

          if (isValid(parentFieldsForm.value.arrivalDate) && isBefore(value, parentFieldsForm.value.arrivalDate.setHours(0, 0, 0))) {
            return false;
          }

          return isValid(value);
        }),
    }),
  ),
  additionalServices: Yup.array().of(
    Yup.object().shape({
      serviceId: Yup.number().required(),
    }),
  ),
});

export default createTripFormValidation;
