import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import NotificationAlert from 'components/NotificationAlert';
import { makeStyles } from '@material-ui/core/styles';

import { getNotificationData, closeNotification } from 'store/notifications';

const useStyles = makeStyles((theme) => ({
  anchorOriginTopRight: {
    top: 5,
    right: 24,
  },
  mailToLink: {
    color: theme.colors.primaryLight,
    textDecoration: 'none',
  },
}
));

const NotificationsManager = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    open,
    type,
    message,
  } = useSelector(getNotificationData);

  const handleClose = () => dispatch(closeNotification());

  const { messageTemplate: { rows = [] } = {} } = JSON.parse(message);

  const renderNotificationContent = () => rows.map(({ rowContent }, rowIndex) => {
    const rowKey = `row_${rowIndex}_${JSON.stringify(rowContent)}`;
    return (
      <div key={rowKey}>
        {rowContent.map(({ type: textType, text, url }, itemIndex) => {
          const itemKey = `${rowKey}_${itemIndex}`;
          const splitText = text.replace(/\s\s+/g, ' ').split('$').map((word) => {
            const wordKey = `${itemKey}_${word}`;
            return (
              <span key={wordKey}>
                {word}
                {itemIndex !== rowContent.length - 1 && (<>&nbsp;</>)}
              </span>
            );
          });
          if (textType === 'link') {
            return (
              <Link to={url} key={itemKey}>
                {splitText}
              </Link>
            );
          }
          if (textType === 'mailto') {
            return (
              <a href={url} key={itemKey} className={classes.mailToLink} target="_blank" rel="noreferrer">{splitText}</a>
            );
          }
          return (
            <Fragment key={itemKey}>
              {splitText}
            </Fragment>
          );
        })}
      </div>
    );
  });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={3500}
      onClose={handleClose}
      classes={{
        anchorOriginTopRight: classes.anchorOriginTopRight,
      }}
    >
      <NotificationAlert type={type} onClose={handleClose}>
        {renderNotificationContent()}
      </NotificationAlert>
    </Snackbar>
  );
};

export default NotificationsManager;
