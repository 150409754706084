import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    color: '#212121', // _color_
    borderBottom: `1px solid ${theme.colors.black10}`,
    position: 'static',
    top: 64,
    zIndex: 4,
    background: theme.colors.white,
  },
  sticky: {
    position: 'sticky',
  },
}
));

export default useStyles;
