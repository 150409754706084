import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textFieldClearButton: {
    position: 'absolute',
    top: 'calc(50% - 14px)',
    right: 30,
  },
  toggleDropdownButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  endAdornmentIconButton: {
    width: 28,
    height: 28,
    paddingTop: 0,
    paddingBottom: 0,
  },
  endAdornmentIcon: {
    width: 20,
    height: 20,
  },
  searchResultsDropdown: {},
  grey: {
    '& input': {
      marginLeft: 24,
      color: '#ccc',
    },
  },
  darkGrey: {
    '& input': {
      marginLeft: 24,
      color: theme.colors.black,
    },
  },
  searchIcon: {
    zIndex: 3,
    position: 'absolute',
    top: 'calc(50% - 10px)',
    left: 16,
    color: theme.colors.darkGray,
    width: 20,
    height: 20,
  },
  searchInput: {
    marginTop: 0,
    marginRight: 16,
    height: 40,
    maxHeight: 40,
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    '&:hover': {
      '& .MuiButtonBase-root': {
        display: 'block',
      },
    },
    '& input::-ms-clear': {
      display: 'none',
    },
    '& input': {
      paddingRight: 20,
      textAlign: 'inherit',
    },
    '& label': {
      fontSize: 16,
      background: theme.colors.white,
      padding: '0 5px',
      top: 0,
    },
    '& label.MuiInputLabel-shrink': {
      fontSize: 14,
      marginLeft: -4,
      top: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '& fieldset legend': {
        maxWidth: 0,
      },
      '&:hover fieldset': {
        borderColor: 'transparent !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
        borderWidth: 1,
      },
      '&.Mui-disabled': {
        '& fieldset': {
          borderColor: 'transparent',
        },
      },
    },
  },
  searchWrapper: {
    width: '100%',
    maxWidth: '100%',
    position: 'relative',
  },
  textField: {
    zIndex: 1,
    width: '100%',
    textAlign: 'left',
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  selectWrapper: {
    borderRadius: 3,
    backgroundColor: theme.colors.white,
    maxHeight: ({ maxHeight }) => (maxHeight || 250
    ),
    position: 'absolute',
    width: '100%',
    top: '100%',
    left: 0,
    marginTop: '-7px',
    boxShadow: 'none',
    boxSizing: 'border-box',
    borderTop: 0,
    '&::before': {
      content: '""',
      top: '-38px',
      bottom: -3,
      left: -2,
      right: -2,
      position: 'absolute',
      border: `1px solid ${theme.colors.black20}`,
      backgroundColor: theme.colors.white,
      borderRadius: 3,
    },
  },
  hovered: {
    '&::before': {
      border: `1px solid ${theme.colors.primaryLight}`,
    },
  },
  unHovered: {
    '&::before': {
      border: `1px solid ${theme.colors.black20}`,
    },
  },
  error: {
    '&::before': {
      border: `1px solid ${theme.colors.error} !important`,
    },
  },
  focused: {
    zIndex: 2,
    '&.MuiFormControl-root': {
      zIndex: 3,
    },
    '& input': {
      paddingRight: 20,
    },
    '&::before': {
      zIndex: 1,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      border: `2px solid ${theme.colors.primaryLight}`,
    },
  },
  visibleBtn: {
    display: 'block',
  },
  unVisibleBtn: {
    display: 'none',
  },
  inputBase: {
    lineHeight: '16px',
  },
  unfocused: {},
  searchMenuWrapper: {
    padding: 0,
  },
  searchMenuItem: {
    paddingLeft: 48,
  },
  optionLabel: {
    color: theme.colors.black20,
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: 0,
    textTransform: 'none',
  },
  menuItem: {
    '& .MuiTypography-root': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    height: 48,
    '&:hover': {
      backgroundColor: theme.colors.midGray,
    },
  },
  menuItemSpan: {
    lineHeight: '36px',
  },
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    searchInput: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          top: 0,
        },
      },
    },
  },
}
));

export default useStyles;
