const routeBits = {
  toContainer: '/containers/container',
  request: '/request',
};

const routes = {
  main: '/',
  request: `${routeBits.request}/:requestId`,
  editEvent: `${routeBits.request}/:requestId/edit`,
  createTrip: '/create-trip',
  createTripForSomeone: '/create-trip/for-someone',
  noAccess: '/no-access',
  notFound: '/404',
};

const getRouterPaths = () => Object.keys(routes).map((key) => routes[key]);

export { routeBits, getRouterPaths };
export default routes;
