import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NotFoundImage } from './assets/404.svg';

import useStyles from './styles';

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.content} data-test="not-found-page">
      <NotFoundImage />
      <div className={classes.label} data-test="not-found-page-title">
        {t('NOT_FOUND_TITLE')}
      </div>
      <div className={classes.subLabel} data-test="not-found-page-subtitle">
        {t('TRY_START_WITH')}
        &nbsp;
        <Link
          to={{
            pathname: '/',
          }}
          data-test="link-to-home-page"
        >
          <span
            data-test="linkToMainPage"
            className={classes.toHomePageLink}
          >
            {t('MAIN_PAGE')}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
