import React from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton, Typography } from '@material-ui/core';

import useStyles from './buttonStyles';

const Button = (props) => {
  const classes = useStyles();
  const { type, children } = props;
  const typeConfigs = {
    'default-primary': {
      color: 'primary',
      variant: 'contained',
      children: <Typography variant="subtitle1">{children}</Typography>,
      size: 'large',
      classes: {
        root: classes.defaultPrimary,
        disabled: classes.defaultPrimaryDisabled,
      },
    },
    'default-error': {
      color: 'error',
      variant: 'contained',
      children: <Typography variant="subtitle1">{children}</Typography>,
      size: 'large',
      classes: {
        root: classes.defaultError,
        disabled: classes.defaultPrimaryDisabled,
      },
    },
    'default-secondary': {
      color: 'secondary',
      variant: 'outlined',
      children: <Typography variant="subtitle1">{children}</Typography>,
      size: 'large',
      classes: {
        root: classes.defaultSecondary,
      },
    },
    'default-secondary-yellow': {
      color: 'primary',
      variant: 'outlined',
      children: <Typography variant="subtitle1">{children}</Typography>,
      size: 'large',
      classes: {
        root: classes.defaultSecondaryYellow,
      },
    },
    'default-primary-yellow': {
      color: 'primary',
      variant: 'contained',
      children: <Typography variant="subtitle1">{children}</Typography>,
      size: 'large',
      classes: {
        root: classes.defaultPrimaryYellow,
        disabled: classes.defaultPrimaryDisabled,
      },
    },
    'cart-primary': {
      color: 'primary',
      variant: 'outlined',
      size: 'small',
      children: <Typography variant="subtitle2">{children}</Typography>,
      classes: {
        root: classes.cartPrimary,
      },
    },
    'cart-secondary': {
      color: 'primary',
      variant: 'outlined',
      size: 'small',
      children: <Typography variant="subtitle2">{children}</Typography>,
      classes: {
        root: classes.cartSecondary,
      },
    },
    'cart-error': {
      variant: 'outlined',
      size: 'small',
      children: <Typography variant="subtitle2">{children}</Typography>,
      classes: {
        root: classes.cartError,
      },
    },
    'cart-dashed': {
      color: 'primary',
      variant: 'outlined',
      size: 'large',
      children: <Typography variant="subtitle2">{children}</Typography>,
      classes: {
        root: classes.cartDashed,
      },
    },
  };
  const config = typeConfigs[type] || typeConfigs['default-primary'];

  return (
    <MaterialButton
      {...config}
      {...props}
      disableElevation
    >
      {config.children || children}
    </MaterialButton>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf([
    'default-primary',
    'default-secondary',
    'cart-primary',
    'cart-secondary',
    'cart-error',
    'cart-dashed',
    'default-error',
    'default-secondary-yellow',
    'default-primary-yellow',
  ]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

Button.defaultProps = {
  type: 'default-primary',
  children: null,
};

export default Button;
