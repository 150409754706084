import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import Can from './Can';

const PrivateRoute = ({
  component: Component, permission, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Can
        perform={permission}
        yes={() => (
          <Component {...props} />
        )}
        no={() => (
          <Redirect to={{ pathname: '/no-access' }} />
        )}
      />
    )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  permission: PropTypes.string.isRequired,
};

export default PrivateRoute;
