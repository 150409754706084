import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  filterHeader: {
    padding: '0px 16px',
    height: 48,
  },
  filtersLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: theme.colors.black,
  },
  clearFiltersButton: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: theme.colors.primaryLight,
    '&:disabled': {
      color: theme.colors.black30,
    },
  },
}));

export default useStyles;
