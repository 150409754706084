import React from 'react';
import { Box } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import { Typography } from 'components/ui-lib';
import useStyles from './markdownViewerStyles';

const LimitsTab = ({ limits = [] }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box px={3} pt={1}>
      <Typography variant="h2">
        {t('LIMITS_DESCRIPTION')}
      </Typography>
      {limits.map(({ value = '', id } = {}) => (
        <div key={id}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{ table: (props) => <table className={classes.markDownTable} {...props} /> }}
          >
            {value.slice(1, -1)}
          </ReactMarkdown>
        </div>
      ))}
    </Box>
  );
};

export default LimitsTab;
