import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
  },
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 'calc(100vh - 220px)',
  },
  actionControl: {
    marginLeft: 24,
  },
  caseBtn: {
    marginRight: 16,
    marginBottom: 16,
  },
  caseBtnSubTitle: {
    marginBottom: 16,
  },
  flexWrapBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  flexWraIndented: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 15%',
    boxSizing: 'border-box',
  },
  redColor: {
    color: '#dd4747',
  },
  filterElement: {
    marginTop: 32,
    marginBottom: 32,
  },
  tabs: {
    '& .MuiTab-root': {
      borderBottom: `2px solid ${theme.colors.black20}`,
      textTransform: 'none',
      fontSize: 16,
      color: theme.colors.fullBlack,
    },
    '& .Mui-selected': {
      fontWeight: 'bold',
      backgroundColor: theme.colors.dtekLightYellow,
    },
  },
  stickyTabs: {
    position: 'sticky',
    top: 128,
    backgroundColor: theme.colors.white,
    zIndex: 10,
  },
  indicator: {
    height: 4,
    backgroundColor: theme.colors.dtekYellow,
  },
}));

export default useStyles;
