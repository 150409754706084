import React from 'react';
import ReactDOM from 'react-dom';
import DialogComponent from './DialogComponent';

const CustomRouterUserConfirmation = (message, callback) => {
  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };

  ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <DialogComponent
      closeModal={closeModal}
    />,
    container,
  );
};
export default CustomRouterUserConfirmation;
