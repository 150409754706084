// action
const VIEW = 'view';

// pages
const CREATE_REQUEST_PAGE = 'create-request:';
const CREATE_REQUEST_FOR_SOMEONE_PAGE = 'create-request-for-someone:';

// create trip page
export const CREATE_REQUEST = `${CREATE_REQUEST_PAGE}${VIEW}`;

// create request page
export const CREATE_REQUEST_FOR_SOMEONE = `${CREATE_REQUEST_FOR_SOMEONE_PAGE}${VIEW}`;
