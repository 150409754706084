import React from 'react';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import enGbLocale from 'date-fns/locale/en-GB';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      '& .Mui-disabled.Mui-error': {
        '& fieldset': {
          borderColor: theme.colors.disabledError,
        },
      },
    },
    '& .Mui-disabled.Mui-error': {
      '& fieldset': {
        borderColor: theme.colors.disabledError,
      },
    },
  },
}));

const SimpleDatePicker = ({
  selectedDate,
  handleDateChange,
  label,
  helperText,
  inputProps,
  withCalendarIcon,
  ...props
}) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGbLocale}>
      <KeyboardDatePicker
        classes={{ root: classes.root }}
        fullWidth
        minDateMessage=""
        maxDateMessage=""
        disableToolbar
        autoOk
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        label={label}
        value={selectedDate}
        onChange={(date) => {
          handleDateChange(date);
        }}
        {...helperText && { helperText }}
        {...inputProps && { inputProps }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

SimpleDatePicker.defaultProps = {
  selectedDate: '',
  withCalendarIcon: true,
  inputProps: {},
};

SimpleDatePicker.propTypes = {
  withCalendarIcon: PropTypes.bool,
  handleDateChange: PropTypes.func.isRequired,
  inputProps: PropTypes.shape({
    'data-test': PropTypes.string,
  }),
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  label: PropTypes.string.isRequired,
};

export default SimpleDatePicker;
