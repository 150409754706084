import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  halfBlock: {
    width: '45%',
  },
  wrapper: {
    display: 'flex',
    width: '60%',
  },
  formControlRow: {
    width: '100%',
    display: 'flex',
    height: 48,
    margin: 0,
  },
  eventsCheckBoxes: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  blockDivider: {
    margin: '24px 0',
  },
  shadowBlock: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    border: '1px solid #f3f3f3',
    padding: '16px 80px',
    borderRadius: 4,
    marginBottom: 16,
    position: 'relative',
  },
  actionBlock: {
    display: 'flex',
    width: '100%',
  },
  actionBlockLeft: {
    width: '20%',
  },
  eventStatus: {
    color: '#ff9900',
    float: 'right',
    margin: '10px 0 0',
  },
  attrFileButton: {
    flex: 'none',
    alignSelf: 'center',
    marginLeft: 16,
  },
  isEmployeeBlock: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      paddingLeft: 14,
    },
  },
  expandBlockBtn: {
    position: 'absolute',
    right: 8,
    top: 0,
    bottom: 0,
    margin: 'auto 0',
    cursor: 'pointer',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  expandedBlockBtn: {
    bottom: 'auto',
    top: 8,
  },
  expandAllBtn: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primaryLight,
    '&:hover': {
      color: theme.colors.lightPrimaryHover,
    },
  },
  toggleAllWrap: {
    cursor: 'pointer',
    position: 'sticky',
    top: 129,
    background: '#fff',
    boxShadow: '3px 0px 0px 0px #fff, -3px 0px 0px 0px #fff',
    zIndex: 2,
  },
  totalLossWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  timeFrame: {
    display: 'flex',
    alignItems: 'center',
  },
  timeFrameItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 36,
  },
  timeFrameText: {
    paddingLeft: 12,
  },
  accompanyingServicesBlock: {
    marginBottom: 24,
  },
}));

export default useStyles;
