import { createTheme } from '@material-ui/core';
import { secondary, white } from './colors';
import * as colors from './colors';
import typography from './typography';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#000',
      dark: '#000',
      contrastText: white,
    },
    secondary: {
      main: secondary,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
  typography,
  colors,
});

export default theme;
