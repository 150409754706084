import React, { useState, useRef } from 'react';
import PropType from 'prop-types';
import clsx from 'clsx';
import {
  get, isEmpty, filter, sortBy,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';

import {
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  SvgIcon, Typography,
} from '@material-ui/core';
import {
  ArrowDropDown,
  ArrowDropUp,
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

import useStyles from './styles';

const PartCustomSelect = (props) => {
  const { t } = useTranslation();
  const {
    hasClearBtn,
    value,
    onChange,
    onClose,
    isRequired,
    disabled,
    label,
    options = [],
    autocomplete,
    optionKey,
    optionValue,
    error,
    'data-test': dataTest,
    helperText,
    maxHeight,
  } = props;

  const inputRef = useRef(null);

  const [hovered, setIsHovered] = useState(false);
  const [isVisibleOptions, setIsVisibleOptions] = useState(false);
  const [isTyped, setIsTyped] = useState(false);
  const [search, setSearch] = useState('');
  const classes = useStyles({ maxHeight });

  const valueToShow = isTyped ? search : get(value, optionValue) || '';
  const isSearchIconVisible = (search || isEmpty(value) || isTyped
  ) && isVisibleOptions && autocomplete;

  const filteredOptions = sortBy(filter(options, (option = {}) => {
    const val = option[optionValue];
    if (!search) return option;
    return val && val.toLowerCase().includes(search.toLowerCase());
  }), (option) => option[optionValue].toLowerCase().indexOf(search.toLowerCase()));

  const toggleOptionsDropdown = () => {
    setSearch('');
    if (!isVisibleOptions) return;
    setIsVisibleOptions(!isVisibleOptions);
  };

  const handleOptionSelect = (option) => {
    onChange(option);
    setIsTyped(false);
    setIsVisibleOptions(false);
    inputRef.current.blur();
  };

  const handleInputChange = (event) => {
    const val = get(event, 'target.value') || '';
    setSearch(val);
    setIsTyped(true);
  };

  const handleInputOnFocus = () => {
    setIsVisibleOptions(true);
  };

  const renderSearchInput = () => (
    <form noValidate spellCheck="false" className={classes.form}>
      <TextField
        required={isRequired}
        type={autocomplete ? 'input' : 'button'}
        disabled={disabled}
        inputRef={inputRef}
        onMouseEnter={() => {
          if (!disabled) setIsHovered(true);
        }}
        onMouseLeave={() => {
          if (!disabled) setIsHovered(false);
        }}
        autoComplete="off"
        className={clsx(
          classes.textField,
          isVisibleOptions ? classes.focused : classes.unfocused,
        )}
        margin="dense"
        variant="outlined"
        label={label}
        placeholder={t('FILTER')}
        value={valueToShow}
        InputLabelProps={{
          error,
        }}
        // error={valueToShow}
        helperText={isVisibleOptions ? undefined : helperText}
        onChange={handleInputChange}
        onFocus={handleInputOnFocus}
        {...dataTest && { inputProps: { 'data-test': dataTest } }}
        InputProps={{
          ...dataTest && { 'data-test': `${dataTest}-Input-component` },
          endAdornment: (
            <>
              {hasClearBtn && (!isEmpty(value) || search
              ) && (
                <div className={clsx(
                  classes.textFieldClearButton,
                  (isVisibleOptions || hovered
                  ) ? classes.visibleBtn : classes.unVisibleBtn,
                )}
                >
                  <IconButton
                    disabled={disabled}
                    className={classes.endAdornmentIconButton}
                    onClick={() => {
                      setSearch('');
                      onChange(undefined);
                      if (isVisibleOptions) inputRef.current.focus();
                    }}
                    data-test="clear-button"
                  >
                    <SvgIcon
                      className={classes.endAdornmentIcon}
                      component={ClearIcon}
                    />
                  </IconButton>
                </div>
              )}
              <IconButton
                disabled={disabled}
                size="small"
                className={classes.toggleDropdownButton}
                data-test="toggle-dropdown-button"
                onClick={() => {
                  setIsVisibleOptions(!isVisibleOptions);
                  if (isVisibleOptions) setSearch('');
                  if (!isVisibleOptions) inputRef.current.focus();
                }}
              >
                <SvgIcon
                  component={isVisibleOptions ? ArrowDropUp : ArrowDropDown}
                  viewBox="0 0 24 24"
                />
              </IconButton>
            </>
          ),
          classes: { root: classes.inputBase },
        }}
        classes={{
          root: clsx(
            classes.searchInput,
            // isEmpty(value) && search === '' && isVisibleOptions && classes.grey,
            (search || isTyped || isEmpty(value)
            ) && isVisibleOptions && classes.darkGrey,
          ),
        }}
      />
    </form>
  );

  const getSearchDropdownHeight = () => {
    const filteredOptionHeight = 48;
    const defaultOptionsWrapperHeight = 250;
    const extraHeight = 1;
    if (filteredOptions.length === 0) return filteredOptionHeight + extraHeight;
    if (filteredOptions.length > 4) return maxHeight || defaultOptionsWrapperHeight;
    return filteredOptionHeight * filteredOptions.length + extraHeight;
  };

  const renderSearchResultsDropdown = () => (
    <div
      className={classes.searchResultsDropdown}
    >
      <Paper className={
        clsx(
          classes.selectWrapper,
          isVisibleOptions ? classes.focused : classes.unfocused,
          hovered ? classes.hovered : classes.unHovered,
          error ? classes.error : null,
        )
      }
      >
        {isVisibleOptions && (
          <Scrollbars
            style={{
              border: 'none',
              borderTop: '1px solid #f7f7f7',
              borderBottomLeftRadius: 3,
              borderBottomRightRadius: 3,
              zIndex: 3,
              backgroundColor: '#fff',
              height: getSearchDropdownHeight(),
            }}
            renderThumbVertical={({ style, ...restProps }) => (
              <div
                {...restProps}
                style={{
                  ...style, backgroundColor: 'rgb(225, 225, 225)', borderRadius: 6, width: 4,
                }}
              />
            )}
            renderTrackVertical={({ style, ...restProps }) => {
              if (filteredOptions.length > 4) {
                return (
                  <div
                    {...restProps}
                    style={{
                      ...style,
                      position: 'absolute',
                      backgroundColor: 'rgb(243, 243, 243)',
                      width: 4,
                      right: 8,
                      bottom: 2,
                      top: 2,
                      borderRadius: 3,
                    }}
                  />
                );
              }
              return (<div />
              );
            }}
          >
            {filteredOptions.length === 0 && (
              <div style={{
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#c1c1c1',
              }}
              >
                <Typography variant="subtitle2">No options</Typography>
              </div>
            )}
            <MenuList className={classes.searchMenuWrapper}>
              {filteredOptions.length > 0 && filteredOptions.map((item = {}) => {
                const id = item[optionKey];
                const name = item[optionValue];

                return (
                  <MenuItem
                    data-test={`option-id-${id}`}
                    key={id}
                    onMouseDown={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    onClick={() => {
                      setSearch('');
                      handleOptionSelect(item);
                    }}
                    className={classes.menuItem}
                  >
                    <Typography variant="subtitle2">
                      {name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Scrollbars>
        )}
      </Paper>
    </div>
  );

  return (
    <div className={classes.searchWrapper} data-test={`${dataTest}-wrapper`}>
      <ClickAwayListener onClickAway={() => {
        if (isVisibleOptions) {
          toggleOptionsDropdown();
          setIsTyped(false);
          if (typeof onClose === 'function') {
            onClose();
          }
        }
      }}
      >
        <div>
          {renderSearchInput()}
          {renderSearchResultsDropdown()}
          {isSearchIconVisible && (
            <SvgIcon
              className={classes.searchIcon}
              component={SearchIcon}
            />
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

PartCustomSelect.defaultProps = {
  optionKey: 'id',
  optionValue: 'title',
  hasClearBtn: false,
  error: false,
  disabled: false,
  isRequired: false,
  autocomplete: false,
  dataTestId: '',
  onClose: () => {
  },
  helperText: undefined,
  maxHeight: undefined,
};

PartCustomSelect.propTypes = {
  maxHeight: PropType.number,
  value: PropType.object, // eslint-disable-line
  onChange: PropType.func.isRequired,
  onClose: PropType.func,
  isRequired: PropType.bool,
  disabled: PropType.bool,

  optionKey: PropType.string,
  optionValue: PropType.string,
  // eslint-disable-next-line
  options: PropType.arrayOf(PropType.object).isRequired,

  label: PropType.string.isRequired,
  autocomplete: PropType.bool,
  hasClearBtn: PropType.bool,
  error: PropType.bool,
  dataTestId: PropType.string,
  helperText: PropType.string,
};

export default PartCustomSelect;
